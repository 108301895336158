import { combineReducers } from "redux";
import Api from "utils/Event/WebAPIUtils";
import _ from "lodash";

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_EVENT_GROUPS = "INVALIDATE_EVENT_GROUPS";
const REQUEST_EVENT_GROUPS = "REQUEST_EVENT_GROUPS";
const RECEIVE_EVENT_GROUPS = "RECEIVE_EVENT_GROUPS";

// ------------------------------------
// Actions
// ------------------------------------
export const invalidateGroups = (eventId) => ({
  type: INVALIDATE_EVENT_GROUPS,
  eventId,
});

export const requestGroups = (eventId) => ({
  type: REQUEST_EVENT_GROUPS,
  eventId,
});

export const receiveGroups = (eventId, groups) => ({
  type: RECEIVE_EVENT_GROUPS,
  eventId,
  groups,
  receivedAt: Date.now(),
});

export const fetchGroups = (eventId) => (dispatch, getState) => {
  dispatch(requestGroups(eventId));
  Api.fetchGroups(getState().user.user.credentials, eventId).then((groups) => {
    dispatch(receiveGroups(eventId, groups));
    return null;
  });
};

export const shouldFetchGroups = (state, eventId) => {
  const groups = state.eventGroups.groupsByEvent[eventId];
  if (!groups) {
    return true;
  }
  if (groups.isFetching) {
    return false;
  }
  return groups.didInvalidate;
};

export const fetchGroupsIfNeeded = (eventId) => (dispatch, getState) => {
  if (shouldFetchGroups(getState(), eventId)) {
    return dispatch(fetchGroups(eventId));
  }
};

export const addGroup = (data) => (dispatch, getState) =>
  Api.addGroup(getState().user.user.credentials, data);

export const updateGroup = (data) => (dispatch, getState) =>
  Api.updateGroup(getState().user.user.credentials, data);

export const updateGroupMembers = (data) => (dispatch, getState) =>
  Api.updateGroupMembers(getState().user.user.credentials, data);

export const deleteGroup = (data) => (dispatch, getState) =>
  Api.deleteGroup(getState().user.user.credentials, data);

export const actions = {
  invalidateGroups,
  requestGroups,
  receiveGroups,
  fetchGroups,
  shouldFetchGroups,
  fetchGroupsIfNeeded,
  addGroup,
  updateGroup,
  updateGroupMembers,
  deleteGroup,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
  groups: [],
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_GROUPS:
      return _.extend({}, state, {
        didInvalidate: true,
      });

    case REQUEST_EVENT_GROUPS:
      return _.extend({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case RECEIVE_EVENT_GROUPS:
      return _.extend({}, state, {
        isFetching: false,
        didInvalidate: false,
        groups: action.groups,
        lastUpdated: action.receivedAt,
      });

    default:
      return state;
  }
};

const groupsByEvent = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_GROUPS:
    case RECEIVE_EVENT_GROUPS:
    case REQUEST_EVENT_GROUPS:
      return _.extend({}, state, {
        [action.eventId]: groups(state[action.eventId], action),
      });
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  groupsByEvent,
});

export default rootReducer;
