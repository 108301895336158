import superagent from "superagent";
import {
  clearSsoCredentials,
  getSsoCredentials,
  setSsoCredentials,
} from "utils/General";

let refreshingToken = false;

/*
 * Get SSO credentials object from local storage
 */
function getCredentials() {
  try {
    return JSON.parse(getSsoCredentials());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to parse SSO credentials from local storage", err);
    return null;
  }
}

/*
 * Get SSO credentials access token from local storage
 */
function getAccessToken() {
  return getCredentials()?.access_token;
}

/*
 * Refresh the SSO credentials currently in local storage
 * IF access token is expired or about to expire in the next five minutes.
 */
async function refreshTokenIfNecessary() {
  const ssoCredentials = getCredentials();

  if (refreshingToken || ssoCredentials === null) {
    return;
  }

  if (+new Date() > ssoCredentials.expires_at - 300000) {
    refreshingToken = true;

    // eslint-disable-next-line no-console
    console.log(
      "[Classy SSO] SSO credentials expired or about to expire...refreshing credentials.",
    );

    try {
      const apiResponse = await superagent
        .get(`${window.__LIVE_APP_URL__}/auth/refresh-token`)
        .query({
          refresh_token: ssoCredentials.refresh_token,
          isIdp: ssoCredentials.isIdp,
        });

      const newCredentials = apiResponse.body.credentials;
      setSsoCredentials(JSON.stringify(newCredentials));
    } catch (e) {
      // IMPORTANT: We want to clear the user's credentials if the refresh token call fails
      // in order to prevent infinite loops where we try to refresh an expired access token,
      // but the refresh token call fails, but we keep trying over and over again.
      clearSsoCredentials();
      // eslint-disable-next-line no-console
      console.error("Error refreshing token");
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      refreshingToken = false;
    }
  }
}

export default {
  getCredentials,
  getAccessToken,
  refreshTokenIfNecessary,
};
