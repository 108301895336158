import PropTypes from "prop-types";
import * as R from "ramda";
import { capitalize } from "lodash";

import styleSpecs from "./styleSpecs";
import cxs from "./cxs/component";
import getStyles, { propKeys, implDict, conversionDict } from "./getStyles";
import { defaultProps, filterProps, mapProps } from "../utils";

const emptyObj = {};

const Base = (ss) => (propTypes) => (Comp) => {
  const Result = cxs(Comp)(({ style = emptyObj, ...props }) =>
    getStyles(ss)(filterProps(propKeys)(props)[0], style),
  );
  Result.propTypes = propTypes;
  Result.displayName =
    (typeof Comp === "string" && capitalize(Comp)) ||
    Comp.displayName ||
    Comp.name ||
    "Base(Comp)";
  return Result;
};

// style and base spec'd preprocessors
const defaultPropForAll = mapProps(({ style, title, ...props }) => {
  let displayedTitle;
  if (typeof title === "string") {
    displayedTitle = title;
  } else if (typeof title === "boolean" && typeof props.children === "string") {
    displayedTitle = props.children;
  }
  return {
    style: { boxSizing: "border-box", ...style },
    title: displayedTitle,
    ...props,
  };
});

const ffEnhancement = defaultProps({
  ff: "primary",
});

const buttonEnhancement = mapProps(
  ({ onClick, style, userSelect, ...props }) => ({
    onClick,
    style: {
      cursor: onClick !== undefined ? "pointer" : "inherit",
      userSelect: onClick !== undefined ? "none" : userSelect,
      ...style,
    },
    ...props,
  }),
);

const makePropTypes = (elementStates) => (type) => {
  const elStatesAsKeys = R.fromPairs(R.map((e) => [e, null], elementStates));
  const elStateTypes = PropTypes.shape(
    R.mapObjIndexed(() => type, elStatesAsKeys),
  );
  // @NOTE: will not check if array index out of bounds
  const mediaStateTypes = PropTypes.arrayOf(elStateTypes);
  return PropTypes.oneOfType([type, elStateTypes, mediaStateTypes]);
};

const matchPropDict = (propDict) =>
  R.mapObjIndexed((value) => {
    // for omitted props
    let propType = value;

    // for regular, specific prop types
    if (Array.isArray(value)) {
      const [conversionFuncName] = value;
      propType = conversionDict[conversionFuncName][1](styleSpecs);
    }

    // @TODO: set for specific components
    return makePropTypes(["default", "hover", "focus"])(propType);
  })(propDict);

// eslint-disable-next-line consistent-return
const nilProp = function (props, propName, componentName) {
  if (!R.isNil(props[propName])) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Omit this prop.`,
    );
  }
};

const stylePropTypes = matchPropDict(implDict);

const basePropTypesLess = (arr) =>
  matchPropDict({
    ...implDict,
    ...R.mapObjIndexed(
      () => nilProp,
      R.fromPairs(R.map((x) => [x, null], arr)),
    ),
  });

const basePropTypesOnly = (arr) =>
  basePropTypesLess(R.difference(R.keys(implDict), arr));

const Styled = R.compose(defaultPropForAll, Base(styleSpecs)(stylePropTypes));

const Div = ffEnhancement(buttonEnhancement(Styled("div")));
const Span = ffEnhancement(buttonEnhancement(Styled("span")));
const Button = ffEnhancement(buttonEnhancement(Styled("button")));

const I = buttonEnhancement(Styled("i"));
const Img = buttonEnhancement(Styled("img"));
const SVG = buttonEnhancement(Styled("svg"));

const Input = ffEnhancement(Styled("input"));
const TextArea = ffEnhancement(Styled("textarea"));
const TR = ffEnhancement(Styled("tr"));
const TH = ffEnhancement(Styled("th"));

const Custom = (Comp) => ffEnhancement(Styled(Comp));

export {
  matchPropDict,
  implDict as baseDict,
  basePropTypesLess,
  basePropTypesOnly,
};

export { I, Img, Input, Div, SVG, TextArea, TR, TH, Span, Button, Custom };
