import { get } from "lodash";
import { eventId } from "redux/modules/event/selectors";
import { orgId } from "redux/modules/organization/selectors";
import { user } from "redux/modules/user/selectors";
import canDo from "components/Global/Security";

export const isFetching = (state, key, userId) =>
  get(state, `permissions.userProfile.fetching[${key}][${userId}]`, false);
export const userPermissionProfile = (state, key, userId) =>
  get(state, `permissions.userProfile.userProfile[${key}][${userId}]`, {});
export const hasFetchedUserPermissionProfile = (state, key) => {
  // @NOTE: If no key is specified return `true` because it's assumed
  // that there is are no permissions to retrieve.
  if (!key) return true;

  return Boolean(
    // @NOTE: We fall back to an empty object here because if a user
    // is not logged in, `null` will be returned by the server for the
    // permission payload.
    Object.keys(userPermissionProfile(state, key, user(state).id) || {}).length,
  );
};

export const cando = (state, key, userId) =>
  canDo(userPermissionProfile(state, key, userId)).do;
export const canEventUserDo = (state) =>
  cando(state, eventId(state), user(state).id);
export const canOrgUserDo = (state) =>
  cando(state, orgId(state), user(state).id);
export const canUserDo = (state, key) => cando(state, key, user(state).id);

export const canUserEditItemType = () => () => {
  return true;
};
export const canUserAddItemType = () => () => {
  return true;
};
