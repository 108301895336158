import Immutable from "immutable";
import { combineReducers } from "redux";
import { ERROR } from "redux/modules/errors/constants";
import { REQUEST, RECEIVE, TOGGLE_STARRED_EVENT } from "./constants";

const events = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    case TOGGLE_STARRED_EVENT:
      return Immutable.fromJS(state)
        .setIn(
          [
            state.findIndex((event) => event.id === action.payload.eventId),
            "starred",
          ],
          action.payload.starred,
        )
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  events,
  fetching,
});
