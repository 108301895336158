const { uniq } = require("lodash");

export default (fieldId, parentFields, childFields) =>
  uniq([
    ...parentFields.map((id) => ({
      parent_field_id: id,
      child_field_id: fieldId,
    })),
    ...childFields.map((id) => ({
      parent_field_id: fieldId,
      child_field_id: id,
    })),
  ]);
