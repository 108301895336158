const ns = "FORMS_V2_FORM_SECTIONS";

export const CREATE = `${ns}_CREATE`;
export const CREATE_COMPLETE = `${ns}_CREATE_COMPLETE`;
export const UPDATE = `${ns}_UPDATE`;
export const UPDATE_COMPLETE = `${ns}_UPDATE_COMPLETE`;
export const UPDATE_SECTION_FIELD_RELATION = `${ns}_UPDATE_SECTION_FIELD_RELATION`;
export const UPDATE_SECTION_FIELD_RELATION_COMPLETE = `${ns}_UPDATE_SECTION_FIELD_RELATION_COMPLETE`;
export const DELETE = `${ns}_DELETE`;
export const DELETE_COMPLETE = `${ns}_DELETE_COMPLETE`;
export const REORDER = `${ns}_REORDER`;
