/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import superagent from "superagent";
import * as R from "ramda";
import SsoSession from "App/Config/ssoSession";
import { getBaseApiUrl } from "utils/get-base-api-url";

const debug = require("debug")("lennd:helpers");

const Helpers = {
  truncateString: (str, max, add = "...") =>
    typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str,

  /**
   * check if mobile
   */
  isMobile: () => {
    const isMobile =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);
    return isMobile;
  },
  /**
   * check if touch device
   */
  isTouchDevice: () =>
    ("ontouchstart" in window ||
      // eslint-disable-next-line no-undef
      (window.DocumentTouch && document instanceof DocumentTouch)) === true,
  /**
   * check if rendering using browser (vs. server)
   */
  isBrowser: () => typeof window !== "undefined",
  /**
   * get browser locale
   */
  getLocale: () => window.navigator.userLanguage || window.navigator.language,

  /**
   * get logged in data from DOM (is populated via the server)
   */
  getLoggedInProfileFromDom: () =>
    Helpers.isBrowser() ? window.LENND_USER : null,

  /**
   * trim message before sending
   */
  trimMessage: (message) => message.trim().replace(/\n\s*\n\s*\n/g, "\n\n"),

  /**
   * build acronym for a given user's fname and lname (ex: Josh Parolin -> JP)
   */
  buildUserAcronym: (fname, lname) => {
    let acronym = "";
    if (fname) {
      acronym += fname.charAt(0).toUpperCase();
    }
    if (lname) {
      acronym += lname.charAt(0).toUpperCase();
    }

    if (acronym === "") {
      acronym = "?";
    }

    return acronym;
  },

  /**
   * build array of username options
   */
  buildUsernameOptions: (fname, lname, id) => {
    const data = {
      fname: fname || "",
      lname: lname || "",
      id: id || 0,
    };

    const firstNameLastName = [data.fname, data.lname]
      .join()
      .toLowerCase()
      .replace(/[^A-Za-z]/g, "");
    const firstInitialLastName = [data.fname.charAt(0), data.lname]
      .join()
      .toLowerCase()
      .replace(/[^A-Za-z]/g, "");
    const firstNameLastInitial = [data.fname, data.lname.charAt(0)]
      .join()
      .toLowerCase()
      .replace(/[^A-Za-z]/g, "");
    const firstNameLastNameId = `${[data.fname, data.lname]
      .join()
      .toLowerCase()
      .replace(/[^A-Za-z]/g, "")}_${data.id}`;

    const options = [
      firstNameLastName,
      firstInitialLastName,
      firstNameLastInitial,
      firstNameLastNameId,
    ];

    return options;
  },

  getName: (user) => {
    if (user.user_fname && user.user_lname) {
      return `${
        user.user_fname.charAt(0).toUpperCase() + user.user_fname.slice(1)
      } ${user.user_lname.charAt(0).toUpperCase()}${user.user_lname.slice(1)}`;
    }
    if (user.uploaded_by_user_fname && user.uploaded_by_user_lname) {
      return `${
        user.uploaded_by_user_fname.charAt(0).toUpperCase() +
        user.uploaded_by_user_fname.slice(1)
      } ${user.uploaded_by_user_lname
        .charAt(0)
        .toUpperCase()}${user.uploaded_by_user_lname.slice(1)}`;
    }
    return null;
  },

  /**
   * check if given string is valid email
   */
  isValidEmail: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  /**
   * check if given email is empty
   */
  isEmptyMail: (email) => {
    return email === "" || email === undefined || email === null;
  },

  /**
   * check if given string has special characters on it
   */
  isTextWithBlackListedChars: (text) => {
    const re = /([+\-@\=\!])+/;
    return re.test(text);
  },

  /**
   * check if given string is valid password
   */
  isValidPassword: (password) => {
    // password length
    if (!password || password.length < 8) {
      return false;
    }
    // special character
    if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password)) {
      return false;
    }
    // has number
    if (!/\d/g.test(password)) {
      return false;
    }
    // has capital letter
    if (
      !(() => {
        let i = 0;
        while (i < password.length) {
          const character = password.charAt(i);
          if (character === character.toLowerCase()) {
            // Character is lowercase, numeric, or a symbol
          } else if (character === character.toUpperCase()) {
            return true;
          }
          i++;
        }
        return false;
      })()
    ) {
      return false;
    }
    return true;
  },

  /**
   * check if given string is valid email
   */
  isValidHTTPSURL: (url) => {
    try {
      const parsedURL = new URL(url);
      if (parsedURL.protocol.includes("https")) {
        return true;
      }
      throw new Error("invalid url");
    } catch {
      return false;
    }
  },

  /**
   * search users
   */
  searchUsers: (
    credentials,
    query,
    includeClassyMembers = false,
    cb,
    qs = {},
  ) => {
    const authMode = window.__AUTH_MODE__;

    Helpers.request({
      skipUserId: true,
      method: "get",
      url: qs.eventId
        ? `/event/event/${qs.eventId}/users/search`
        : `/organizations/${qs.orgId}/users/search`,
      qs: {
        term: query,
        ...(authMode === "classy-sso" && includeClassyMembers
          ? { includeClassyMembers: true }
          : {}),
      },
      credentials,
      success: cb,
    });
  },

  /**
   * serialize
   */
  serialize: (obj) => {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.join("&");
  },

  /**
   * check if given arrays are identical
   */
  arraysAreIdentical: (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  },

  /**
   * make an API request
   *
   * @param {object} opts
   * @param {string} opts.method
   * @param {string} opts.url
   * @param {boolean} [opts.withCredentials] XHR withCredentials option to include cookies
   * @param {any} [opts.credentials]
   * @param {any} [opts.qs]
   * @param {any} [opts.data]
   * @param {boolean} [opts.isExternal]
   * @param {(...args: any[]) => void} [opts.success]
   * @param {(...args: any[]) => void} [opts.error]
   */
  request: (opts) => {
    const client = {
      // client: "desktop"  // @NOTE: Removing since this currently provides no value and could be passed via headers
    };

    const authMode = window.__AUTH_MODE__;

    let authToken = opts?.credentials?.idToken || "";

    if (authMode == "classy-sso") {
      SsoSession.refreshTokenIfNecessary();
      authToken = opts?.credentials?.access_token || "";
    }

    const handleResponse = (apiError, apiResponse) => {
      if (apiError) {
        // debug('API Error: ' + opts.method + ' ' + opts.url, apiError.toString(), apiResponse);

        // @TODO: Add react error boundary that catches + shows modal about refreshing
        if (R.pathEq(["body", "message"], "jwt expired")(apiResponse)) {
          console.error("[Error] JWT Expired... refreshing session");
          window.location.reload();
          return false;
        }

        if (typeof opts.error === "function") {
          opts.error(
            R.pathOr(
              R.propOr("", "message", apiError),
              ["response", "body"],
              apiError,
            ),
          );
        } else {
          opts.success([]);
        }
      } else {
        debug(`API Success: ${opts.method} ${opts.url}`);
        opts.success(apiResponse.body);
      }
    };

    const baseApiUrl = getBaseApiUrl({
      publicApi: opts.isExternal || false,
      isIdp: opts.credentials?.isIdp || false,
      windowRef: window,
    });

    opts.url = `${baseApiUrl}${opts.url}`;

    if (opts.method === "get") {
      superagent
        .get(opts.url)
        .query(client)
        .query(opts.qs)
        .withCredentials(opts.withCredentials === true)
        .set("Accept", "application/json")
        .set("Authorization", `Bearer ${authToken}`)
        .end(handleResponse);
    } else if (opts.method === "put") {
      superagent
        .put(opts.url)
        .query(client)
        .query(opts.qs)
        .withCredentials(opts.withCredentials === true)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json")
        .set("Authorization", `Bearer ${authToken}`)
        .end(handleResponse);
    } else if (opts.method === "post") {
      superagent
        .post(opts.url)
        .query(client)
        .query(opts.qs)
        .withCredentials(opts.withCredentials === true)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json")
        .set("Authorization", `Bearer ${authToken}`)
        .end(handleResponse);
    } else if (opts.method === "delete") {
      superagent
        .del(opts.url)
        .query(client)
        .query(opts.qs)
        .withCredentials(opts.withCredentials === true)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json")
        .set("Authorization", `Bearer ${authToken}`)
        .end(handleResponse);
    } else if (opts.method === "head") {
      superagent
        .head(opts.url)
        .query(client)
        .query(opts.qs)
        .withCredentials(opts.withCredentials === true)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json")
        .set("Authorization", `Bearer ${authToken}`)
        .end(handleResponse);
    }
  },

  /**
   * Check if a file is an image
   */
  isImage: (mimetype) => {
    const mimeTypes = ["image/jpeg", "image/pjpeg", "image/gif", "image/png"];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a PDF
   */
  isPDF: (mimetype) => {
    const mimeTypes = ["application/pdf"];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a Spreadsheet
   */
  isSpreadsheet: (mimetype) => {
    const mimeTypes = [
      "text/csv",
      "application/excel",
      "application/x-excel",
      "application/x-msexcel",
      "application/vnd.ms-excel",
    ];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a Powerpoint document
   */
  isPowerpoint: (mimetype) => {
    const mimeTypes = [
      "application/mspowerpoint",
      "model/x-pov",
      "image/x-portable-pixmap",
      "application/powerpoint",
      "application/vnd.ms-powerpoint",
      "application/x-mspowerpoint",
    ];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a Word document
   */
  isWordDocument: (mimetype) => {
    const mimeTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a Zip file
   */
  isZipFile: (mimetype) => {
    const mimeTypes = [
      "application/x-gzip",
      "multipart/x-gzip",
      "application/x-compressed",
      "application/x-zip-compressed",
      "application/zip",
      "multipart/x-zip",
    ];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is an Audio file
   */
  isAudio: (mimetype) => mimetype.indexOf("audio/") >= 0,

  /**
   * Check if a file is a Video file
   */
  isVideo: (mimetype) => mimetype.indexOf("video/") >= 0,

  /**
   * Check if a file is an Illustrator file
   */
  isIllustrator: (mimetype) => {
    const mimeTypes = ["application/illustrator"];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Check if a file is a Photoshop document
   */
  isPhotoshop: (mimetype) => {
    const mimeTypes = [
      "application/photoshop",
      "application/psd",
      "application/x-photoshop",
      "image/photoshop",
      "image/psd",
      "image/x-photoshop",
      "image/x-psd",
    ];
    return mimeTypes.indexOf(mimetype) >= 0;
  },

  /**
   * Format a size in bytes to a human readable value
   */
  formatSizeUnits: (bytes) => {
    let result;
    if (bytes >= 1000000000) {
      result = `${(bytes / 1000000000).toFixed(0)}GB`;
    } else if (bytes >= 1000000) {
      result = `${(bytes / 1000000).toFixed(0)}MB`;
    } else if (bytes >= 1000) {
      result = `${(bytes / 1000).toFixed(0)}KB`;
    } else if (bytes > 1) {
      result = `${bytes} bytes`;
    } else if (bytes === 1) {
      result = `${bytes} byte`;
    } else {
      result = "0 bytes";
    }
    return result;
  },
  /**
   * Format a size in bytes to a human readable value
   */
  formatMimeType: (mimetype) => {
    let value = "File";
    if (Helpers.isImage(mimetype)) {
      value = "Image";
    } else if (Helpers.isPDF(mimetype)) {
      value = "PDF";
    } else if (Helpers.isSpreadsheet(mimetype)) {
      value = "Spreadsheet";
    } else if (Helpers.isPowerpoint(mimetype)) {
      value = "Powerpoint";
    } else if (Helpers.isWordDocument(mimetype)) {
      value = "Document";
    } else if (Helpers.isZipFile(mimetype)) {
      value = "Archive";
    } else if (Helpers.isAudio(mimetype)) {
      value = "Audio";
    } else if (Helpers.isVideo(mimetype)) {
      value = "Video";
    } else if (Helpers.isIllustrator(mimetype)) {
      value = "Illustrator";
    } else if (Helpers.isPhotoshop(mimetype)) {
      value = "Photoshop";
    }
    return value;
  },

  /* Filepicker.io */
  getFilepicker: (
    pickerOpts = {},
    storeOpts = {},
    onSuccess,
    onError,
    onProgress,
  ) => {
    const pickerOptions = {
      maxFiles: pickerOpts.maxFiles
        ? pickerOpts.maxFiles
        : pickerOpts.multiple
          ? 50
          : 1,

      displayMode: pickerOpts.displayMode || "overlay",
      fromSources:
        pickerOpts.fromSources && pickerOpts.fromSources.length
          ? pickerOpts.fromSources
          : ["local_file_system", "dropbox"], // services: ["COMPUTER", "DROPBOX"],
      maxSize: pickerOpts.maxSize || 2147483648, // 2gb
      storeTo: {
        location: storeOpts.location || "s3",
        path: storeOpts.path || "event-files/",
      },
      onUploadDone: onSuccess
        ? ({ filesUploaded }) => onSuccess(filesUploaded)
        : undefined,
      onFileUploadFailed: onError
        ? onError
        : (err) => {
            console.error(`Error Uploading via Filestack`, err);
          },
      onFileUploadProgress: onProgress,
      transformations: pickerOpts.transformations || {
        crop: true,
        circle: false,
        rotate: false,
      },
    };

    if (pickerOpts.accept && pickerOpts.accept.length) {
      pickerOptions.accept = pickerOpts.accept;
    }

    const filepicker = window.filestack.init(window.__FILEPICKER_API_KEY__);
    filepicker.picker(pickerOptions).open();
  },

  pdfToJpg: (url, format = "jpg", page = 1) =>
    `https://process.filestackapi.com/${window.__FILEPICKER_API_KEY__}/output=format:${format},page:${page}/${url}`,

  resizeImage: (url, width = 500) =>
    `https://process.filestackapi.com/${window.__FILEPICKER_API_KEY__}/resize=width:${width}/${url}`,
};

export default Helpers;
