import * as R from "ramda";
import hexRgb from "hex-rgb";
const isValidDecRGB = R.test(
  /rgba?\(((25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,\s*?){2}(25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,?\s*([01]\.?\d*?)?\)/,
);

const isValidHexRGB = R.test(/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/);

function shadeRGBColor(color, percent) {
  var f = color.split(","),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = parseInt(f[0].slice(4)),
    G = parseInt(f[1]),
    B = parseInt(f[2]);
  return (
    "rgb(" +
    (Math.round((t - R) * p) + R) +
    "," +
    (Math.round((t - G) * p) + G) +
    "," +
    (Math.round((t - B) * p) + B) +
    ")"
  );
}

function shadeHEXColor(color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

function shadeColor(color, percent) {
  if (isValidDecRGB(color)) {
    return shadeRGBColor(color, percent);
  } else if (isValidHexRGB) {
    return shadeHEXColor(color, percent);
  } else {
    return color;
  }
}

const blackOrWhiteByContrast = (r, g, b) => {
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return a > 0.3 ? "#FFFFFF" : "#000000";
};

const convertHexToRgb = (hex) => hexRgb(hex, { format: "array" });

export {
  convertHexToRgb,
  isValidDecRGB,
  isValidHexRGB,
  shadeColor,
  blackOrWhiteByContrast,
};
