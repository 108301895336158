import React from "react";
import * as R from "ramda";
import { I, basePropTypesOnly } from "../Base/index";
import { capitalizeFirst, mapProps } from "../utils";
import { iconNameMapping } from "../SVGs/iconNameMapping";
import Tooltip from "components/Global/Tooltip";

const tooltipEnhancement =
  (Comp) =>
  ({ tooltip, ...props }) => {
    if (tooltip) {
      return (
        <Tooltip tooltip={tooltip}>
          <div>
            <Comp {...props} />
          </div>
        </Tooltip>
      );
    }
    return <Comp {...props} />;
  };

const FontIcon = tooltipEnhancement(
  ({ from = "material-icons", icon, className, children, ...props }) => {
    const additionalProps = {};
    let appliedIcon = icon;
    // ghetto render transparent conditional
    if (R.isNil(children) && R.isNil(icon)) {
      appliedIcon = "remove"; // could be whatever
      additionalProps.color = "transparent";
    }

    return (
      <I
        className={[from, className].join(" ")}
        color={"neutral5"}
        {...props}
        {...additionalProps}
      >
        {children || appliedIcon}
      </I>
    );
  },
);

const NullFontIcon = tooltipEnhancement((props) => (
  <FontIcon {...props} color={"transparent"}>
    remove
  </FontIcon>
));

const allowedProps = [
  "styleName",
  "className",
  "onClick",
  "tooltip",
  "color",
  "size",
  "sizeWFS",
  "mt",
  "mb",
  "mr",
  "ml",
  "mx",
  "my",
  "m",
  "data-cy",
  "data-path",
];

const namedIcons = R.compose(
  R.fromPairs,
  R.map(([name, svgData]) => {
    let additionalAllowedProps = [];
    let svgFunc = svgData;
    const isSpecialSVG = Array.isArray(svgData);
    if (isSpecialSVG) {
      additionalAllowedProps = svgData[0];
      svgFunc = svgData[1];
    }
    return [
      `${capitalizeFirst(name)}Icon`,
      mapProps(({ style, title, ...other }) => {
        return {
          otherTags: <title id={"title"}>{title || name}</title>,
          "aria-labelledby": "title",
          size: 16,
          style: { fill: "currentColor", ...style },
          color: "neutral5",
          ...R.pick([...allowedProps, ...additionalAllowedProps], other),
        };
      })(tooltipEnhancement(svgFunc)),
    ];
  }),
  R.toPairs,
)(iconNameMapping);

R.forEach(([name, func]) => {
  exports[name] = func;
  func.propTypes = basePropTypesOnly(allowedProps);
}, R.toPairs(namedIcons));

// for story
export { namedIcons };

// for lib
export {
  FontIcon,
  NullFontIcon,
  allowedProps,
  // + the svgs added after the namedIcons def
};
