import { indexedColors } from "./colorSpecs";

const styleSpecs = {
  spacing: 4,
  borderRadius: [0, 4, 8, 12],
  border: [0, 1, 2, 4, 8],
  fontSize: [10, 12, 14, 16, 18, 22, 26, 32, 48, 64],
  fontWeight: ["100", "300", "400", "600", "700"],
  fontFamily: {
    primary: `Mulish, -apple-system, system-ui, Helvetica Neue, Helvetica, Arial, sans-serif`,
    altA: "serif",
    altB: "serif",
  },
  color: indexedColors,
  transition: {
    none: "none",
    fast: "all 0.2s ease-in-out",
    slow: "all 1s ease-in-out",
  },
  shadow: {
    4: "0px 8px 19px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01);",
    3: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    2: "0px 2px 6px rgba(0, 0, 0, 0.07), 0px 1px 3px rgba(0, 0, 0, 0.12)",
    1: "0px 1px 3px rgba(0, 0, 0, 0.07)",
    inset: "inset 0px 1px 3px rgba(0, 0, 0, 0.07)",
    0: "none",
    none: "none",
  },
  breakpoints: [320, 640],
};

export default styleSpecs;
