import { compose } from "ramda";

/* eslint-disable no-underscore-dangle */

const defaultCompose = () => compose;

export const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || defaultCompose;

/* eslint-enable */
