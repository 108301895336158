import { LOCATION_CHANGE } from "react-router-redux";

export const navigationHistory = (state) => state.history;
export const getPathFromCurrent = (state, idx) => {
  try {
    const list = navigationHistory(state);
    const route = list[list.length - idx] || {};
    return route.pathname;
  } catch (err) {
    return undefined;
  }
};
export const currentPath = (state) => {
  return getPathFromCurrent(state, 1);
};
export const previousPath = (state) => {
  return getPathFromCurrent(state, 2);
};
export const history = (
  state = [{ pathname: window.location.pathname }], // prepopulate with current location TODO: retrieve location object via react-router
  action,
) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return [...state, action.payload];
    default:
      return state;
  }
};
