import { combineReducers } from "redux";
import Immutable from "immutable";
import deriveCredential from "./utils/derive-credential-id";
import { RECEIVE, DELETE_LINE_ITEM, UPDATE_META } from "./constants";

const list = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE: {
      // Add in key
      const values = action.payload.values;
      Object.keys(action.payload.values).forEach((id) => {
        values[id].key = deriveCredential(values[id]);
      });

      return {
        ...state,
        ...values,
      };
    }
    case DELETE_LINE_ITEM: {
      return Immutable.fromJS(state).deleteIn([action.payload]).toJS();
    }
    default:
      return state;
  }
};

const references = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .mergeDeep(Immutable.fromJS(action.payload.references))
        .toJS();
    default:
      return state;
  }
};

const fields = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.fields;
    default:
      return state;
  }
};

const meta = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_META:
    case RECEIVE:
      return action.payload.meta;
    default:
      return state;
  }
};

export default combineReducers({
  fields,
  references,
  list,
  meta,
});
