import { combineReducers } from "redux";
import {
  RECEIVE_PAGINATION,
  RECEIVE_LIST,
  RECEIVE_COUNTS,
  REQUEST,
  RECEIVE_RECORD_WITH_ISSUANCE,
  INVALIDATE_RECORD_WITH_ISSUANCE,
} from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import * as R from "ramda";
import Immutable from "immutable";

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE_LIST:
    case ERROR:
      return false;
    default:
      return state;
  }
};
const counts = (state = { issued: 0, remaining: 0, available: 0 }, action) => {
  switch (action.type) {
    case RECEIVE_COUNTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const pagination = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PAGINATION:
      return action.payload;
    default:
      return state;
  }
};
const list = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LIST:
      return action.payload;
    default:
      return state;
  }
};
const recordWithIssuance = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_RECORD_WITH_ISSUANCE:
      return Immutable.fromJS(state)
        .setIn(["byKey", action.payload.key], action.payload.record)
        .toJS();
    case INVALIDATE_RECORD_WITH_ISSUANCE:
      return Immutable.fromJS(state)
        .deleteIn(["byKey", action.payload.key])
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  list,
  pagination,
  counts,
  fetching,
  recordWithIssuance,
});
