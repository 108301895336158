import { ADD_MODULES } from "./constants";
import { combineReducers } from "redux";
import counts from "./counts/reducer";

const list = (state = [], action) => {
  switch (action.type) {
    case ADD_MODULES:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  counts,
});
