import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import people from "./people/reducer";
import person from "./person/reducer";
import formResponses from "./formResponses/reducer";
import groupManager from "./groupManager/reducer";
import user from "./user/reducer";
import form from "./form/reducer";
import forms from "./forms/reducer";
import reports from "./reports/reducer";
import messages from "./messages/reducer";
import intakeDetails from "./intakeDetails/reducer";
import settings from "./settings/reducer";

const portal = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      // mimic eventDetails structure of admin for all the heplers involved
      // with creating reports and summaries in portal
      const newPortal = {
        ...action.payload.portal.portal,
        event: {
          ...action.payload.portal.portal.event,
          module_settings: {
            credentials: {
              // @DEPRECATED - We no longer return credentials here
              credentials: [],
            },
          },
        },
      };
      return newPortal;
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  error,
  fetching,
  form,
  formResponses,
  forms,
  groupManager,
  messages,
  people,
  person,
  portal,
  reports,
  intakeDetails,
  user,
  settings,
});
