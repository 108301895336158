import React from "react";
import { Div, DarkCloseButton, IconLabel } from "../../index";

const IconLabelRemove = ({ text, icon, onRemove, ...props }) => {
  return (
    <Div
      bra={1}
      my={1}
      p={1}
      bg={"neutral2"}
      display={"row.flex-start.center"}
      {...props}
    >
      <IconLabel flex={1} icon={icon} text={text} />
      <DarkCloseButton size={16} onClick={onRemove} />
    </Div>
  );
};

export { IconLabelRemove };
