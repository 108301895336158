import React from "react";
import * as R from "ramda";
import md5 from "md5";
import { Div, Img, getColor, getNamedColors } from "../index";
import { shadeColor, getInitials, withProps, hashStrPickEl } from "../utils";
import { sizes } from "../Level1/BoxSpec";

const isNilOrEmpty = (x) => R.isNil(x) || R.isEmpty(x);

const avatarColorSet = getNamedColors(
  ["purple", "blue", "teal", "yellow", "orange", "red"],
  [6, 8],
);
const colorBuilder = hashStrPickEl(avatarColorSet);

const getAvatarColor = (inputBg, id) => {
  if (R.isNil(inputBg)) {
    if (R.isNil(id)) {
      return "blue6";
    }
    return colorBuilder(id);
  }
  return inputBg;
};

const AvatarBase = ({
  id,
  square = false,
  longInitials = false,
  singleInitial = false,
  bg: inputBg,
  text = "",
  photoURL,
  hashable,
  size,
  sizeOverride,
  fsOverride,
  Icon,
  invert,
  fs,
  b,
  bc,
  onClick,
  style: incomingStyle = {},
}) => {
  const sizeToUse = sizeOverride || size;
  const fsToUse = fsOverride || fs;
  const bg = getAvatarColor(inputBg, id);

  const squareProps = square
    ? {
        pill: false,
        bra: sizeToUse < 40 ? 2 : 3,
      }
    : {};
  let props = {
    display: "row.center.center",
    pill: true,
    color: invert ? bg : "white",
    bg: invert ? shadeColor(getColor(bg), 0.9) : bg,
    fs: fsToUse,
    fw: 3,
    size: sizeToUse,
    b,
    bc,
    onClick,
    ...squareProps,
  };
  const style = { minWidth: sizeToUse, minHeight: sizeToUse, ...incomingStyle };

  if (!isNilOrEmpty(photoURL)) {
    const src = `url(${photoURL})`;
    return (
      <Div
        {...props}
        style={{
          backgroundImage: src,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          ...style,
        }}
      />
    );
  } else if (!isNilOrEmpty(hashable)) {
    const hash = md5(hashable.trim().toLowerCase());
    const src = `https://secure.gravatar.com/avatar/${hash}/?s=${sizeToUse}&r=pg&d=retro`;
    return <Img {...props} src={src} style={style} />;
  } else if (!isNilOrEmpty(Icon)) {
    return (
      <Div {...{ style, ...props }}>
        <Icon size={sizeToUse * 0.7} color={props.color} />
      </Div>
    );
  }
  return (
    <Div {...props} style={style}>
      {getInitials(text, longInitials, singleInitial)}
    </Div>
  );
};

// TODO: fix this jerry rigging
const getSize = (size) => sizes[size].h * 4;

const HugeAvatar = withProps({
  size: 80,
  fs: 8,
})(AvatarBase);
const SuperAvatar = withProps({
  size: getSize("Super"),
  fs: 6,
})(AvatarBase);
const BigAvatar = withProps({
  size: getSize("Big"),
  fs: 5,
})(AvatarBase);
const MediumAvatar = withProps({
  size: getSize("Medium"),
  fs: 3,
})(AvatarBase);
const SmallAvatar = withProps({
  size: getSize("Small"),
  fs: 1,
})(AvatarBase);
const TinyAvatar = withProps({
  size: getSize("Tiny"),
  fs: 0,
})(AvatarBase);

HugeAvatar.displayName = "HugeAvatar";
SuperAvatar.displayName = "SuperAvatar";
BigAvatar.displayName = "BigAvatar";
MediumAvatar.displayName = "MediumAvatar";
SmallAvatar.displayName = "SmallAvatar";
TinyAvatar.displayName = "TinyAvatar";

export {
  HugeAvatar,
  SuperAvatar,
  BigAvatar,
  MediumAvatar,
  SmallAvatar,
  TinyAvatar,
};
