import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import { CircularProgress } from "@mui/material";

const styles = {
  loading: {
    backgroundColor: "rgba(245, 245, 245, 0.88)",
    color: "#aaa",
    fontSize: 15,
    zIndex: 10,
    width: 240,
    padding: "15px 10px",
    borderRadius: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    display: "flex",
    marginRight: 10,
    position: "relative",
    width: 50,
  },
  content: {
    display: "flex",
    flex: 1,
  },
  circle: {
    position: "absolute",
    left: 0,
    top: -13,
  },
};

const Loading = (props) => (
  <div style={[styles.loading, props.styles]} className={props.className}>
    <div style={styles.progress}>
      <CircularProgress style={styles.circle} size={24} mode="indeterminate" />
    </div>
    <div style={styles.content}>{props.content}</div>
  </div>
);

Loading.defaultProps = {
  content: "Loading...",
  styles: {},
  dark: false,
  className: null,
};

Loading.propTypes = {
  dark: PropTypes.bool,
  styles: PropTypes.shape(),
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Radium(Loading);
