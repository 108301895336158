// About: This is a place for generic (non lennd specific) functions.
// Might eventually break these out into separate files,
// and have them just exported from here

/* eslint-disable no-console */

import * as R from "ramda";
import { camelCase } from "lodash";
import moment from "moment"; // eventually pull this out, not necessarily needed
import { v4 as uuidv4 } from "uuid";

// //////////////////////////////////////////////////////////////////////////////

const camelcaseObjKeys = R.curry(
  R.compose(
    R.fromPairs,
    R.map(([key, value]) => [camelCase(key), value]),
    R.toPairs,
  ),
);
// //////////////////////////////////////////////////////////////////////////////

const camelcase = camelCase;
// //////////////////////////////////////////////////////////////////////////////

const sortByIdDict = (sortDict) => (sortSet) => {
  const maxPlus1 = Math.max(R.values(sortDict)) + 1;
  return R.sortBy((e) => R.pathOr(maxPlus1, [R.prop("id", e)], sortDict))(
    sortSet,
  );
};

/*
const setToSort = [
  { id: 'idA', name: 'A' },
  { id: 'idB', name: 'B' },
  { id: 'idC', name: 'C' },
  { id: 'idD', name: 'D' }
];

const dictToSortBy = {
  idA: 1,
  idB: 0,
  idC: 2
};

const sortedSet = sortByIdDict(dictToSortBy)(setToSort)
// returns [
//   { id: 'idB', name: 'B' },
//   { id: 'idA', name: 'A' },
//   { id: 'idC', name: 'C' },
//   { id: 'idD', name: 'D' } // pushes unordered elements to end
// ];
*/

// //////////////////////////////////////////////////////////////////////////////

const sortArrByKeyDict = (sortDict) => (arr) => {
  const maxPlus1 = Math.max(R.values(sortDict)) + 1;
  return R.sortBy((e) => R.propOr(maxPlus1, e)(sortDict))(arr);
};

/*
const setToSort = [
  'keyA',
  'keyB',
  'keyC',
  'keyD',
];

const dictToSortBy = {
  keyA: 1,
  keyB: 0,
  keyC: 2
};

const sortedSet = sortByIdDict(dictToSortBy)(setToSort)
// returns [
//   'keyB',
//   'keyA',
//   'keyC',
//   'keyD', // pushes unordered elements to end
// ];
*/

// //////////////////////////////////////////////////////////////////////////////

const print =
  (logs = true) =>
  (funcName) =>
  (func) =>
  (...props) => {
    const result = func(...props);
    if (logs) {
      console.group(`%c${funcName}`, "color: red");
      console.log("%cprops  ", "color: green", ...props);
      console.log("%cresult ", "color: navy", result);
      console.groupEnd();
    }
    return result;
  };

/*
// essentially a log flag for this file
const filePrinter = print(true)

const add = filePrinter('add')((a,b) => a + b);

const five = add(2,3)
// will log:
//
// add
//   props  2,3
//   result 5

*/

// //////////////////////////////////////////////////////////////////////////////

const multiDimCond = (conds, defaultFunc, actions) => {
  const key = R.reduce((a, c) => (c ? `${a}T` : `${a}F`), "", conds);
  const keyList = R.reverse(
    R.addIndex(R.map)((e, i) => R.slice(0, i + 1, key), key),
  );
  const matchedFunc = R.reduce(
    (a, c) => (a === undefined ? actions[c] : a),
    undefined,
    keyList,
  );
  const func = matchedFunc || defaultFunc;
  return func();
};

/*

// matches all condition key
// returns '1'
const story1 = multiDimCond([true, true, false], () => 'default', {
TTF: () => '1',
FFF: () => '2'
});

// matches closest ancestor if all condition key not present
// returns '3'
const story2 = multiDimCond([true, true, false], () => 'default', {
TT: () => '3',
FFF: () => '4'
});

// matches to default if no all conditional or ancestor key present
// returns 'default'
const story3 = multiDimCond([true, true, false], () => 'default', {
TF: () => '5',
FFF: () => '6'
});

*/

// //////////////////////////////////////////////////////////////////////////////

const arrayToIdOrderDict = (orderedArr) =>
  R.fromPairs(R.addIndex(R.map)(({ id }, i) => [id, i], orderedArr));

// //////////////////////////////////////////////////////////////////////////////

const filterProps = (propsIn0) => (props) => {
  const sortedProps = [{}, {}];
  Object.keys(props).forEach((prop) => {
    const sortIndex = R.contains(prop, propsIn0) ? 0 : 1;
    sortedProps[sortIndex] = {
      ...sortedProps[sortIndex],
      [prop]: props[prop],
    };
  });
  return sortedProps;
};

// //////////////////////////////////////////////////////////////////////////////

const bkdrhash = function (str) {
  const seed = 131;
  const seed2 = 137;
  let hash = 0;
  // make hash more sensitive for short string like 'a', 'b', 'c'
  const nstr = str + "x";
  // Note: Number.MAX_SAFE_INTEGER equals 9007199254740991
  const MAX_SAFE_INTEGER = parseInt(9007199254740991 / seed2);
  for (let i = 0; i < nstr.length; i++) {
    if (hash > MAX_SAFE_INTEGER) {
      hash = parseInt(hash / seed2);
    }
    hash = hash * seed + nstr.charCodeAt(i);
  }
  return hash;
};

// //////////////////////////////////////////////////////////////////////////////

const noop = () => {};

// //////////////////////////////////////////////////////////////////////////////

const hashStrPickEl = (set) => (str) => set[bkdrhash(str) % R.length(set)];

// //////////////////////////////////////////////////////////////////////////////

// supDUDE to SupDUDE
const capitalizeFirst = (str) => R.toUpper(str[0]) + R.slice(1, Infinity, str);

// supDUDE to Supdude
const capitalize = R.compose(capitalizeFirst, R.toLower);

// //////////////////////////////////////////////////////////////////////////////

const findBy = (key, value) => R.find(R.propEq(key, value));
/*
findBy('id', 3)([{id: 1}, {id: 2}, {id: 3}]) //=> {id: 3}
*/

// //////////////////////////////////////////////////////////////////////////////

const recursiveIteratorMaker = (func) => {
  const recursiveIterator = (iterables, endFunc, elements = []) =>
    func((...args) => {
      const newIterable = R.slice(1, Infinity, iterables);
      const newElements = [...elements, args];
      if (R.isEmpty(newIterable)) {
        return endFunc(newElements);
      }
      return recursiveIterator(newIterable, endFunc, newElements);
    }, iterables[0]);
  return recursiveIterator;
};

const deepMapFlat = R.compose(R.flatten, recursiveIteratorMaker(R.map));

/*

const a = ['0','1','2']
const b = ['a','b','c']
const c = ['x','y','z']

deepMap([a,b,c], (([i,j,k]) => i+j+k))

// const result = [
//   [['0ax', '0ay', '0az'], ['0bx', '0by', '0bz'], ['0cx', '0cy', '0cz']],
//   [['1ax', '1ay', '1az'], ['1bx', '1by', '1bz'], ['1cx', '1cy', '1cz']],
//   [['2ax', '2ay', '2az'], ['2bx', '2by', '2bz'], ['2cx', '2cy', '2cz']]
// ];

 */

// ////////////////////////////////////////////////////////////////////////////

const getFirstDefined = R.reduce((a, c) => (!R.isNil(a) ? a : c), undefined);

// //////////////////////////////////////////////////////////////////////////////

const flattenToIndexedKeys = (obj) =>
  R.reduce(
    (a, [key, value]) => {
      if (typeof value === "object") {
        return {
          ...a,
          ...R.addIndex(R.reduce)(
            (va, vc, i) => ({ ...va, [`${key}${i}`]: vc }),
            {},
            value,
          ),
        };
      }
      return {
        ...a,
        [key]: value,
      };
    },
    {},
    R.toPairs(obj),
  );

// ////////////////////////////////////////////////////////////////////////////

const sortByPickers = (pickers) => (props) =>
  R.map(
    (picker) => R.pick(picker, props),
    [...pickers, R.difference(R.keys(props), R.reduce(R.union, [], pickers))],
  );

/*
// pickers :: array of array of keys
// props :: object

// returns :: array of objects, sorted as per keys, with last object collecting unmatched keys

const sorted = sortByPickers([['a','c'],['d','e','f']])({
  a: 'wert',
  b: 'asdf',
  c: 'oidjfg',
  d: 'owem',
  e: 'lmdfk',
  f: 'aosiff',
  g: 'oaisjdf'
})

// sorted = [
//   {
//     a: 'wert',
//     c: 'oidjfg',
//   },
//   {
//     d: 'owem',
//     e: 'lmdfk',
//     f: 'aosiff',
//   },
//   {
//     b: 'asdf',
//     g: 'oaisjdf'
//   }
// ]
*/

// //////////////////////////////////////////////////////////////////////////

const crossSpread = (keys, values) =>
  R.fromPairs(
    R.addIndex(R.map)(
      (key, i) => [
        key,
        R.fromPairs(
          R.map(
            ([propName, valueValues]) => [propName, valueValues[i]],
            R.toPairs(values),
          ),
        ),
      ],
      keys,
    ),
  );

/*
const specs = crossSpread(['Mercury', 'Venus', 'Earth'], {
  a: [0, 1, 2],
  b: [2, 2, 2],
  c: [4, 6, 8],
  d: ['x','y','z']
});

// specs = {
//   Mercury: {
//     a: 0,
//     b: 2,
//     c: 4,
//     d: 'x'
//   },
//   Venus: {
//     a: 1,
//     b: 2,
//     c: 6,
//     d: 'y'
//   },
//   Earth: {
//     a: 2,
//     b: 2,
//     c: 8
//     d: 'z'
//   },
// }
*/

// ////////////////////////////////////////////////////////////////////////

const rootMeanSquare = (xs) =>
  Math.sqrt(xs.reduce((a, x) => a + x * x, 0) / xs.length);

// //////////////////////////////////////////////////////////////////////

const sortByKeyIntoZeroIndexArr = (func) =>
  R.compose(
    R.map(([k, v]) => ({ [k]: func(v) })),
    R.sort(([k]) => -k),
    R.toPairs,
  );

const sortByKeyIntoKeyValueArr = (func) =>
  R.compose(
    R.map(([k, v]) => ({ key: k, value: func(v) })),
    // @NOTE: Change to -k to sort inverse
    R.sort(([k]) => k),
    R.toPairs,
  );

// ////////////////////////////////////////////////////////////////////

const setEvent = (date, events, event) => {
  const lens = R.lensPath([
    date.format("Y"),
    date.format("M"),
    date.format("D"),
  ]);
  const otherActivities = R.view(lens, events) || [];
  return R.set(lens, [...otherActivities, event], events);
};

const orderEventsByYearMonthDay = (
  events,
  startProp = "start",
  endProp = "end",
) => {
  let eventsByYearObj = {};

  R.forEach(
    (event) => {
      const start = !R.isNil(event[startProp])
        ? moment(event[startProp])
        : moment();
      const end = !R.isNil(event[endProp])
        ? moment(event[endProp])
        : start.clone().add(2, "hours");

      if (start.isSame(end, "day")) {
        eventsByYearObj = setEvent(start, eventsByYearObj, {
          ...event,
          todaysTime: start,
          isStartDate: true,
          isEndDate: true,
        });
      } else {
        let counter = start.clone().add(1, "days");
        // add start event
        eventsByYearObj = setEvent(start, eventsByYearObj, {
          ...event,
          todaysTime: start,
          isStartDate: true,
          isEndDate: false,
        });

        while (start.isBefore(end) && counter.isBefore(end, "day")) {
          // add mid event
          eventsByYearObj = setEvent(counter, eventsByYearObj, {
            ...event,
            todaysTime: moment(counter.format("YYYY-MM-DD")),
            isStartDate: false,
            isEndDate: false,
          });
          counter = counter.clone().add(1, "days");
        }

        // add end event
        eventsByYearObj = setEvent(end, eventsByYearObj, {
          ...event,
          todaysTime: moment(end.format("YYYY-MM-DD")),
          isStartDate: false,
          isEndDate: true,
        });
      }
    },
    [
      ...events,
      {
        [startProp]: moment().format(), // current time in ISO formatting
        isTimeSpacer: true,
      },
    ],
  );

  const eventsByYear = R.compose(
    sortByKeyIntoKeyValueArr,
    sortByKeyIntoKeyValueArr,
    sortByKeyIntoKeyValueArr,
  )(R.sortBy(R.prop("todaysTime")))(eventsByYearObj);

  return eventsByYear;
};

/*
const input = [
  {
    start: '2018-01-23',
    name: 'bob'
  },
  {
    start: '2018-01-23',
    thing: 'table'
  },
  {
    start: '2017-11-03',
    name: 'dan'
  },
  {
    start: '2017-01-24',
    quality: 'great',
    name: 'bike'
  }
];

const result = orderEventsByYearMonthDay(input, 'start');
// result = [
//   {
//     key: '2017',
//     value: [
//       {
//         key: '11',
//         value: [
//           {
//             key: '03',
//             value: [
//               {
//                 start: '2017-11-03',
//                 name: 'dan'
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     key: '2018',
//     value: [
//       {
//         key: '01',
//         value: [
//           {
//             key: '23',
//             value: [
//               {
//                 start: '2018-01-23', // will be ordered by time if present
//                 name: 'bob'
//               },
//               {
//                 start: '2018-01-23',
//                 thing: 'table'
//               }
//             ]
//           },
//           {
//             key: '24',
//             value: [
//               {
//                 start: '2017-01-24',
//                 quality: 'great',
//                 name: 'bike'
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   }
// ];

*/

// //////////////////////////////////////////////////////////////////
const addS = (thing) => (thing === 1 ? "" : "s");

// //////////////////////////////////////////////////////////////////
const padNumber = (number) => ("0" + number).slice(-2);

// //////////////////////////////////////////////////////////////////

const groupByBreaks = (breakPred) => (list) => {
  const lastIndex = R.length(list) - 1;
  return R.reduce(
    (a, el) => {
      const index = R.length(a) - 1;
      const preResult = R.update(index, [...a[index], el], a);
      if (breakPred(el) && !lastIndex) {
        return [...preResult, []];
      }

      return preResult;
    },
    [[]],
    list,
  );
};

/*

const input = [
  {
    name: "K",
    isTheEndOfTheGroup: false
  },
  {
    name: "L",
    isTheEndOfTheGroup: true
  },
  {
    name: "M",
    isTheEndOfTheGroup: false
  },
  {
    name: "N",
    isTheEndOfTheGroup: false
  },
  {
    name: "O",
    isTheEndOfTheGroup: true
  },
  {
    name: "P",
    isTheEndOfTheGroup: false
  },
  {
    name: "Q",
    isTheEndOfTheGroup: false
  }
];

const myGrouper = groupByBreaks(el => el.isTheEndOfTheGroup);

const result = myGrouper(input);

// result = [
//   [
//     {
//       name: "K",
//       isTheEndOfTheGroup: false
//     },
//     {
//       name: "L",
//       isTheEndOfTheGroup: true
//     }
//   ],
//   [
//     {
//       name: "M",
//       isTheEndOfTheGroup: false
//     },
//     {
//       name: "N",
//       isTheEndOfTheGroup: false
//     },
//     {
//       name: "O",
//       isTheEndOfTheGroup: true
//     }
//   ],
//   [
//     {
//       name: "P",
//       isTheEndOfTheGroup: false
//     },
//     {
//       name: "Q",
//       isTheEndOfTheGroup: false
//     }
//   ]
// ];

*/

// //////////////////////////////////////////////////////////////////

const verifyIsLetterOrNumber = (x) => (R.test(/[a-zA-Z0-9]/g)(x) ? x : "");

const getInitials = (string, longInitials = false, singleInitial = false) => {
  if (!string) {
    return "";
  }

  const words = R.split(" ", string);
  let initials = "";
  if (longInitials && words.length > 1) {
    initials = capitalize(R.take(2, words[0]));
  } else if (singleInitial) {
    initials = R.take(1, words[0]);
  } else if (R.length(words) === 2) {
    initials =
      verifyIsLetterOrNumber(R.take(1, words[0])) +
      verifyIsLetterOrNumber(R.take(1, words[1]));
  } else if (R.length(words) > 0) {
    initials = R.take(1, words[0]);
  }
  return initials;
};

// //////////////////////////////////////////////////////////////////

const runWithUndefined = (path) => {
  return R.map((el) => {
    if (typeof el === "function") {
      return el(undefined);
    }
    return el;
  }, path);
};

const getPath = (path, obj) => {
  const head = R.head(path);
  const tail = R.tail(path);
  let result;
  switch (typeof head) {
    case "function":
      const prop = head(obj);
      if (R.isEmpty(tail)) {
        return [prop];
      }
      result = obj[prop];
      if (R.isNil(result)) {
        return [prop, ...runWithUndefined(tail)];
      }
      return [prop, ...getPath(tail, result)];
    default:
      if (R.isEmpty(tail)) {
        return [head];
      }
      result = obj[head];
      if (R.isNil(result)) {
        return [head, ...runWithUndefined(tail)];
      }
      return [head, ...getPath(tail, result)];
  }
};

const pickerLens = (path, obj) => {
  const myPath = getPath(path, obj);
  if (R.contains(undefined, myPath)) {
    return undefined;
  }
  return R.lensPath(myPath);
};

/*

const myObj = {
  a: [
    {
      id: 1,
      x: ["value1", "value2", "value3"]
    },
    {
      id: 2,
      x: ["value6", "value23", "value91"]
    }
  ],
  b: []
};

const myUpdater = (id, obj) => {
  const myPicker = arr => {
    const result = R.findIndex(R.propEq("id", id))(arr);
    if (result === undefined) {
      return R.length(arr);
    }
    return result;
  };

  const lens = pickerLens(["a", myPicker, "z", 0], obj);
  if (R.isNil(lens)) {
    return obj;
  } else {
    return R.over(lens, () => "newValue", obj);
  }
};
const result = myUpdater(1, myObj)
// result = {
//   a: [
//     {
//       id: 1,
//       x: ["newValue", "value2", "value3"]
//     },
//     {
//       id: 2,
//       x: ["value6", "value23", "value91"]
//     }
//   ],
//   b: []
// };

*/

////////////////////////////////////////////////////////////////////////////////

const makeEnum = R.compose(
  Object.freeze,
  R.fromPairs,
  R.map((x) => [x, x]),
);

/*
const result = makeEnum(["APPLES", "ORANGES", "BANANAS"])
// result = {
//   APPLES: "APPLES",
//   ORANGES: "ORANGES",
//   BANANAS: "BANANAS"
// }
*/

////////////////////////////////////////////////////////////////////////////////

const isEmptyOrNil = (x) => R.isNil(x) || R.isEmpty(x);

////////////////////////////////////////////////////////////////////////////////

const oxfordJoin = (list) => {
  if (list.length <= 2) {
    return list.join(" and ");
  }
  return list.reduce((str, val) => {
    if (list.length - 1 === list.indexOf(val)) {
      return `${str}, and ${val}`;
    }
    if (str.length) {
      return `${str}, ${val}`;
    }
    return val;
  }, "");
};

////////////////////////////////////////////////////////////////////////////////

const isCyclic = function (obj) {
  var seenObjects = [];

  function detect(obj) {
    if (obj && typeof obj === "object") {
      if (seenObjects.indexOf(obj) !== -1) {
        return true;
      }
      seenObjects.push(obj);
      for (var key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          detect(obj[key])
        ) {
          console.log(obj, "cycle at " + key);
          return true;
        }
      }
    }
    return false;
  }

  return detect(obj);
};

////////////////////////////////////////////////////////////////////////////////
const toggleListItem = R.curry((value, items) =>
  R.pipe(R.ifElse(R.contains(value), R.without(value), R.append(value)))(items),
);
////////////////////////////////////////////////////////////////////////////////

const navigateTo = (location) => {
  window.location = location;
};

const openInNewWindow = (location, target = "_blank") => {
  window.open(location, target);
};

const joinNotNullBy = (p = "") =>
  R.compose(
    R.ifElse(R.isEmpty, R.always(null), R.identity),
    R.compose(
      R.join(p),
      R.filter(R.compose(R.not, R.either(R.isNil, R.isEmpty))),
    ),
  );

const createTempIds = (ids) =>
  R.reduce(
    (acc, oId) => {
      acc[oId] = uuidv4();
      return acc;
    },
    {},
    ids,
  );

const toString = (x) => (typeof x === "string" ? x : R.toString(x));

// liftToArr: wraps value in array if it's not an array already
const liftToArr = (val) => (Array.isArray(val) ? val : val ? [val] : []);

// liftToArr

// checkDiff: dev util, debug selectors
const checkSelectorDiff = (f, ns) => {
  let oldVal;
  return (...params) => {
    const newVal = f(...params);
    if (newVal !== oldVal) {
      // eslint-disable-next-line no-console
      console.log(ns);
    }
    oldVal = newVal;
    return newVal;
  };
};

// checkDiff

const parseComboId = (comboId) => comboId.split("_");

const stripHtml = (html = "") => {
  const htmlWithParagraphsToNewLines = html.replace(
    /(<br>)|(<br \/>)|(<p>)|(<\/p>)/g,
    "\r\n",
  );
  const doc = new DOMParser().parseFromString(
    htmlWithParagraphsToNewLines,
    "text/html",
  );
  return doc.body.textContent || "";
};

const toUnderscore = (str) =>
  str
    .replace(/\.?([A-Z])/g, (_, y) => {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");

const toDash = (str) =>
  typeof str === "string" ? str.replace(/\s/g, "-").toLowerCase() : "";

const mapKeys = (map, obj) =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [map(key)]: obj[key] }), {});

const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

const adjustColor = (color, amount) =>
  "#" +
  color
    .replace(/^#/, "")
    .replace(/../g, (color) =>
      (
        "0" +
        Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
      ).substr(-2),
    );

export {
  pickTextColorBasedOnBgColorSimple,
  adjustColor,
  toggleListItem,
  camelcaseObjKeys,
  camelcase,
  toUnderscore,
  toDash,
  sortByIdDict,
  sortArrByKeyDict,
  print,
  multiDimCond,
  arrayToIdOrderDict,
  filterProps,
  bkdrhash,
  noop,
  hashStrPickEl,
  capitalizeFirst,
  capitalize,
  findBy,
  recursiveIteratorMaker,
  deepMapFlat,
  flattenToIndexedKeys,
  getFirstDefined,
  sortByPickers,
  crossSpread,
  rootMeanSquare,
  sortByKeyIntoZeroIndexArr,
  sortByKeyIntoKeyValueArr,
  orderEventsByYearMonthDay,
  addS,
  padNumber,
  getInitials,
  groupByBreaks,
  pickerLens,
  makeEnum,
  isEmptyOrNil,
  oxfordJoin,
  isCyclic,
  navigateTo,
  openInNewWindow,
  joinNotNullBy,
  createTempIds,
  toString,
  liftToArr,
  checkSelectorDiff,
  parseComboId,
  stripHtml,
  mapKeys,
};
