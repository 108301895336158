import * as R from "ramda";
import { combineReducers } from "redux";
import {
  RECEIVE_PAGINATION,
  RECEIVE_LIST,
  RECEIVE_STATS,
  REQUEST,
  TOGGLE_MENU,
  LANDED,
} from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE_LIST:
    case ERROR:
      return false;
    default:
      return state;
  }
};
const stats = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_STATS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const pagination = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PAGINATION:
      return action.payload;
    default:
      return state;
  }
};
const list = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LIST:
      return action.payload;
    default:
      return state;
  }
};

const menues = (state = { opened: {}, landed: [] }, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      if (R.has(action.payload, state.opened)) {
        return {
          ...state,
          opened: R.omit([action.payload], state.opened),
        };
      } else {
        return {
          ...state,
          opened: { ...state.opened, [action.payload]: action.payload },
        };
      }
    case LANDED:
      return {
        opened: R.reduce(
          (acc, landed) =>
            R.contains(landed, state.landed)
              ? acc
              : R.mergeAll([{ [landed]: landed }, acc]),
          state.opened,
          action.payload,
        ),
        landed: R.uniq(R.concat(state.landed, action.payload)),
      };
    default:
      return state;
  }
};

export default combineReducers({
  stats,
  pagination,
  list,
  fetching,
  menues,
});
