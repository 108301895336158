import Immutable from "immutable";
import { combineReducers } from "redux";
import {
  RECEIVE,
  ADD_COMPLETE,
  ADD_BULK_COMPLETE,
  REMOVE,
  REMOVE_BULK,
  UPDATE,
} from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { get } from "lodash";

const cart = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.submissionId], action.payload.cart)
        .toJS();
    case ADD_COMPLETE:
      return Immutable.fromJS(state)
        .setIn(
          ["byId", action.payload.submissionId],
          [
            ...get(state, ["byId", action.payload.submissionId], []),
            action.payload.lineItem,
          ],
        )
        .toJS();
    case ADD_BULK_COMPLETE:
      return Immutable.fromJS(state)
        .setIn(
          ["byId", action.payload.submissionId],
          [
            ...get(state, ["byId", action.payload.submissionId], []),
            ...action.payload.lineItems,
          ],
        )
        .toJS();
    case REMOVE:
      return Immutable.fromJS(state)
        .setIn(
          ["byId", action.payload.submissionId],
          [
            ...get(state, ["byId", action.payload.submissionId], []).filter(
              (i) => i.id !== action.payload.lineItemId,
            ),
          ],
        )
        .toJS();
    case REMOVE_BULK:
      return Immutable.fromJS(state)
        .setIn(
          ["byId", action.payload.submissionId],
          [
            ...get(state, ["byId", action.payload.submissionId], []).filter(
              (i) => !action.payload.lineItemIds.includes(i.id),
            ),
          ],
        )
        .toJS();
    default:
      return state;
  }
};

export default cart;
