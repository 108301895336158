import * as R from "ramda";
import { getters } from "./model";
import moment from "moment";

import { getFieldId, getSubmissionId } from "./utils";

export const getFieldById = (state, props) =>
  R.path(["byId", getFieldId(props)], getters.fields(state, props));

export const getSubmissionById = (state, props) =>
  R.path(["byId", getSubmissionId(props)], getters.submissions(state, props));

/*
  event templates by uuid
 */
export const getEventTemplateByUUID = (state, props) =>
  R.path(["byId", props.eventUUID], getters.eventTemplates(state, props));

/*
  event templates by uuid
*/

export const getAllRecordReferences = getters.recordReferences;

export const getAllRecordReferencesById = R.compose(
  R.prop("byId"),
  getters.recordReferences,
);

export const getAllFieldReferencesById = R.compose(
  R.prop("byId"),
  getters.fieldReferences,
);

// support articles
export const getSupportArticles = R.compose(
  R.prop("articles"),
  getters.support,
);

export const getSupportArticlesById = (state, props) =>
  R.compose(R.prop(props.id), R.prop("articles"), getters.support)(state);

export const getSalesforceProperties = () => {
  if (window.__USE_SALESFORCE_LIVEAGENT_SANDBOX__) {
    return {
      scriptUrl:
        "https://stayclassy--full.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
      gslbBaseUrl: "https://service.force.com",
      salesforceDomain: "https://stayclassy--full.sandbox.my.salesforce.com",
      liveAgentSiteUrl: "https://stayclassy--full.sandbox.my.site.com",
      liveAgentOrganizationId: "00DM0000000Gcj7",
      liveAgentDeploymentName: "Classy_Live_Chat",
      baseLiveAgentContentURL:
        "https://c.la3-c1cs-dfw.salesforceliveagent.com/content",
      deploymentId: "57244000000LEk9",
      buttonId: "5732R0000000Iyr",
      baseLiveAgentURL: "https://d.la3-c1cs-dfw.salesforceliveagent.com/chat",
      eswLiveAgentDevName:
        "EmbeddedServiceLiveAgent_Parent04I2R0000004C9hUAE_18096391456",
      isOfflineSupportEnabled: true,
      enabledFeatures: ["LiveAgent"],
      entryFeature: "LiveAgent",
      storageDomain: window.location.hostname,
      devMode: true,
    };
  }

  return {
    scriptUrl:
      "https://stayclassy.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    gslbBaseUrl: "https://service.force.com",
    salesforceDomain: "https://stayclassy.my.salesforce.com",
    liveAgentSiteUrl: "https://classysupport.force.com",
    liveAgentOrganizationId: "00DE0000000K08T",
    liveAgentDeploymentName: "Classy_Live_Chat",
    baseLiveAgentContentURL:
      "https://c.la5-c1-ia5.salesforceliveagent.com/content",
    deploymentId: "57244000000LEk9",
    buttonId: "5732R0000000Iyr",
    baseLiveAgentURL: "https://d.la5-c1-ia5.salesforceliveagent.com/chat",
    eswLiveAgentDevName:
      "EmbeddedServiceLiveAgent_Parent04I2R0000004C9hUAE_18096391456",
    isOfflineSupportEnabled: true,
    enabledFeatures: ["LiveAgent"],
    entryFeature: "LiveAgent",
    storageDomain: window.location.hostname,
    devMode: false,
  };
};

// attend

export const getAttendEnableFundraiserStageOverlay = R.compose(
  R.prop("enableFundraiserStageOverlay"),
  getters.attend,
);

export const getAttendEnableFundraiserLeaderboard = R.compose(
  R.prop("enableFundraiserLeaderboard"),
  getters.attend,
);

export const getAttendEnableStageChat = R.compose(
  R.prop("enableStageChat"),
  getters.attend,
);

export const isAuthorizingAttend = R.compose(
  R.prop("authorizing"),
  getters.attend,
);

export const getAttendAuthFirstName = R.compose(
  R.path(["auth", "first_name"]),
  getters.attend,
);

export const getAttendAuthLastName = R.compose(
  R.path(["auth", "last_name"]),
  getters.attend,
);

export const getAttendAuthEmail = R.compose(
  R.path(["auth", "email"]),
  getters.attend,
);

export const getAttendAuthPhone = R.compose(
  R.path(["auth", "mobile_phone"]),
  getters.attend,
);

export const getAttendIsBidOnlyUser = R.compose(
  R.path(["auth", "is_bid_only_user"]),
  getters.attend,
);

export const getAttendUserContactId = R.compose(
  R.path(["auth", "contact_id"]),
  getters.attend,
);

export const getAttendeeUser = R.compose(
  R.pick(["first_name", "last_name", "photo_url", "user_id"]),
  R.prop("auth"),
  getters.attend,
);

export const getAttendUserPhotoUrl = R.compose(
  R.path(["auth", "photo_url"]),
  getters.attend,
);

export const getAttendUserName = R.compose(
  (auth) =>
    [auth.first_name, auth.last_name].filter((v) => v && v.length).join(" "),
  R.prop("auth"),
  getters.attend,
);

export const getAttendUserEmail = R.compose(
  R.path(["auth", "email"]),
  getters.attend,
);

export const getAttendTemplate = R.compose(R.prop("template"), getters.attend);

export const getAttendDetails = R.compose(
  R.propOr({}, "details"),
  getters.attend,
);

export const getAttendEventName = R.compose(
  R.path(["event", "name"]),
  getters.attend,
);

export const getAttendEventRegistrationUrl = R.compose(
  R.prop("registrationUrl"),
  getters.attend,
);

export const getAttendDefaultStageId = R.compose(
  R.prop("defaultStageId"),
  getters.attend,
);

export const getAttendDefaultRoomId = R.compose(
  R.prop("defaultRoomId"),
  getters.attend,
);

export const getAttendColor = R.compose(R.prop("color"), getters.attend);

export const getAttendIsPaymentEnabled = R.compose(
  R.prop("isPaymentEnabled"),
  getters.attend,
);

export const getAttendEventStripeToken = R.compose(
  R.prop("eventStripeToken"),
  getters.attend,
);

export const getAttendIsNetworkingEnabled = R.compose(
  R.prop("isNetworkingEnabled"),
  getters.attend,
);

export const getAttendEventSlug = R.compose(
  (date) => moment().isAfter(date),
  R.path(["event", "slug"]),
  getters.attend,
);

export const getAttendIsEventOpen = R.compose(
  (date) => moment().isAfter(date),
  R.path(["event", "date_from"]),
  getters.attend,
);

export const getAttendAreEventLinksAccessible = R.compose(
  (date) => moment().isAfter(moment(date).subtract(5, "minutes")),
  R.path(["event", "date_from"]),
  getters.attend,
);

export const getAttendIsEventOver = R.compose(
  (date) => moment().isAfter(date),
  R.path(["event", "date_to"]),
  getters.attend,
);

export const getAttendEventStartDate = R.compose(
  R.path(["event", "date_from"]),
  getters.attend,
);

export const getAttendEventEndDate = R.compose(
  R.path(["event", "date_to"]),
  getters.attend,
);

export const getAttendLive = R.compose(R.propOr([], "live"), getters.attend);

export const getAttendIsAdmin = R.compose(
  R.path(["auth", "is_admin"]),
  getters.attend,
);

export const getAttendHasPaymentMethod = R.compose(
  R.path(["auth", "has_payment_method"]),
  getters.attend,
);

export const getAttendHasAccess = R.compose(
  R.path(["auth", "has_access"]),
  getters.attend,
);

export const getAttendAttendeeId = R.compose(
  R.path(["auth", "attendee_id"]),
  getters.attend,
);

export const getAttendEventUUID = R.compose(
  R.path(["event", "uuid"]),
  getters.attend,
);

export const getAttendUserProfile = R.compose(
  R.prop("userProfile"),
  getters.attend,
);

export const getAttendSessions = R.compose(
  R.propOr([], "sessions"),
  getters.attend,
);

export const getAttendPreferences = R.compose(
  R.propOr({}, "preferences"),
  getters.attend,
);

export const getAttendCartItems = R.compose(
  R.propOr([], "cartItems"),
  getters.attend,
);

export const getAttendPaymentMethods = R.compose(
  R.propOr([], "paymentMethods"),
  getters.attend,
);

export const getAttendCountOfCartAlerts = R.compose(
  R.propOr(0, "countOfCartAlerts"),
  getters.attend,
);

export const getAttendHasVirtualVenue = R.compose(
  R.prop("hasVirtualVenue"),
  getters.attend,
);

export const getAttendHasAuction = R.compose(
  R.prop("hasAuction"),
  getters.attend,
);

export const getAttendHasAuctionTerms = R.compose(
  (v) => (v && v.length > 0 ? true : false),
  R.prop("auctionTerms"),
  getters.attend,
);

export const getAttendAuctionTerms = R.compose(
  R.prop("auctionTerms"),
  getters.attend,
);

export const getAttendIsExternalBiddingEnabled = R.compose(
  R.prop("isExternalBiddingEnabled"),
  getters.attend,
);

export const getIsBulkPayEnabled = R.compose(
  R.prop("isBulkPayEnabled"),
  getters.attend,
);

export const getIsBillingAddressRequired = R.compose(
  R.prop("isBillingAddressRequired"),
  getters.attend,
);

export const getMaxWidthMobile = R.always(767);
