import { createModule, createHandlers } from "redux-mvc";

import sidebar from "ui-kit/Sidebar";
import snackbar from "ui-kit/Snackbar";
import toasts from "ui-kit/Toasts";
import attendGate from "Attend/Gate";
import invoiceGate from "Invoice/Gate";
import attendPublicAuction from "Attend/AuctionPageWrapper";
import data from "App/Data";
import connection from "App/Connection";
import eventLayout from "EventLight/Layout";
import orgLayout from "OrgLight/Layout";

const model = createHandlers({
  namespace: "MVCGlobal",
});

const module = createModule(model);

module.plugModule(sidebar);
module.plugModule(snackbar);
module.plugModule(toasts);
module.plugModule(data);
module.plugModule(attendGate);
module.plugModule(invoiceGate);
module.plugModule(connection);
module.plugModule(eventLayout);
module.plugModule(orgLayout);
module.plugModule(attendPublicAuction);

export default module;
