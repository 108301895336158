import React, { Component } from "react";
import { SmallClearButton, MediumClearButton, Div } from "components/Base";
import { withProps } from "../../utils";

// TODO: why is this div display row here
const itemMaker =
  (Button) =>
  ({ LeftIcon, RightIcon, children, onClick, disabled = false, ...props }) => (
    <Div display="row" {...props}>
      <Button
        flex={1}
        onClick={onClick}
        LeftIcon={LeftIcon}
        RightIcon={RightIcon}
        disabled={disabled}
      >
        <Div textAlign="left" flex={1} style={{ lineHeight: "12px" }}>
          {children}
        </Div>
      </Button>
    </Div>
  );

const MenuItem = itemMaker(MediumClearButton);
const SubMenuItem = itemMaker(SmallClearButton);

const MenuCard = withProps({ width: 200, bra: 1, p: 1 })(Div);

class PopMenu extends Component {
  state = {
    showMenu: false,
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  toggleMenu = () => {
    this.setState((p) => ({ showMenu: !p.showMenu }));
  };

  handleClickOutside = (event) => {
    if (this.state.showMenu) {
      if (this.wrapperRef && event && !this.wrapperRef.contains(event.target)) {
        this.closeMenu();
      }
    }
  };

  closeMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
      if (this.props.onCloseMenu) {
        this.props.onCloseMenu();
      }
    }
  };

  componentDidMount() {
    window.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const { Label, children, style = {}, open, ...props } = this.props;

    const showMenu = typeof open !== "undefined" ? open : this.state.showMenu;

    return (
      <div ref={this.setWrapperRef}>
        <Div style={{ position: "relative", ...style }} {...props}>
          {Label && <Label onClick={this.toggleMenu} />}
          {showMenu
            ? typeof children === "function"
              ? children({ closeMenu: this.closeMenu }) || null
              : children
            : null}
        </Div>
      </div>
    );
  }
}

const positions = {
  right: {
    style: {
      left: "100%",
    },
    margin: {
      ml: 1,
    },
  },
  left: {
    style: {
      right: "100%",
    },
    margin: {
      mr: 1,
    },
  },
  bottom: {
    style: {
      top: "100%",
    },
    margin: {
      mt: 1,
    },
  },
  top: {
    style: {
      bottom: "100%",
    },
    margin: {
      mb: 1,
    },
  },
};

const alignments = {
  top: {
    top: 0,
  },
  bottom: {
    bottom: 0,
  },
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
};

const defaultAlignments = {
  right: "top",
  left: "top",
  bottom: "left",
  top: "left",
};

const PopMenuCard = ({
  position,
  align,
  children,
  customStyle = {},
  ...props
}) => {
  const p = positions[position] || positions.bottom;
  const a = alignments[align] || defaultAlignments[position];

  return (
    <Div
      style={{
        ...p.style,
        ...a,
        position: "absolute",
        overflow: "initial",
        padding: 0,
        zIndex: props.zIndex || 10,
        ...customStyle,
      }}
      bra={1}
      p={1}
      bg="white"
      shadow={2}
      {...p.margin}
      {...props}
    >
      {children}
    </Div>
  );
};

export { MenuCard, MenuItem, SubMenuItem, PopMenu, PopMenuCard };
