import { flattenToIndexedKeys } from "../utils";

const namedColors = {
  white: "#ffffff",
  black: "#000000",
  gray: [
    "#f8f8f8",
    "#f3f3f3",
    "#e9e9e9",
    "#dedede",
    "#cecece",
    "#adadad",
    "#868686",
    "#494949",
    "#343434",
    "#212121",
  ],

  red: [
    "#fff5f5",
    "#ffe3e3",
    "#ffc9c9",
    "#ffa8a8",
    "#ff8787",
    "#ff6b6b",
    "#fa5252",
    "#f03e3e",
    "#e03131",
    "#c92a2a",
  ],
  pink: [
    "#fff0f6",
    "#ffdeeb",
    "#fcc2d7",
    "#faa2c1",
    "#f783ac",
    "#f06595",
    "#e64980",
    "#d6336c",
    "#c2255c",
    "#a61e4d",
  ],
  grape: [
    "#f8f0fc",
    "#f3d9fa",
    "#eebefa",
    "#e599f7",
    "#da77f2",
    "#cc5de8",
    "#be4bdb",
    "#ae3ec9",
    "#9c36b5",
    "#862e9c",
  ],
  purple: [
    "#f6f0ff",
    "#eddbff",
    "#dfbfff",
    "#c997fc",
    "#b675fa",
    "#a85ef7",
    "#9f50f2",
    "#9548e8",
    "#823dd1", // '#8a41d9', NOTE: Consult color complaint dept
    "#7e3dc4",
  ],
  indigo: [
    "#edf2ff",
    "#dbe4ff",
    "#bac8ff",
    "#91a7ff",
    "#748ffc",
    "#5c7cfa",
    "#4a65d5",
    "#425bc2", // 7
    "#394fa9",
    "#283878",
  ],
  blue: [
    "#e8f7ff",
    "#ccedff",
    "#a3daff",
    "#72c3fc",
    "#4dadf7",
    "#329af0",
    "#228ae6",
    "#1c7cd6",
    "#1b6ec2",
    "#1862ab",
  ],
  cyan: [
    "#e3fafc",
    "#c5f6fa",
    "#99e9f2",
    "#66d9e8",
    "#3bc9db",
    "#22b8cf",
    "#15aabf",
    "#1098ad",
    "#0c8599",
    "#0b7285",
  ],
  teal: [
    "#e6fcf5",
    "#c3fae8",
    "#96f2d7",
    "#63e6be",
    "#38d9a9",
    "#20c997",
    "#12b886",
    "#0ca678",
    "#099268",
    "#087f5b",
  ],
  green: [
    "#ebfbee",
    "#d3f9d8",
    "#b2f2bb",
    "#8ce99a",
    "#69db7c",
    "#51cf66",
    "#40c057",
    "#37b24d",
    "#2f9e44",
    "#2b8a3e",
  ],
  lime: [
    "#f4fce3",
    "#e9fac8",
    "#d8f5a2",
    "#c0eb75",
    "#a9e34b",
    "#94d82d",
    "#82c91e",
    "#74b816",
    "#66a80f",
    "#5c940d",
  ],
  yellow: [
    "#fff9db",
    "#fff3bf",
    "#ffec99",
    "#ffe066",
    "#ffd43b",
    "#fcc419",
    "#fab005",
    "#f59f00",
    "#f08c00",
    "#e67700",
  ],
  orange: [
    "#fff4e6",
    "#ffe8cc",
    "#ffd8a8",
    "#ffc078",
    "#ffa94d",
    "#ff922b",
    "#fd7e14",
    "#f76707",
    "#e8590c",
    "#d9480f",
  ],
};

const semanticColors = {
  neutral: [
    "#f8f8f8",
    "#f2f1f4",
    "#e7e6ea",
    "#dedede",
    "#cecece",
    "#adadad",
    "#868686",
    "#494949",
    "#343434",
    "#212121",
  ],
  primary: namedColors.indigo,
  altA: namedColors.green,
  altB: namedColors.orange,
  success: namedColors.green,
  danger: namedColors.red,
  warning: namedColors.yellow,
  info: namedColors.blue,
};

const baseColors = {
  ...namedColors,
  ...semanticColors,
};

const indexedColors = flattenToIndexedKeys(baseColors);

export { baseColors, namedColors, semanticColors, indexedColors };
