import React from "react";
import { Div, DarkCloseButton, AvatarLabel } from "../../index";

const AvatarLabelRemove = ({ id, onRemove, frontText, backText, photoURL }) => {
  return (
    <Div bra={1} my={1} p={1} bg={"neutral2"} display={"row.flex-start.center"}>
      <AvatarLabel
        id={id}
        frontText={frontText}
        photoURL={photoURL}
        backText={backText}
        flex={1}
      />
      <DarkCloseButton size={16} onClick={onRemove} />
    </Div>
  );
};

export { AvatarLabelRemove };
