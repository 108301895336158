import * as R from "ramda";
import {
  cancel,
  fork,
  take,
  call,
  put,
  select,
  all,
  cancelled,
  spawn,
} from "redux-saga/effects";
import { push } from "react-router-redux";
import { v4 as uuidv4 } from "uuid";

import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { getEventUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/actions";
import { actions, getters } from "./model";
import { actions as SnackbarActions } from "ui-kit/Snackbar/model";
import { actions as AlertActions } from "ui-kit/Alert/model";
import { actions as ToastsActions } from "ui-kit/Toasts/model";

import { user as getUser } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { getAttend } from "App/Data/sagas";
import { createPusherChannel } from "App/Data/sagas";

import * as eventActions from "redux/modules/event/constants";
import {
  ALERT_EVENT_TYPES,
  VIRTUAL_TOASTS_INSTANCE,
  ACTIONS,
} from "./constants";
import { SEVERITY } from "ui-kit/Snackbar/constants";

const mapSeverity = {
  update: SEVERITY.INFO,
  warning: SEVERITY.WARNING,
  breaking: SEVERITY.ERROR,
};

const watchUpdates = function* () {
  const eventId = yield select(getEventId);
  const alertsChannel = yield call(createPusherChannel, {
    channelId: `event-${eventId}`,
    eventTypes: R.values(ALERT_EVENT_TYPES),
  });

  try {
    while (true) {
      const { alert } = yield take(alertsChannel);

      if (
        alert &&
        !alert.is_draft &&
        alert.id &&
        alert.title &&
        alert.title.length
      ) {
        yield put(
          ToastsActions.show(
            {
              id: alert.id,
              message: alert.title,
              severity: R.prop(alert.alert_type, mapSeverity),
              clickAction: { id: ACTIONS.NAVIGATE_TO_ALERT },
            },
            { meta: { instanceId: VIRTUAL_TOASTS_INSTANCE } },
          ),
        );
      }
    }
  } catch (error) {
    yield put(
      SnackbarActions.show({
        error,
        id: uuidv4(),
        severity: SEVERITY.ERROR,
        message: "There was an error in the alerts channel",
      }),
    );
  } finally {
    if (yield cancelled()) {
      alertsChannel.close();
    }
  }
};

const navigateToAlert = function* ({ payload: { data } }) {
  const eventUUID = yield select(getters.eventUUID);
  const eventSlug = yield select(getters.eventSlug);
  yield put(push(`/attend/${eventSlug}/${eventUUID}/alerts/${data.id}`));
};

const delegates = {
  [ACTIONS.NAVIGATE_TO_ALERT]: navigateToAlert,
};

const watchExecuteAction = function* () {
  for (;;) {
    const action = yield take(
      (ac) =>
        ac.type === AlertActions.executeAction.type &&
        ac.meta.instanceId === VIRTUAL_TOASTS_INSTANCE,
    );

    const delegate = R.prop(R.path(["payload", "actionId"], action), delegates);
    if (delegate) {
      yield spawn(delegate, action);
    }
  }
};

const watchInit = function* () {
  let task = null;
  for (;;) {
    const {
      payload: { eventSlug, eventUUID },
    } = yield take(actions.init.type);
    if (task) {
      yield cancel(task);
    }
    try {
      // get wrapper payload and event details
      const result = yield call(getAttend, { eventSlug, eventUUID });
      const event = R.pathOr({}, ["payload", "event"], result);
      const isPublished = R.path(["payload", "is_published"], result);
      const user = yield select(getUser);

      // if event is not published, redirect
      if (!R.prop("id", user) && !isPublished) {
        yield put(push(`/login?message=This event has not been published yet`));
      } else {
        yield all([
          put({
            type: eventActions.RECEIVE,
            payload: event,
          }),
          put(getUserAccessLevels()),
          put(getEventUserPermissionProfile(event.id, user.id)),
        ]);
      }
      yield fork(watchUpdates);
    } catch (e) {
      yield put(
        push(
          `/register/${eventSlug}/${eventUUID}?error=You must be logged in to attend this event.`,
        ),
      );
    } // eslint-disable-line no-empty
  }
};

const root = function* () {
  yield all([spawn(watchInit), spawn(watchExecuteAction)]);
};

export default root;
