import { SHOW, HIDE } from "./constants";

export function showSnackbar(payload) {
  return {
    type: SHOW,
    payload: {
      ...payload,
      key: Math.random(),
    },
  };
}

export function hideSnackbar() {
  return {
    type: HIDE,
  };
}
