import React from "react";
import { capitalizeFirst } from "./";
import { withState, lifecycle } from "recompose";

const defaultProps = (props) => (Comp) => (compProps) => (
  <Comp {...props} {...compProps} />
);
const propsDiff = lifecycle({
  componentDidUpdate(prevProps) {
    console.log("Row update diff:");

    const now = Object.entries(this.props);
    const added = now.filter(([key, val]) => {
      if (prevProps[key] === undefined) return true;
      if (prevProps[key] !== val) {
        console.log(`${key}
          + ${JSON.stringify(val)}
          - ${JSON.stringify(prevProps[key])}`);
      }
      return false;
    });
    added.forEach(([key, val]) =>
      console.log(`${key}
          + ${JSON.stringify(val)}`),
    );
  },
});
const quickState = (name, defaultValue) =>
  withState(name, `set${capitalizeFirst(name)}`, defaultValue);

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export { propsDiff, defaultProps, quickState };

export {
  withProps,
  mapProps,
  toClass,
  withState,
  withHandlers,
  withStateHandlers,
  withRenderProps,
  lifecycle,
  pure,
  compose,
} from "recompose";
