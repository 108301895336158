import { combineReducers } from "redux";
import { RECEIVE } from "./constants";

const modules = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  modules,
});
