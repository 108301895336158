import { capitalizeFirst } from "utils/General";
import * as R from "ramda";
import { defaultInstanceId } from "./bindInstance";
import { makeReducer } from "./reducer-utils";

export const createHandlers = ({
  iniState = {},
  reducers = {},
  namespace: ns = "",
}) => {
  const setHandlers = Object.keys(iniState).reduce((acc, key) => {
    const setKey = `set${capitalizeFirst(key)}`;
    const type = `${ns ? ns + "/" : ns}${setKey}`;

    const handler = (payload, props = {}, error = false) => ({
      meta: {},
      ...props,
      payload,
      error,
      type,
      namespace: ns,
    });

    handler.type = type;
    handler.key = key;

    return {
      ...acc,
      [setKey]: handler,
    };
  }, {});

  const customHandlers = Object.keys(reducers).reduce((acc, key) => {
    const type = `${ns ? ns + "/" : ns}${key}`;

    const handler = (payload, props = {}, error = false) => ({
      meta: {},
      ...props,
      payload,
      error,
      type,
      namespace: ns,
    });

    handler.type = type;
    handler.key = key;

    return {
      ...acc,
      [key]: handler,
    };
  }, {});

  const setReducers = Object.values(setHandlers).reduce(
    (acc, action) => ({
      ...acc,
      [action.type]: (state, { payload }) => ({ [action.key]: payload }),
    }),
    {},
  );

  const customReducers = Object.values(customHandlers).reduce(
    (acc, action) => ({
      ...acc,
      [action.type]: reducers[action.key],
    }),
    {},
  );

  const actions = R.merge(setHandlers, customHandlers); // TODO rename, actions are not handlers
  const getters = Object.keys(iniState).reduce(
    (acc, key) => ({
      ...acc,
      [key]: (state, props = {}) => {
        const instanceId = R.prop("instanceId", props) || defaultInstanceId;
        const path = [ns, instanceId, key];
        return R.pathOr(R.prop(key, iniState), path, state);
      },
    }),
    {
      instance: (state, props) => {
        const instanceId = R.prop("instanceId", props) || defaultInstanceId;
        const path = [ns, instanceId];
        return R.pathOr(iniState, path, state);
      },
      identity: (state) => {
        return R.propOr({}, ns, state);
      },
    },
  );
  const handlers = R.merge(setReducers, customReducers);
  const namespace = ns;

  const handlersIniState = { [ns]: { [defaultInstanceId]: iniState } };
  const reducer = makeReducer(handlers, handlersIniState, namespace);

  return {
    handlers,
    actions,
    getters,
    reducer,
    iniState: handlersIniState,
    namespace,
  };
};
