import React from "react";
import { Div, Text1, Text2 } from "../../index";

const Type5Card = ({ icon, name, subname }) => (
  <Div
    bra={1}
    my={2}
    p={2}
    pl={3}
    bg="neutral1"
    display="row.flex-start.center"
  >
    {icon}

    <Div display="column" flex={1}>
      <Text2 bold pl={2}>
        {name}
      </Text2>
      {subname ? <Text1 pl={2}>{subname}</Text1> : null}
    </Div>
  </Div>
);

export { Type5Card };
