import { select, call, put } from "redux-saga/effects";

import { actions } from "../model";

import { getEventTemplateByUUID } from "../selectors";

import { apiCall } from "./utils";
import { v4 as uuidv4 } from "uuid";

export const fetchEventTemplateByUUID = function* ({
  eventUUID,
  transactionId = uuidv4(),
  updateData = false,
}) {
  const oldTemplate = yield select(getEventTemplateByUUID, { eventUUID });
  if (!updateData && oldTemplate) {
    return oldTemplate;
  }

  try {
    const result = yield call(apiCall, {
      method: "get",
      url: `/attend/event/${eventUUID}/template`,
      public: true,
    });

    yield put(
      actions.addEventTemplate(
        { data: result.payload, eventUUID },
        { meta: { transactionId } },
      ),
    );
    return result.payload;
  } catch (error) {
    yield put(actions.error(error, { meta: { transactionId } }));
    throw error;
  }
};
