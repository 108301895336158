import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { darken, setShade, lighten } from "../colorUtil";
import { crossSpread, noop } from "../utils";
import { Div, Button } from "../Base/index";
import { TextInputController } from "../Controllers/TextFields";

const sizeNames = ["Tiny", "Small", "Medium", "Big", "Super"];

const scale = [1, 1.5, 2, 2.5, 3];

const sizes = crossSpread(sizeNames, {
  fs: [0, 1, 2, 3, 4],
  fw: [3, 3, 3, 3, 3],
  h: R.map(R.multiply(4), scale),
  ba: [1, 2, 2, 2, 2],
  bra: [1, 1, 1, 1, 1],
  iconSize: [10, 14, 18, 22, 26],
  insideIconSpacing: R.map(R.multiply(1 / 2), scale),
  outsideIconSpacing: R.map(R.multiply(1 / 2), scale),
  textSpacing: R.map(R.multiply(1), scale),
  extraSpacing: R.map(R.multiply(1 / 2), scale),
});
// Spacing Diagram:
// |extraPadding|outsideIconSpacing|<icon>|insideIconSpacing|<text>|textSpacing|extraPadding|
// (extraPadding is toggled by 'isPadded')

// TODO: this state picking might be slow
const styles = {
  Filled:
    (statePicker) =>
    ({ bg = "neutral5", color = "white", hover = true }) => ({
      color,
      bg: statePicker({ default: bg, hover: hover ? darken(1, bg) : bg }),
      bc: statePicker({ default: bg, hover: hover ? darken(1, bg) : bg }),
    }),
  Outline:
    (statePicker) =>
    ({ color = "neutral7", bg }) => ({
      color,
      bg: R.isNil(bg)
        ? statePicker({ default: "transparent", hover: setShade(1, color) })
        : statePicker({ default: bg, hover: darken(1, bg) }),
      bc: setShade(2, color),
    }),
  Clear:
    (statePicker) =>
    ({ color = "neutral7", bg }) => ({
      color,
      bg: statePicker({
        default: "transparent",
        hover: setShade(1, bg || color),
      }),
      bc: statePicker({
        default: "transparent",
        hover: setShade(1, bg || color),
      }),
    }),
  Text:
    (statePicker) =>
    ({ color = "neutral6" }) => ({
      color: statePicker({ default: color, hover: lighten(1, color) }),
      bg: "transparent",
      bc: "transparent",
    }),
  Shaded:
    (statePicker) =>
    ({ color = "neutral6", bg }) => {
      return {
        color,
        bg: statePicker({
          default: setShade(1, bg || color),
          hover: setShade(2, bg || color),
        }),
        bc: statePicker({
          default: setShade(1, bg || color),
          hover: setShade(2, bg || color),
        }),
      };
    },
  Inset:
    () =>
    ({ color = "neutral6" }) => ({
      color,
      bg: "white",
      bc: "neutral2",
      shadow: "inset",
    }),
};

const functionalities = {
  Box: {
    func: ({
      containerProps,
      OnlyIcon,
      onlyIconProps,
      LeftIcon,
      leftIconProps,
      children,
      RightIcon,
      rightIconProps,
    }) => (
      <Div display="inline-row.center.center" {...containerProps}>
        {OnlyIcon && <OnlyIcon {...onlyIconProps} />}
        {LeftIcon && <LeftIcon {...leftIconProps} />}
        {children}
        {RightIcon && <RightIcon {...rightIconProps} />}
      </Div>
    ),
    isPadded: false,
  },
  Button: {
    func: ({
      containerProps,
      OnlyIcon,
      onlyIconProps,
      LeftIcon,
      leftIconProps,
      RightIcon,
      rightIconProps,
      children,
      // passed
      onClick,
      type,
    }) => (
      <Button
        onClick={containerProps.disabled ? undefined : onClick}
        display="inline-row.center.center"
        type={type}
        {...containerProps}
      >
        {OnlyIcon && <OnlyIcon {...onlyIconProps} />}
        {LeftIcon && <LeftIcon {...leftIconProps} />}
        {children}
        {RightIcon && <RightIcon {...rightIconProps} />}
      </Button>
    ),
    propTypes: {
      onClick: PropTypes.func.isRequired,
      type: PropTypes.string,
    },
    defaultProps: {
      onClick: noop,
    },
    enabledStates: ["hover"],
    isPadded: true,
  },

  Input: {
    func: ({
      containerProps,
      OnlyIcon,
      onlyIconProps,
      LeftIcon,
      leftIconProps,
      RightIcon,
      rightIconProps,
      // eslint-disable-next-line no-unused-vars
      children,
      autoFocus = false,
      autocomplete,
      // passed
      name,
      type,
      required,
      disabled,
      min,
      max,
      maxLength,
      continuous,
      value,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      inputRef,
      inputStyle,
    }) => (
      <Div display="inline-row.flex-start.center" {...containerProps} ba={1}>
        {OnlyIcon && <OnlyIcon {...onlyIconProps} />}
        {LeftIcon && <LeftIcon {...leftIconProps} />}
        <Div flex={1} color="inherit">
          <TextInputController
            name={name}
            type={type}
            required={required}
            disabled={disabled || containerProps.disabled}
            min={min}
            max={max}
            maxLength={maxLength}
            bg="transparent"
            ba={0}
            color={"inherit"}
            style={{
              fontSize: "inherit",
              fontWeight: "inherit",
              height: "100%",
              width: "100%",
              flex: 1,
              ...inputStyle,
            }}
            continuous={continuous}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            inputRef={inputRef}
            autoFocus={autoFocus}
            autoComplete={autocomplete}
          />
        </Div>
        {RightIcon && <RightIcon {...rightIconProps} />}
      </Div>
    ),
    propTypes: {
      name: PropTypes.string,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
      maxLength: PropTypes.number,
      continuous: PropTypes.bool,
      required: PropTypes.bool,
      value: PropTypes.string,
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      inputRef: PropTypes.element,
      inputStyle: PropTypes.object,
      autocomplete: PropTypes.string,
      autoFocus: PropTypes.bool,
    },
    // @TODO
    defaultProps: {
      type: "text",
      autoFocus: false,
    },
    enabledStates: ["focus"],
    isPadded: false,
  },
};

export { sizes, styles, functionalities };
