import React from "react";
import * as R from "ramda";
import { Div, addSelectableGroupProps, enhanceSelectableItems } from "../index";

const NullComp = () => null;

////////////////////////////////////////////////////////////////////////////////

const SelectableItemsList =
  ({ ItemComp = NullComp, HeaderComp = NullComp, NoneFoundComp = NullComp }) =>
  ({ onSelect, onRemove, title, items = [], selection = [] }) => {
    const { onSelectAll, onRemoveAll, allAreSelected, someAreSelected } =
      addSelectableGroupProps({
        selection,
        items,
        onRemove,
        onSelect,
      });

    return (
      <Div>
        <HeaderComp
          title={title}
          numItems={R.length(items)}
          onSelectAll={onSelectAll}
          onRemoveAll={onRemoveAll}
          allAreSelected={allAreSelected}
          someAreSelected={someAreSelected}
        />
        {R.isEmpty(items) ? (
          <NoneFoundComp />
        ) : (
          <Div>
            {R.map(
              (item) => (
                <ItemComp key={item.id} {...item} />
              ),
              enhanceSelectableItems({
                selection,
                items,
                onRemove,
                onSelect,
              }),
            )}
          </Div>
        )}
      </Div>
    );
  };

const SelectableGroupsList = ({
  GroupsHeaderComp = NullComp,
  GroupHeaderComp = NullComp,
  ItemComp = NullComp,
  GroupsNoneFoundComp = NullComp,
  GroupNoneFoundComp = NullComp,
}) => {
  const AppliedItemsList = SelectableItemsList({
    ItemComp,
    HeaderComp: GroupHeaderComp,
    NoneFoundComp: GroupNoneFoundComp,
  });
  return ({
    groupsInfo = {}, // {[groupId]:  {name: string}, ...}
    selection = [], // arrayOfItemIds
    items = [], // [{id: 'string', groupId: 'string'}, ...]
    onSelect, // (arrayOfItemIds) => {}
    onRemove, // (arrayOfItemIds) => {}
  }) => {
    const groupedItems = R.compose(
      R.map(([groupId, items]) => {
        return {
          groupId,
          name: R.pathOr("Other", [groupId, "name"], groupsInfo),
          items: items,
        };
      }),
      R.toPairs,
      R.groupBy(R.propOr("Other", "groupId")),
    )(items);

    const { onSelectAll, onRemoveAll, allAreSelected, someAreSelected } =
      addSelectableGroupProps({
        selection,
        items,
        onRemove,
        onSelect,
      });

    return (
      <Div>
        <GroupsHeaderComp
          numItems={R.length(items)}
          onSelectAll={onSelectAll}
          onRemoveAll={onRemoveAll}
          allAreSelected={allAreSelected}
          someAreSelected={someAreSelected}
        />
        {R.isEmpty(items) ? (
          <GroupsNoneFoundComp />
        ) : (
          <Div>
            {R.map(({ groupId, name, items }) => {
              return (
                <AppliedItemsList
                  selection={selection}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  key={groupId}
                  title={name}
                  items={items}
                  ItemComp={ItemComp}
                />
              );
            }, groupedItems)}
          </Div>
        )}
      </Div>
    );
  };
};

export { SelectableItemsList, SelectableGroupsList };
