import { all, race, fork, take, select, delay, put } from "redux-saga/effects";

import { actions } from "./model";
import { actions as AlertActions } from "ui-kit/Alert/model";

import { getActiveSnackbar } from "./selectors";

const watchShowSnackbar = function* () {
  for (;;) {
    yield take(actions.show.type);

    for (;;) {
      const snackbar = yield select(getActiveSnackbar);
      if (!snackbar) {
        break;
      }
      if (process.env.NODE_ENV !== "produciton" && snackbar.error) {
        //eslint-disable-next-line no-console
        console.error(snackbar.error);
      }
      if (snackbar.autoHideDuration === false) {
        yield take(actions.hide.type);
      } else {
        const { autoHide } = yield race({
          autoHide: delay(snackbar.autoHideDuration || 3000),
          close: take(actions.hide.type),
        });
        if (autoHide) {
          yield put(actions.hide());
        }
      }
      yield delay(400);
      yield put(actions.next());
    }
  }
};

const watchExecuteAction = function* () {
  for (;;) {
    yield take(AlertActions.executeAction.type);
    yield put(actions.hide());
  }
};

const rootSaga = function* () {
  yield all([fork(watchShowSnackbar), fork(watchExecuteAction)]);
};

export default rootSaga;
