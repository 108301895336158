import { createModule } from "redux-mvc";

import model from "./model";

import rootSaga from "./sagas";

const module = createModule(model);

module.setRootSaga(rootSaga);

export default module;
