import React from "react";
import { SVG } from "../Base/index";
import { getColor } from "../colorUtil";

/**
 * NOTE: for special svgs, define a array [additionalSVGProps, svgFunc]
 * if you are receiving colors, make sure to wrap in getColor
 * so that any base colors are converted
 */

const specialSVGs = {
  threeCircles: [
    ["color1", "color2", "color3"],
    ({ otherTags = null, color1, color2, color3, ...svgProps }) => {
      return (
        <SVG {...svgProps} viewBox="0 0 48 48">
          {otherTags}
          <g transform="matrix(1.125,0,0,1.125,-1.5,-6.5)">
            <circle cx="12" cy="36" r="8" fill={getColor(color1)} />
          </g>
          <g transform="matrix(1.125,0,0,1.125,10.5,-26.5)">
            <circle cx="12" cy="36" r="8" fill={getColor(color2)} />
          </g>
          <g transform="matrix(1.125,0,0,1.125,22.5,-6.5)">
            <circle cx="12" cy="36" r="8" fill={getColor(color3)} />
          </g>
        </SVG>
      );
    },
  ],
  donut: [
    ["value", "defaultColor", "valueColor", "strokeRatio"],
    ({
      otherTags = null,
      value = 0,
      defaultColor = "#ddd",
      valueColor = "#333",
      strokeRatio = 1 / 4,
      ...svgProps
    }) => {
      const iconPercentage = 7 / 8;
      const iconWidth = iconPercentage * 48; // 48 = viewBox size
      const center = 48 / 2;
      const strokeWidth = strokeRatio * iconWidth;
      const R = iconWidth / 2 - strokeWidth / 2;
      const C = 2 * Math.PI * R;
      return (
        <SVG {...svgProps} viewBox="0 0 48 48">
          {otherTags}
          <circle
            cx={center}
            cy={center}
            r={R}
            fill="none"
            stroke={getColor(defaultColor)}
            strokeWidth={strokeWidth}
          />
          <circle
            cx={center}
            cy={center}
            r={R}
            fill="none"
            stroke={getColor(valueColor)}
            strokeWidth={strokeWidth}
            strokeDasharray={C}
            strokeDashoffset={C - value * C}
            transform={`rotate(-90 ${center} ${center})`}
          />
        </SVG>
      );
    },
  ],
  addBlocks: [
    ["color"],
    ({ otherTags = null, color, ...svgProps }) => {
      return (
        <SVG {...svgProps} viewBox="0 0 24 24">
          {otherTags}
          <path fill="none" d="M.002 0h27v26.552h-27z" />
          <path fill="none" d="M13.117 13.276h11.571v11.379h-11.57z" />
          <path
            fill={getColor(color)}
            d="M22.374 14.698h-6.75a.956.956 0 0 0-.964.948v6.638c0 .524.432.949.964.949h6.75a.956.956 0 0 0 .965-.949v-6.637a.956.956 0 0 0-.965-.949zm-.964 4.742h-1.928v1.896h-.965V19.44H16.59v-.949h1.928v-1.896h.965v1.896h1.928v.949z"
          />
          <path fill="none" d="M.002 0h27v26.552h-27z" />
          <path
            d="M3.377 14.382h9V3.32h-9v11.063zm0 8.85h9v-6.637h-9v6.638zM14.627 3.32l.03 9.198h9l-.03-9.198h-9z"
            fill={getColor(color)}
          />
        </SVG>
      );
    },
  ],
};

export { specialSVGs };
