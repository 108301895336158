import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "InvoiceGate",
  iniState: {
    eventId: "",
    attendeeId: "",
    canAttendeeAccessEvent: false,
    loading: false,
  },
  reducers: {
    init: (_, { payload: { eventId } }) => ({
      eventId,
    }),
  },
});

const { actions, getters } = model;

export { actions, getters };

export default model;
