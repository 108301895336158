import React from "react";
import {
  MoreIcon,
  Text1,
  Text3,
  PopoverMenu,
  TinyOutlineButton,
  MediumOutlineButton,
  Div,
  FontIcon,
  collapsableHandler,
  Collapsable,
} from "components/Base";

const CollapsablePanelType1 = collapsableHandler(
  ({
    collapsed,
    toggleCollapsed,
    menuItems,
    subText,
    headingText,
    children,
    ...styles
  }) => (
    <Div {...styles}>
      <Div display="row.flex-start.center">
        <Div display="row.flex-start.center" onClick={toggleCollapsed}>
          <FontIcon color="neutral8">
            {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
          </FontIcon>
          <Text3 bold pl={2}>
            {headingText}
          </Text3>
        </Div>
        <Div flex={1} display="row.flex-start.center">
          {menuItems && (
            <PopoverMenu
              Label={({ onClick }) => (
                <TinyOutlineButton onClick={onClick} ml={3}>
                  <MoreIcon size={16} />
                </TinyOutlineButton>
              )}
              menuItems={menuItems}
            />
          )}
          <Div flex={1} />
          {subText && <Text1 pr={4}>{subText}</Text1>}
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>{children}</Collapsable>
    </Div>
  ),
);

const CollapsablePanelType2 = collapsableHandler(
  ({ collapsed, toggleCollapsed, headingText, headingExtras, children }) => (
    <Div>
      <Div
        p={3}
        display="row.flex-start.center"
        bg="neutral1"
        bb={1}
        bc="neutral3"
      >
        <Div display="row.flex-start.center" flex={1} onClick={toggleCollapsed}>
          <FontIcon fs={3} bra={1} ba={1} bc="neutral3" color="neutral8" mr={2}>
            {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
          </FontIcon>
          <Text3 bold>{headingText}</Text3>
        </Div>
        {headingExtras}
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div p={3} bg="neutral0">
          <Div p={3}>{children}</Div>
        </Div>
      </Collapsable>
    </Div>
  ),
);

const CollapsablePanelType3 = collapsableHandler(
  ({
    collapsed,
    toggleCollapsed,
    menuItems,
    subText,
    headingText,
    children,
    borderColor,
    actionButtonText,
    onActionButtonClick,
    backgroundColor = "white",
  }) => (
    <Div
      bg={backgroundColor}
      bl={collapsed ? 2 : 0}
      bt={collapsed ? 0 : 2}
      bc={borderColor}
    >
      <Div display="row.flex-start.center">
        <Div
          display="row.flex-start.center"
          flex={1}
          onClick={toggleCollapsed}
          px={3}
          py={4}
        >
          <FontIcon color="neutral8">
            {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
          </FontIcon>
          <Div fw={3} fs={5} pl={2} color="gray9">
            {headingText}
          </Div>
        </Div>
        <Div display="row.flex-start.center" px={3}>
          {menuItems && menuItems.length ? (
            <PopoverMenu
              Label={({ onClick }) => (
                <MediumOutlineButton onClick={onClick}>
                  <MoreIcon size={16} />
                </MediumOutlineButton>
              )}
              menuItems={menuItems}
            />
          ) : null}
          {actionButtonText ? (
            <MediumOutlineButton
              onClick={onActionButtonClick}
              py={1}
              px={4}
              ml={3}
            >
              {actionButtonText}
            </MediumOutlineButton>
          ) : null}
          <Div flex={1} />
          {subText && <Text1 pr={4}>{subText}</Text1>}
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>{children}</Collapsable>
    </Div>
  ),
);

const CollapsablePanelType4 = collapsableHandler(
  ({ collapsed, toggleCollapsed, headingText, children, ...props }) => (
    <Div {...props}>
      <Div pb={4} display="row.flex-start.center" onClick={toggleCollapsed}>
        <Div display="row.flex-start.center" pr={2}>
          <FontIcon fs={3} color="gray6">
            {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
          </FontIcon>
          <Text1 bold color="gray6">
            {headingText}
          </Text1>
        </Div>
        <Div h={1 / 4} bg="gray5" flex={1} />
      </Div>
      <Collapsable collapsed={collapsed}>{children}</Collapsable>
    </Div>
  ),
);

export {
  CollapsablePanelType1,
  CollapsablePanelType2,
  CollapsablePanelType3,
  CollapsablePanelType4,
};
