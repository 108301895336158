import { combineReducers } from "redux";
import { RECEIVE, REQUEST, INVALIDATE, DELETE_SUBMISSION } from "./constants";

const forms = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.forms.forms;
    case INVALIDATE:
      return [];
    case DELETE_SUBMISSION:
      return state.map((form) => {
        if (form.id === action.payload.formId) {
          return {
            ...form,
            submissions: form.submissions.filter(
              (submission) => submission.id !== action.payload.submissionId,
            ),
          };
        }
        return form;
      });
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  forms,
  fetching,
});
