import { Component, cloneElement } from "react";

/**
 * Why is this needed?
 * - Currently ReactTable doesn't update the page number based on total pages
 *   - Example: If you're on page 2, with a page size of 5 and you have 6 records.
 *     deleting one results in 1 page, but RT doesn't update the current page to 1 (stays 2)
 * - Currently ReactTable caches the pagesize, so when calculating the number of pages,
 *   it uses the previous `pageSize` for the calculation (https://github.com/tannerlinsley/react-table/blob/6d28bb2c4d96a94ed8684bee14600fc7197c5f6b/src/lifecycle.js#L95)
 *
 * @example
 *  ```
 *   <PaginationManager pages={COUNT_OF_PAGES}>
 *     <ReactTable ... />
 *   </PaginationManager>
 *  ```
 */

const makeDefaultState = ({ pageSize }) => ({
  page: 0,
  pageSize,
});

class PaginationManager extends Component {
  constructor(props) {
    super(props);
    this.state = makeDefaultState(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.setState({ pageSize: this.props.pageSize });
    }
  }

  render() {
    return cloneElement(this.props.children, {
      defaultPageSize: this.props.pageSize,
      // Controlled props
      page: this.state.page,
      pageSize: this.state.pageSize,
      pages: this.props.countOfRows
        ? Math.max(1, Math.ceil(this.props.countOfRows / this.state.pageSize))
        : undefined,

      // Callbacks
      onPageChange: (page) => this.setState({ page }),
      onPageSizeChange: (pageSize, page) => this.setState({ page, pageSize }),
      onExpandedChange: (expanded) => this.setState({ expanded }),
      // onResizedChange: resized => this.setState({ resized }),
      onFilteredChange: (filtered) => this.setState({ filtered }),
    });
  }
}

PaginationManager.defaultProps = {
  page: 0,
  pageSize: 50,
};

export default PaginationManager;
