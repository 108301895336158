import PropTypes from "prop-types";
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "styles/less/style_v2.less";

import LenndMuiTheme from "components/Global/Themes/LenndMuiTheme";
import PlatformLoading from "components/Platform/PlatformLoading";

class View extends Component {
  componentDidMount() {
    const authMode = window.__AUTH_MODE__;
    if (authMode === "classy-sso") {
      this.props.checkSsoSession();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // @NOTE: Since we're an SPA, we need to let AppCues know when
    // a page change has occurred
    /*
    if (window.Appcues) {
      window.Appcues.start();
    }
    */
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createTheme(adaptV4Theme(LenndMuiTheme))}>
            {this.props.isSessionCheckComplete ? (
              this.props.children
            ) : (
              <PlatformLoading />
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </DndProvider>
    );
  }
}

View.propTypes = {
  children: PropTypes.element,
};

export default View;
