import { cancel, take, call, put, all, spawn } from "redux-saga/effects";
import { actions } from "./model";
import { apiCall } from "App/Data/sagas";
import { registerError } from "redux/modules/errors/actions";

const watchInit = function* () {
  yield put(actions.setLoading(true));

  let task = null;
  for (;;) {
    const {
      payload: { eventId, attendeeId, attendeeUUID },
    } = yield take(actions.init.type);
    if (task) {
      yield cancel(task);
    }

    try {
      const { payload } = yield call(apiCall, {
        method: "get",
        url: `/user/attendee-event-access-check/event/${eventId}/attendee/${attendeeId}?attendeeUUID=${attendeeUUID}`,
      });
      yield put(actions.setCanAttendeeAccessEvent(payload));
      yield put(actions.setLoading(false));
      return payload;
    } catch (err) {
      yield put(
        registerError([
          {
            system: err,
            user: "Error occurred checking for attendee access",
          },
        ]),
      );
      yield put(actions.setLoading(false));
    }
  }
};

const root = function* () {
  yield all([spawn(watchInit), spawn]);
};

export default root;
