import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { withStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Div } from "../Base/index";

const StyledMenuItem = withStyles(() => ({
  root: {
    minHeight: 48,
    fontFamily: "Mulish",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16,
    padding: "0 16px",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
}))(MenuItem);

const PopoverBase = ({
  uniqueKey,
  children,
  Label, // must be class
  labelProps,
  wrapperProps,
  innerSpanStyle,
  anchorOrigin,
  targetOrigin, // @NOTE: This property needs to be deprecated
  transformOrigin,
  transitionDuration = 0.1,
  disablePortal = false,
  useContainerRef = true,
  PaperProps,
  onClose,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverContainer = useRef(null);
  const popoverAnchorEl = useContainerRef ? popoverContainer.current : anchorEl;
  /*
    There is a use case when we use this component from an element that is not visible yet,
    and this generates the popoverContainer never gets a valid HTML ref (always null).
    A workaround for this case is to use the anchorEl that is set when the user tries to open this popover.
    But, normally we need to use the useRef hook to not loose the reference of the anchorEl on re-renders.
  */

  const closePopover = () => {
    setPopoverOpen(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const openPopover = (event) => {
    event.stopPropagation();
    if (!useContainerRef) {
      setAnchorEl(event.target);
    }
    setPopoverOpen(true);
  };

  return (
    <Div display="row.center.center" {...wrapperProps}>
      <span style={innerSpanStyle} ref={popoverContainer}>
        <Label onClick={openPopover} onClose={closePopover} {...labelProps} />
      </span>

      <Popover
        key={uniqueKey}
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        disablePortal={disablePortal}
        anchorOrigin={anchorOrigin}
        transformOrigin={targetOrigin || transformOrigin}
        transitionDuration={transitionDuration}
        onClose={closePopover}
        PaperProps={PaperProps}
      >
        {children({
          open: popoverOpen,
          closePopover,
        })}
      </Popover>
    </Div>
  );
};

const PopoverMenu = ({
  Label,
  anchorOrigin,
  labelProps,
  maxHeight,
  menuItems = [],
  targetOrigin,
  transformOrigin,
  wrapperProps,
  disablePortal = false,
  useContainerRef = true,
  onClose,
}) => (
  <PopoverBase
    Label={Label}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin || targetOrigin}
    labelProps={labelProps}
    wrapperProps={wrapperProps}
    disablePortal={disablePortal}
    useContainerRef={useContainerRef}
    onClose={onClose}
  >
    {({ closePopover }) => (
      <MenuList maxHeight={maxHeight}>
        {menuItems.map(([label, func, children, dataId, dataTestId], i) => {
          return (
            <StyledMenuItem
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                func();
                closePopover();
              }}
              {...(dataId ? { ["data-id"]: dataId } : {})}
              {...(dataTestId ? { ["data-testid"]: dataTestId } : {})}
            >
              {children || label}
            </StyledMenuItem>
          );
        })}
      </MenuList>
    )}
  </PopoverBase>
);

PopoverMenu.propTypes = {
  Label: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  labelProps: PropTypes.object,
  maxHeight: PropTypes.number,
  menuItems: PropTypes.arrayOf(PropTypes.array).isRequired,
};

PopoverMenu.defaultProps = {
  labelProps: {},
  maxHeight: undefined,
};

export { PopoverMenu, PopoverBase as Popover };
