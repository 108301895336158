import React from "react";
import moment from "moment";
import * as R from "ramda";
import { Div, FullSizeDotIcon, Text3, Text5, Text6 } from "../index";
import { orderEventsByYearMonthDay } from "../utils";

const TimeSpacer = () => (
  <Div display="row.center.center" h={2}>
    <FullSizeDotIcon color="primary7" size={8} />
    <Div flex={1} bg="primary7" h={1 / 4} />
  </Div>
);

const EventList =
  (EventCard) =>
  ({ events = [], ...props }) => {
    const eventsByYear = orderEventsByYearMonthDay(events);
    let lastWasTimeSpacer = false;
    return (
      <Div {...props}>
        {R.addIndex(R.map)(
          ({ key: yearName, value: eventsByMonth }, i) => (
            <Div>
              {i !== 0 && <Text6 pt={3}>{yearName}</Text6>}
              <Div>
                {R.map(
                  ({ key: monthName, value: eventsByDay }) => (
                    <Div>
                      <Text5 bold py={2}>
                        {moment(`${yearName}-${monthName}`).format("MMMM")}
                      </Text5>
                      <Div>
                        {R.map(
                          ({ key: dateName, value: eventsPerDay }) => (
                            <Div pb={2} display="row.flex-start.stretch">
                              <Div width={36}>
                                <Text6 noLineHeight bold>
                                  {dateName}
                                </Text6>
                                <Text3 bold>
                                  {moment(
                                    `${yearName}-${monthName}-${dateName}`,
                                  ).format("ddd")}
                                </Text3>
                              </Div>
                              <Div mb={2} flex={1}>
                                {R.addIndex(R.map)((event, j) => {
                                  if (event.isTimeSpacer) {
                                    lastWasTimeSpacer = true;
                                    return <TimeSpacer key={j} />;
                                  }
                                  const tempLastWasTimeSpacer =
                                    lastWasTimeSpacer;
                                  lastWasTimeSpacer = false;
                                  return (
                                    <EventCard
                                      key={j}
                                      {...event}
                                      noTopMargin={
                                        j === 0 || tempLastWasTimeSpacer
                                      }
                                    />
                                  );
                                }, eventsPerDay || [])}
                              </Div>
                            </Div>
                          ),
                          eventsByDay || [],
                        )}
                      </Div>
                    </Div>
                  ),
                  eventsByMonth || [],
                )}
              </Div>
            </Div>
          ),
          eventsByYear || [],
        )}
      </Div>
    );
  };

export { EventList };
