import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import snackbarModule from "ui-kit/Snackbar";

const module = createModule(model);

module.setRootSaga(sagas);
module.plugModule(snackbarModule);

export default module;
