import React from "react";
import * as R from "ramda";
import { Div, UpIcon, DownIcon, CloseIcon, NoneIcon } from "../index";
import { defaultProps, mapProps, withProps } from "../utils";

const tabMapper = mapProps(
  ({ barColor, activeColor, color, active, ...props }) => ({
    h: 8,
    mr: 6,
    bb: 2,
    display: "inline-row.center.center",
    transition: "fast",
    fs: 2,
    fw: 3,
    bc: active ? barColor : "transparent",
    color: active ? activeColor : { default: color, hover: activeColor },
    bg: "transparent",
    ...props,
  }),
);

const LightTab = R.compose(
  defaultProps({
    barColor: "altA5",
    activeColor: "white",
    color: "primary2",
  }),
  tabMapper,
)(Div);

const DarkTab = R.compose(
  defaultProps({
    barColor: "primary8",
    activeColor: "primary8",
    color: "gray7",
  }),
  tabMapper,
)(Div);

const DirectionButton = ({ Icon, onClick, size, sizeWFS, color }) => {
  const AppliedIcon = onClick ? Icon : NoneIcon;
  return (
    <AppliedIcon
      transistion="fast"
      color={color}
      size={size}
      sizeWFS={sizeWFS}
      onClick={onClick}
    />
  );
};

const UpDownButtons = ({ goUp, goDown, size, sizeWFS, color, ...props }) => (
  <Div display="row.flex-start.center" {...props}>
    <DirectionButton
      Icon={UpIcon}
      onClick={goUp}
      size={size}
      sizeWFS={sizeWFS}
      color={color}
    />
    <DirectionButton
      Icon={DownIcon}
      onClick={goDown}
      size={size}
      sizeWFS={sizeWFS}
      color={color}
    />
  </Div>
);

const CloseButton = ({ color, onClick, ...props }) => (
  <CloseIcon onClick={onClick} color={color} {...props} />
);

const LightCloseButton = withProps({
  color: { default: "white", hover: "red7" },
})(CloseButton);
const DarkCloseButton = withProps({
  color: { default: "neutral7", hover: "red7" },
})(CloseButton);

export { DarkTab, LightTab, UpDownButtons, LightCloseButton, DarkCloseButton };
