export default module.exports = {
  ID: "fa87516b-d6ca-40fc-9b17-e93b128f587b",
  TYPE: "da36516b-d6ca-40fc-9b17-e93b128f587b",
  APPROVAL: "d395238b-a2bf-42d4-941c-96a3c2aa1a28",
  OWNERS: "190802ad-c3fd-4cd5-ae59-4b592b4aa024",
  SOURCE: "cb266790-66cc-473f-aa01-85c6c480aa33",
  CREATED_BY: "470e976a-850e-4ec9-ba2a-38fc49c06cf9",
  LAST_MODIFIED_BY: "5dd6edf1-1c2d-4bc3-b6eb-e821e421e02d",
  CREATED_AT: "69cb66d8-120c-48e3-8231-4bf139e0eaa5",
  LAST_MODIFIED_AT: "12fcd5ab-4508-41c9-94ab-06372ade518c",
  LAST_SENT_AT: "19db44e8-120c-48e3-8231-4bf139e0eaa5",
  EVENT_LIST: "event_list",
};
