const { groupBy, uniqBy } = require("lodash");
const R = require("ramda");

const getCurrentApprovers = (sequences) => {
  const countOfSequences = sequences.length;

  for (var i = 0; i < countOfSequences; i++) {
    if (sequences[i].status === "pending") {
      return sequences[i].approvers.filter((a) => a.review === null);
    }
  }

  return [];
};

const getAllApprovers = (sequences) => {
  return uniqBy(
    sequences.reduce((list, sequence) => {
      sequence.approvers.forEach((a) => {
        list.push(a);
      });
      return list;
    }, []),
    "user_id",
  );
};

const formatApprovalManifestForRecord = (record) => {
  if (!record.approval_manifest || !record.approval_manifest.id) {
    return {
      id: null,
      sequences: [],
      current_approvers: [],
      all_approvers: [],
      status: "approved",
    };
  }

  const approversBySequence = groupBy(
    record.approval_manifest.approvers,
    "sequence",
  );

  const sequences = Object.keys(approversBySequence)
    .reduce((list, sequence) => {
      const approvers = approversBySequence[sequence];
      const is_any = approvers[0].is_any;
      let status = "pending";

      if (is_any) {
        if (R.any(R.equals("approve"), R.map(R.prop("review"), approvers))) {
          status = "approved";
        }
      } else {
        if (R.all(R.equals("approve"), R.map(R.prop("review"), approvers))) {
          status = "approved";
        }
      }

      // override if rejected, NOTE: change R.any to R.all to check if all reject
      if (R.any(R.equals("reject"), R.map(R.prop("review"), approvers))) {
        status = "rejected";
      }

      const formattedSequence = {
        sequence,
        approvers,
        is_any,
        status,
      };

      list.push(formattedSequence);

      return list;
    }, [])
    .sort((a) => parseInt(a.sequence));

  const approvalStatus = R.reduce(
    (a, c) => {
      if (a === "rejected" || c.status === "rejected") {
        return "rejected";
      } else if (a === "pending" || c.status === "pending") {
        return "pending";
      } else {
        return "approved";
      }
    },
    undefined,
    sequences,
  );

  return {
    id: record.approval_manifest.id,
    sequences,
    current_approvers: getCurrentApprovers(sequences),
    all_approvers: getAllApprovers(sequences),
    status: approvalStatus,
  };
};

export default formatApprovalManifestForRecord;
