import React from "react";
import * as R from "ramda";
import {
  Div,
  MediumOutlineButton,
  Text4,
  tabStateHandler,
  DarkTab,
} from "../../index";

const Tabs = ({ tabs }) => (
  <Div pl={5}>
    {R.map(({ label, active, onClick, value }) => (
      <DarkTab key={value} px={2} fs={3} active={active} onClick={onClick}>
        {label}
      </DarkTab>
    ))(tabs)}
  </Div>
);

const Type6Card = tabStateHandler(
  ({
    cardTitleIcon: Icon,
    cardTitleIconColor,
    cardTitle,
    cardTitleButtons = [],
    tabs,
    activeComponent,
    children,
  }) => (
    <Div bra={1} ba={1} bc="neutral3" bg="white" display="column">
      <Div bc="neutral1" bb={1}>
        <Div display="row.flex-start.center" px={5} py={4}>
          <Div display="row.flex-start.center" flex={1}>
            <Div
              display="row.center.center"
              size={28}
              bg={cardTitleIconColor}
              pill
            >
              <Icon color="white" size={16} />
            </Div>
            <Text4 bold ml={2}>
              {cardTitle}
            </Text4>
          </Div>

          <Div>
            {R.addIndex(R.map)(
              (props, i) => (
                <MediumOutlineButton
                  color="neutral8"
                  ml={i === 0 ? 0 : 1}
                  {...props}
                />
              ),
              cardTitleButtons,
            )}
          </Div>
        </Div>
        {tabs && <Tabs tabs={tabs} />}
      </Div>
      <Div style={{ overflowY: "scroll" }} flex={1}>
        {activeComponent || children}
      </Div>
    </Div>
  ),
);

export { Type6Card };
