import React from "react";
import { Div, Text2, SmallAvatar } from "../../index";

const AvatarLabel = ({ id, frontText, backText, photoURL, ...props }) => {
  return (
    <Div display={"row.flex-start.center"} {...props}>
      <SmallAvatar
        id={id}
        text={frontText}
        photoURL={photoURL}
        hashable={backText}
      />
      <Div ml={2} display={"row.flex-start.center"}>
        {frontText && <Text2 bold>{frontText}</Text2>}
        {backText && <Text2 bold ml={2}>{`(${backText})`}</Text2>}
      </Div>
    </Div>
  );
};

export { AvatarLabel };
