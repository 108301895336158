import React from "react";
import Tooltip from "components/Global/Tooltip";
import {
  Div,
  BigTextButton,
  AddCircleIcon,
  NoneIcon,
  InfoIcon,
  Text1,
  Text3,
} from "../../index";

const Type13Card = ({ Icon = NoneIcon, name, tooltip, onAdd }) => (
  <Div
    className="hoverContainer"
    my={1}
    px={2}
    pr={1}
    bra={1}
    bg="gray1"
    display="row.flex-start.center"
  >
    <Icon sizeWFS={4} mr={2} />
    <Div flex={1} display="row.flex-start.center">
      <Text3 pr={1} bold>
        {name}
      </Text3>
      {tooltip ? (
        <span className="showOnHover">
          <Tooltip placement="top" tooltip={tooltip}>
            <span style={{ display: "flex", cursor: "pointer" }}>
              <InfoIcon />
            </span>
          </Tooltip>
        </span>
      ) : (
        ""
      )}
    </Div>
    {onAdd ? (
      <BigTextButton
        color="neutral6"
        OnlyIcon={AddCircleIcon}
        onClick={onAdd}
      />
    ) : undefined}
  </Div>
);

export { Type13Card };
