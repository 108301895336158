import React from "react";
import { Div, Span, Text3, Text2, Text1, NoneIcon } from "../../index";
import { noop } from "../../utils";

const Type8Card = ({
  Button,
  title = "",
  subtitle,
  onClickButton = noop,
  LeftIcon = NoneIcon,
  RightIcon,
  rightTextColor = "neutral5",
  rightText,
  mobile = false,
  required = false,
  ...props
}) => (
  <Div
    bg={"white"}
    bra={1}
    bc={"neutral3"}
    ba={1}
    p={2}
    display={"row.flex-start.center"}
    width={1}
    {...props}
  >
    <LeftIcon size={24} style={{ flexShrink: 0 }} />
    <Div display="column" width={1} flex={1} ml={2}>
      <Div display="row.flex-start.center">
        <Text3 bold color="gray8">
          {title}
          {required ? (
            <Span fs={3} bold color="danger5">
              &nbsp;*
            </Span>
          ) : null}
        </Text3>
      </Div>
      {subtitle ? (
        <Text2
          color="neutral5"
          truncate
          style={{ maxWidth: mobile ? "150px" : "290px" }}
        >
          {subtitle}
        </Text2>
      ) : null}
    </Div>
    {Button && <Button onClick={onClickButton} />}
    {RightIcon ? (
      <RightIcon size={20} />
    ) : (
      <Text1 mr={2} bold color={rightTextColor}>
        {rightText}
      </Text1>
    )}
  </Div>
);

export { Type8Card };
