import { createHandlers } from "redux-mvc";

import { STRATEGY, STATUS } from "./constants";

const model = createHandlers({
  namespace: "AppConnection",
  iniState: {
    // eslint-disable-next-line no-underscore-dangle
    pingUrl: window.__CONNECTION_PING_URL__,
    // eslint-disable-next-line no-underscore-dangle
    pingInterval: window.__CONNECTION_PING_INTERVAL__,
    checkStrategy: STRATEGY.EVENT,
    connectionStatus: STATUS.CONNECTED,
  },
});

const { actions, getters } = model;

export { actions, getters };

export default model;
