function constructPagination({ pages, page }) {
  const firstPage = 1;
  const lastPage = pages;
  const activePage = page + 1;

  if (pages <= 7) {
    return [...Array.from(Array(pages), (v, i) => ++i)];
  }
  if (activePage < 5) {
    return [...Array.from(Array(6), (v, i) => ++i), "...", lastPage];
  }
  if (activePage > lastPage - 5) {
    return [
      firstPage,
      "...",
      ...Array.from(Array(6), (v, i) => lastPage - i).reverse(),
    ];
  }
  return [
    firstPage,
    "...",
    activePage - 2,
    activePage - 1,
    activePage,
    activePage + 1,
    activePage + 2,
    "...",
    lastPage,
  ];
}
export default constructPagination;
