/* eslint-disable no-unused-vars, consistent-return */
import * as R from "ramda";

import React, { Component } from "react";
import ReactTextarea from "react-textarea-autosize";
import { Input, TextArea, Custom } from "../Base/index";
import { toClass } from "../utils";

const TextControllerMaker = (BaseComp) => {
  const Base = toClass(BaseComp); // convert to class to allow for refs
  class Controller extends Component {
    constructor(props) {
      super(props);
      this.state = { value: props.defaultValue || props.value || "" };

      this.handleChange = this.handleChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.getValue = this.getValue.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.value !== nextProps.value) {
        this.setState({ value: nextProps.value });
      }
    }

    getValue() {
      return this.state.value;
    }

    handleChange(event) {
      this.setState({ value: event.target.value });
      if (this.props.continuous) {
        this.props.onChange(event.target.value, event);
      }
    }

    handleKeyDown(event) {
      if (event.key === "Enter") {
        this.props.onChange(event.target.value, event);
      }
    }

    handleBlur(event) {
      if (this.props.continuous) return false; // this becomes an extra call to onChange when continuous, so we skip it in this case.
      this.props.onChange(event.target.value, event);
    }

    render() {
      const {
        onChange,
        continuous,
        value,
        onBlur,
        placeholder,
        style,
        inputRef,
        onFocus,
        ...other
      } = this.props;

      return (
        <Base
          onFocus={(e) => {
            // to set the cursor at the end of the input text
            const val = e.target.value;
            e.target.value = "";
            e.target.value = val;
            if (typeof onFocus === "function") onFocus(e);
          }}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={onBlur || this.handleBlur}
          placeholder={placeholder}
          ref={inputRef}
          style={{
            outline: "none",
            ...style,
          }}
          {...other}
        />
      );
    }
  }
  return Controller;
};

const TextInputController = TextControllerMaker(Input);
const TextAreaController = TextControllerMaker(TextArea);
const TextAreaControllerAutoSize = TextControllerMaker(Custom(ReactTextarea));

const textAreaGeneralStyle = {
  ba: 0,
  bg: "white",
  color: "neutral7",
  fs: 3,
  mb: 2,
  p: 2,
};

const textAreaBorderStyle = {
  ba: 1,
  bc: "neutral2",
  bra: 1,
  shadow: "inset",
};

const TextAreaInput = ({ autoSize, inputRef, style, ...props }) => {
  const Comp = autoSize ? TextAreaControllerAutoSize : TextAreaController;

  const styleProps = {
    minHeight: 72,
    ...style,
  };
  return (
    <Comp
      {...textAreaGeneralStyle}
      {...textAreaBorderStyle}
      {...props}
      ref={inputRef}
      style={
        autoSize ? R.omit(["minHeight", "maxHeight"], styleProps) : styleProps
      }
    />
  );
};

export { TextInputController, TextAreaInput };
