import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const messages = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.moduleId, action.payload.recordId],
          action.payload.messages,
        )
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId, action.payload.recordId], true)
        .toJS();
    case ERROR:
      // @NOTE: On error of any fetching, we clear out all fetching states since
      // we don't know where exactly we failed
      return {};
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId, action.payload.recordId], false)
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  messages,
  fetching,
});
