import { combineReducers } from "redux";
import { ERROR } from "redux/modules/errors/constants";
import { REQUEST, RECEIVE } from "./constants";

const status = (state = null, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  fetching,
});
