import React, { useState } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Popover, MenuList, MenuItem } from "@mui/material";

const SizeSelector = ({
  onPageSizeChange = () => {},
  pageSizeOptions = [],
  pageSize = 0,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onRequestClose = () => setShowPopover(false);

  const handleClick = (ev) => {
    ev.stopPropagation();
    setAnchorEl(ev.currentTarget);
    setShowPopover(true);
  };

  const handleMenuSelect = (ev) => {
    setShowPopover(false);
    onPageSizeChange(ev.target.value);
  };

  return (
    <div>
      <div className={css.select} onClick={handleClick}>
        <span>Show: {pageSize}</span>
        <i className="material-icons">keyboard_arrow_down</i>
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={onRequestClose}
        open={showPopover}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <MenuList>
          {pageSizeOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={handleMenuSelect}
              className={css.menuItem}
            >
              Show {option}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
};

export default CSSModules(css)(SizeSelector);
