import * as R from "ramda";
import {
  cancel,
  fork,
  take,
  call,
  put,
  select,
  all,
  takeEvery,
  spawn,
} from "redux-saga/effects";
import { push } from "react-router-redux";
import { registerError } from "redux/modules/errors/actions";

import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { getEventUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/actions";
import { actions, getters } from "./model";
import { actions as AlertActions } from "ui-kit/Alert/model";

import { user as getUser } from "redux/modules/user/selectors";

import { getAttend } from "App/Data/sagas";

import * as eventActions from "redux/modules/event/constants";
import { VIRTUAL_TOASTS_INSTANCE, ACTIONS } from "Attend/Gate/constants.js";

const navigateToAlert = function* ({ payload: { data } }) {
  const eventUUID = yield select(getters.eventUUID);
  const eventSlug = yield select(getters.eventSlug);
  yield put(push(`/attend/${eventSlug}/${eventUUID}/alerts/${data.id}`));
};

const delegates = {
  [ACTIONS.NAVIGATE_TO_ALERT]: navigateToAlert,
};

const watchExecuteAction = function* () {
  for (;;) {
    const action = yield take(
      (ac) =>
        ac.type === AlertActions.executeAction.type &&
        ac.meta.instanceId === VIRTUAL_TOASTS_INSTANCE,
    );

    const delegate = R.prop(R.path(["payload", "actionId"], action), delegates);
    if (delegate) {
      yield spawn(delegate, action);
    }
  }
};

const watchOnRegisterToBidOnItem = function* () {
  for (;;) {
    const {
      payload: { itemId = undefined, operation, amount },
    } = yield take(actions.loginToBidOnItem.type);
    let afterLoginParams = "";
    if (!R.isEmpty(operation) && !R.isEmpty(amount)) {
      afterLoginParams = `operation=${operation}&amount=${amount}`;
    }
    yield put(
      actions.setLoginToBidData({
        afterLoginParams,
        itemId,
      }),
    );
  }
};

const handleCallback = function* ({ payload }) {
  if (payload.returnTo && payload.returnTo.length) {
    window.location = payload.returnTo;
  } else {
    window.location = `/register/${payload.eventSlug}/${payload.eventUUID}?loggedIn=true`;
  }
};

const watchInit = function* () {
  let task = null;
  for (;;) {
    const {
      payload: { eventSlug, eventUUID },
    } = yield take(actions.init.type);
    if (task) {
      yield cancel(task);
    }
    try {
      // get wrapper payload and event details
      const result = yield call(getAttend, {
        eventSlug,
        eventUUID,
        throwNoAccessException: false,
      });
      const event = R.pathOr({}, ["payload", "event"], result);
      const isPublished = R.path(["payload", "is_published"], result);
      const user = yield select(getUser);
      const userExists = Boolean(user && user.id);
      const externalRegistrationUrl = result?.payload?.registration_url || "";

      yield all([
        put(actions.setExternalRegistrationUrl(externalRegistrationUrl)),
        put(actions.setOrgName(result?.payload?.org_name)),
        put(
          actions.setClassyOrgPrivacyPolicyUrl(
            result?.payload?.classy_org_privacy_policy_url,
          ),
        ),
      ]);

      // if event is not published, redirect
      if (!R.prop("id", user) && !isPublished) {
        yield put(push(`/login?message=This event has not been published yet`));
      } else {
        yield all(
          [
            put({
              type: eventActions.RECEIVE,
              payload: event,
            }),
            userExists ? put(getUserAccessLevels()) : null,
            userExists
              ? put(getEventUserPermissionProfile(event.id, user.id))
              : null,
          ].filter((v) => v),
        );
        yield put(actions.setLoading(false));
      }
    } catch (e) {
      yield put(
        registerError([
          {
            system: e,
            user: "There was an error while trying to load this auction page.",
          },
        ]),
      );
    }
  }
};

const watchCallback = function* () {
  yield takeEvery(actions.handleLoginCallback.type, handleCallback);
};

const root = function* () {
  yield all([
    spawn(watchInit),
    spawn(watchExecuteAction),
    fork(watchOnRegisterToBidOnItem),
    fork(watchCallback),
  ]);
};

export default root;
