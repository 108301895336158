import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

// this module is running in the global store redux/modules/global
const module = createModule(model);

module.setRootSaga(sagas);

export default module;
