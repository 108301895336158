import { findIndex } from "lodash";

export const toggleRowApprovalOnSubmission = (submission, data) => {
  const idx = findIndex(submission.approvals, { row_id: data.rowId });

  // update submission response row status
  if (idx === -1) {
    submission.approvals.push({
      response_id: data.responseId,
      grid_id: data.gridId,
      row_id: data.rowId,
      status: data.status,
    });
  } else if (submission.approvals[idx].status === data.status) {
    submission.approvals[idx].status = "pending";
  } else {
    submission.approvals[idx].status = data.status;
  }

  // lock the submission
  submission.locked = true;
  return submission;
};

export const addChangeToSubmission = (submission, data) => {
  submission.changes.push({
    id: 0,
    response_id: data.responseId,
    row_id: data.rowId,
    path: data.path,
    old_value: data.oldValue,
    new_value: {
      value: data.newValue,
    },
  });

  // lock the submission
  submission.locked = true;
  return submission;
};

export const updateApprovalNoteOnSubmission = (submission, data) => {
  const idx = findIndex(submission.approvals, { row_id: data.rowId });
  if (idx !== -1) {
    submission.approvals[idx].note = data.note;
  } else {
    submission.approvals.push({
      response_id: data.responseId,
      grid_id: data.gridId,
      row_id: data.rowId,
      status: "pending",
      note: data.note,
    });
  }
  return submission;
};
