import { combineReducers } from "redux";
import { RECEIVE, REQUEST, RECEIVE_PREVIEWS } from "./constants";

const emailMergeTags = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.mergeTags;
    default:
      return state;
  }
};

const previews = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PREVIEWS:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
    case RECEIVE_PREVIEWS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  emailMergeTags,
  previews,
  fetching,
});
