import * as R from "ramda";

export const user = R.pathOr({}, ["user", "user"]);
export const userId = R.compose(R.propOr("", "id"), user);
export const getUserTimezone = R.compose(R.prop("timezone"), user);

export const getUserMutedSound = R.compose(R.prop("mute_sounds"), user);
export const userEvents = R.compose(R.propOr([], "events"), user);

export const isClassyAdmin = R.compose(
  R.propOr(false, "is_classy_admin"),
  user,
);

export const isClassySuperAdmin = R.compose(
  R.propOr(false, "is_super_admin"),
  user,
);

export const getCredentials = (state) => state.user.user.credentials;
export const isSessionCheckComplete = (state) =>
  state.user.user.sessionCheckComplete;

const isFormManager = (state, props) =>
  R.compose(
    R.any(R.compose(R.equals(R.prop("id", user(state))), R.prop("user_id"))),
    R.pathOr([], ["form", "form_users"]),
  )(props);

export const getShowAdminFields = (state, props) =>
  R.has("showAdminFields", props)
    ? R.prop("showAdminFields", props)
    : isFormManager(state, props);

export const getReadOnly = (state, props) =>
  R.has("readOnly", props)
    ? R.prop("readOnly", props)
    : R.not(isFormManager(state, props));

export const getUserPhoneNumber = R.compose(R.prop("phone"), user);

export const hasUserEnteredPhoneNumber = R.compose(Boolean, getUserPhoneNumber);
