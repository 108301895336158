const ns = "FORMS_V2_FORM_PREFERENCES";
export const FIELD_WIDTH_UPDATE = `${ns}_FIELD_WIDTH_UPDATE`;
export const FIELD_WIDTH_UPDATE_COMPLETE = `${ns}_FIELD_WIDTH_UPDATE_COMPLETE`;
export const FIELD_VISIBILITY_UPDATE = `${ns}_FIELD_VISIBILITY_UPDATE`;
export const FIELD_VISIBILITY_UPDATE_COMPLETE = `${ns}_FIELD_VISIBILITY_UPDATE_COMPLETE`;
export const ROW_ORDER_UPDATE = `${ns}_ROW_ORDER_UPDATE`;
export const ROW_ORDER_UPDATE_COMPLETE = `${ns}_ROW_ORDER_UPDATE_COMPLETE`;

export const DEFAULT_FIELD_WIDTH_UPDATE = `${ns}_DEFAULT_FIELD_WIDTH_UPDATE`;
export const DEFAULT_FIELD_WIDTH_UPDATE_COMPLETE = `${ns}_DEFAULT_FIELD_WIDTH_UPDATE_COMPLETE`;
export const DEFAULT_FIELD_VISIBILITY_UPDATE = `${ns}_DEFAULT_FIELD_VISIBILITY_UPDATE`;
export const DEFAULT_FIELD_VISIBILITY_UPDATE_COMPLETE = `${ns}_DEFAULT_FIELD_VISIBILITY_UPDATE_COMPLETE`;
export const DEFAULT_ROW_ORDER_UPDATE = `${ns}_DEFAULT_ROW_ORDER_UPDATE`;
export const DEFAULT_ROW_ORDER_UPDATE_COMPLETE = `${ns}_DEFAULT_ROW_ORDER_UPDATE_COMPLETE`;

export const ERROR = `${ns}_ERROR`;
export const CLEAR_ERROR = `${ns}_CLEAR_ERROR`;
