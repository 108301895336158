import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, INVALIDATE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const person = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.recordId], action.payload.person)
        .toJS();
    case INVALIDATE:
      return Immutable.fromJS(state)
        .deleteIn(["byId", action.payload.recordId])
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  person,
});
