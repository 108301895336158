import React from "react";
import * as R from "ramda";
import { Div, Text4, MediumClearButton } from "../index";

const Dialog = ({
  dialogText,
  onClickPositive,
  positiveText,
  onClickNegative,
  negativeText,
  ...props
}) => {
  return (
    <Div px={5} bg={"white"} {...props}>
      <Text4 py={5} bold color={"neutral7"}>
        {dialogText}
      </Text4>
      <Div display={"row.flex-end.center"} py={2} bt={1} bc={"neutral3"}>
        <MediumClearButton color={"neutral5"} onClick={onClickNegative}>
          {negativeText}
        </MediumClearButton>

        <MediumClearButton ml={2} color={"primary7"} onClick={onClickPositive}>
          {positiveText}
        </MediumClearButton>
      </Div>
    </Div>
  );
};

export { Dialog };
