import Immutable from "immutable";
import { RECEIVE } from "./constants";

const visibleFields = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.recordTypeId, action.payload.layoutType],
          action.payload.visibleFields,
        )
        .toJS();
    default:
      return state;
  }
};

export default visibleFields;
