import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, orgId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/organizations/${orgId}`,
        credentials,
        data: {},
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `/organizations/${data.orgId}`,
        credentials,
        data,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
};
