import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";

import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { openFilePicker } from "utils/General/sagas";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Api from "./api";

import {
  eventId as getEventId,
  eventDetails as getEventDetails,
} from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { getEvent } from "redux/modules/event/actions";
import { getSupport } from "App/Data/sagas";
import Helpers from "utils/Global/Helpers";

const init = function* () {
  try {
    const credentials = yield select(getCredentials);
    const eventDetails = yield select(getEventDetails);
    const isMobile = Helpers.isMobile();

    if (isMobile) {
      yield put(actions.setCollapsed(true));
    }

    const [{ payload: navigation }] = yield all([
      call(Api.getNavigation, {
        credentials,
        eventId: eventDetails.id,
      }),
      yield call(getSupport),
    ]);

    yield put(actions.setNavigation(navigation));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting navigation",
          },
        ]),
      ),
    ]);
  }
};

const refreshNavigation = function* () {
  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const { payload: navigation } = yield call(Api.getNavigation, {
      credentials,
      eventId,
    });

    yield put(actions.setNavigation(navigation));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting navigation",
          },
        ]),
      ),
    ]);
  }
};

const showFilePicker = function* () {
  const files = yield call(openFilePicker, {
    multiple: false,
    fromSources: ["local_file_system"],
    accept: ["image/jpeg", "image/svg+xml", "image/png"],
    storeTo: {
      path: "event-logo/",
    },
  });

  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);
    yield call(Api.put, {
      credentials,
      eventId,
      data: {
        logoImageUrl: R.path([0, "url"])(files),
      },
    });

    yield put(getEvent(eventId));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred uploading photo",
        },
      ]),
    );
  }
};

const publishEvent = function* () {
  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);
    yield call(Api.put, {
      credentials,
      eventId,
      data: {
        isVirtualEventPublished: true,
      },
    });

    yield put(getEvent(eventId));

    yield put(showSnackbar({ message: "Your event has been published" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred publishing event",
        },
      ]),
    );
  }
};

const watchInit = function* () {
  yield takeEvery(actions.init.type, init);
};

const watchPublishEvent = function* () {
  yield takeEvery(actions.publishEvent.type, publishEvent);
};

const watchShowFilepicker = function* () {
  yield takeEvery(actions.showLogoFilepicker.type, showFilePicker);
};

const watchRefreshNavigation = function* () {
  yield takeEvery(actions.refreshNavigation.type, refreshNavigation);
};

const rootSaga = function* () {
  yield all([
    fork(watchPublishEvent),
    fork(watchShowFilepicker),
    fork(watchRefreshNavigation),
    fork(watchInit),
  ]);
};

export default rootSaga;
