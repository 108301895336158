import * as R from "ramda";

import { get } from "lodash";

import moment from "moment-timezone";
import { CREDIT_CARD_REQUIREMENT_OPTION_IDS } from "EventLight/Bidding/Settings/constants";

export const FLAGS = {
  BETA: "flag--beta",
  DONATE_FORM: "flag--donate-form",
};

export const isFetching = (state) =>
  state.event.event.fetching || !state.event.event.event.id;

export const eventDetails = (state) => state.event.event.event;

export const isEventError = (state) => state.event.event.eventError;

export const getCreditCardRequiredForBidding = (state) => {
  const event = eventDetails(state);
  return Boolean(
    event?.bidding_credit_card_requirement ===
      CREDIT_CARD_REQUIREMENT_OPTION_IDS.REQUIRED || true,
  );
};

export const getCreditCardOptionalForBidding = (state) => {
  const event = eventDetails(state);

  return Boolean(
    event?.bidding_credit_card_requirement ===
      CREDIT_CARD_REQUIREMENT_OPTION_IDS.OPTIONAL || false,
  );
};

export const getCreditCardNotRequiredForBidding = (state) => {
  const event = eventDetails(state);
  return Boolean(event?.bidding_credit_card_requirement === "none");
};

export const eventId = (state) => get(state.event, "event.event.id");
export const orgId = (state) => get(state.event, "event.event.org_id");

export const eventUUID = R.pathOr("", ["event", "event", "event", "uuid"]);
export const eventSlug = R.pathOr("", ["event", "event", "event", "slug"]);
export const eventName = R.pathOr("", ["event", "event", "event", "name"]);
export const getEventTimezone = R.path(["event", "event", "event", "timezone"]);
export const getEventCurrency = R.path(["event", "event", "event", "currency"]);
export const credentials = (state) =>
  get(eventDetails(state), "module_settings.credentials.credentials", []);
export const getEventScope = R.path(["event", "event", "event", "scope"]);
export const getIsLightEvent = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_light",
]);
export const isTrialOver = R.pathOr("", [
  "event",
  "event",
  "event",
  "is_trial_over",
]);
export const isBulkPayEnabled = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_bulk_pay_enabled",
]);
export const isAddTicketsEnabled = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_add_tickets_enabled",
]);
export const isImportAttendeesEnabled = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_import_attendees_enabled",
]);
export const isMobileBiddingEnabled = R.pathOr(false, [
  "event",
  "event",
  "event",
  "plan_mobile_bidding",
]);
export const isBillingAddressRequired = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_billing_address_required",
]);

export const isVirtualEvent = (state) => getEventScope(state) === "virtual";
export const isHybridEvent = (state) => getEventScope(state) === "hybrid";
export const isLiveEvent = (state) => getEventScope(state) === "live";
export const isVirtualOrHybridEvent = (state) =>
  isHybridEvent(state) || isVirtualEvent(state);
export const isLiveOrHybridEvent = (state) =>
  isHybridEvent(state) || isLiveEvent(state);

export const isEventFeatureEnabled = (state) => (feature) => {
  return R.pathOr(
    [],
    ["event", "event", "event", "enabled_features"],
  )(state).includes(feature);
};

export const isEventFlagEnabled = (state) => (flag) => {
  return R.pathOr(false, ["event", "event", "event", "flags", flag])(state);
};

export const accountTypes = (state) =>
  get(eventDetails(state), "module_settings.accounts.types", []).sort(
    (a, b) => b.name.toLowerCase() - a.name.toLowerCase(),
  );
export const eventHomeAccountRecordTypes = (state) =>
  get(state.event, "event.eventHomeAccountRecordTypes");

export const getBackgroundUrl = R.compose(
  (url) => `url(${url})`,
  R.pathOr("", ["event", "event", "event", "background_image_url"]),
);

export const getEventRegistrationUrl = R.compose(
  // eslint-disable-next-line no-underscore-dangle
  (event) => `${window.__LIVE_APP_URL__}/register/${event.slug}/${event.uuid}`,
  R.pathOr("", ["event", "event", "event"]),
);

export const getSubscriptionFeatures = R.pathOr({}, [
  "event",
  "event",
  "event",
  "subscription_features",
]);

export const isSubscriptionFeatureEnabled = (state) => (feature) => {
  return R.prop(feature)(getSubscriptionFeatures(state));
};

export const getNetworkingMinMeetingDuration = R.pathOr(0, [
  "event",
  "event",
  "event",
  "networking_min_meeting_duration",
]);

export const getLengthOfEventDays = (state) => {
  const evt = eventDetails(state);
  const startDay = moment(evt.date_from).tz(evt.timezone).format("DD") || "0";
  const endDay = moment(evt.date_to).tz(evt.timezone).format("DD") || "0";

  return Math.abs(parseInt(endDay) - parseInt(startDay));
};

export const isCampaignMapped = (data) =>
  data?.event?.event?.event?.is_campaign_mapped ?? false;
