import { HIDE, SHOW } from "./constants";

const open = (state = { default: true }, action) => {
  switch (action.type) {
    case HIDE:
      return { ...state, [action.moduleId]: false };
    case SHOW:
      return { ...state, [action.moduleId]: true };
    default:
      return state;
  }
};

export default open;
