import { get } from "lodash";

export const isFetching = (state) => state.organization.organization.fetching;
export const organization = (state) =>
  state.organization.organization.organization;
export const orgEvents = (state) => get(organization(state), "events", []);
export const orgDetails = (state) =>
  state.organization.organization.organization || {};
export const getIsLightOrg = (state) => get(organization(state), "is_light");

export const subscription = (state) =>
  get(organization(state), "subscription") || {};
export const orgId = (state) => get(organization(state), "id");
export const getCustomerId = (state) =>
  get(organization(state), "stripe_customer_id");
export const getSubscriptionId = (state) =>
  get(organization(state), "stripe_subscription_id");
export const isTrialOver = (state) => get(organization(state), "is_trial_over");
export const isBulkPayEnabled = (state) =>
  get(organization(state), "is_bulk_pay_enabled");
export const isBidAnonymouslyEnabled = (state) =>
  get(organization(state), "is_bid_anonymously_enabled");
