import Immutable from "immutable";
import { UPDATE } from "./constants";
import {
  CREATE as VALUE_CREATE,
  CREATE_SUBFORM as SUBFORM_VALUE_CREATE,
} from "./values/constants";
import {
  CREATE as SUBFORM_REVIEW_CREATE,
  BULK_CREATE as SUBFORM_REVIEW_BULK_CREATE,
} from "./reviews/constants";

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case SUBFORM_VALUE_CREATE: {
      const { value, submissionId, subformFieldId, fieldId } = action.payload;
      return Immutable.fromJS(state)
        .setIn(
          [
            // Path to update
            "values",
            subformFieldId,
            "value",
            "submissions",
            submissionId,
            "values",
            fieldId,
          ],
          value,
        )
        .toJS();
    }
    case SUBFORM_REVIEW_CREATE: {
      const { status, submissionId, subformId } = action.payload;

      // if handling a single submission review, process update
      if (submissionId === state.id) {
        return Immutable.fromJS(state)
          .setIn(
            [
              // Path to update
              "review",
              "status",
            ],
            status,
          )
          .toJS();
      }
      return Immutable.fromJS(state)
        .setIn(
          [
            // Path to update
            "values",
            subformId,
            "value",
            "submissions",
            submissionId,
            "review",
            "status",
          ],
          status,
        )
        .toJS();
    }
    case SUBFORM_REVIEW_BULK_CREATE: {
      const { reviews, subformId } = action.payload;

      return reviews.reduce((updatedState, review) => {
        // if handling a single submission review, process update
        if (review.submissionId === updatedState.id) {
          return Immutable.fromJS(updatedState)
            .setIn(
              [
                // Path to update
                "review",
                "status",
              ],
              review.status,
            )
            .toJS();
        }
        return Immutable.fromJS(updatedState)
          .setIn(
            [
              // Path to update
              "values",
              subformId,
              "value",
              "submissions",
              review.submissionId,
              "review",
              "status",
            ],
            review.status,
          )
          .toJS();
      }, state);
    }
    case VALUE_CREATE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.fieldId]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
