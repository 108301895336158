import React from "react";
import {
  Div,
  CheckBoxBlankIcon,
  CheckBoxIcon,
  IndeterminateCheckBoxIcon,
} from "../index";

const SmallCheckbox = ({
  onClick,
  selected,
  disabled,
  indeterminate,
  size = 24,
  selectedColor = "indigo8",
  ...props
}) => (
  <Div width={size} height={size} {...props}>
    {indeterminate ? (
      <IndeterminateCheckBoxIcon
        color={disabled ? "gray5" : selectedColor}
        size={size}
        onClick={disabled ? undefined : onClick}
      />
    ) : selected ? (
      <CheckBoxIcon
        color={disabled ? "gray5" : selectedColor}
        size={size}
        onClick={disabled ? undefined : onClick}
      />
    ) : (
      <CheckBoxBlankIcon
        color="gray4"
        size={size}
        onClick={disabled ? undefined : onClick}
      />
    )}
  </Div>
);

export { SmallCheckbox };
