const ns = "SUBMISSION_CART";
export const RECEIVE = `${ns}_RECEIVE`;
export const ADD = `${ns}_ADD`;
export const ADD_BULK = `${ns}_ADD_BULK`;
export const ADD_COMPLETE = `${ns}_ADD_COMPLETE`;
export const ADD_BULK_COMPLETE = `${ns}_ADD_BULK_COMPLETE`;
export const REMOVE = `${ns}_REMOVE`;
export const REMOVE_BULK = `${ns}_REMOVE_BULK`;
export const REMOVE_COMPLETE = `${ns}_REMOVE_COMPLETE`;
export const UPDATE = `${ns}_UPDATE`;
