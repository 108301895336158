import React, { Fragment } from "react";
import {
  Div,
  FontIcon,
  AddCircleIcon,
  Text1,
  Text4,
  MediumClearButton,
} from "../../";

const CardGroup1 = ({
  heading,
  icon,
  iconColor,
  subtext,
  onCreateNew,
  children,
  onLearnMore,
}) => (
  <Div mb={5} pl={2}>
    <Div display="row.flex-start.center" mb={1}>
      {icon && <FontIcon fs={5} icon={icon} color={iconColor} mr={2} />}
      <Text4 bold>{heading}</Text4>
    </Div>
    {subtext && (
      <Text1 color="neutral5" pb={1}>
        {subtext}
        {onLearnMore && (
          <Text1 display="inline" underline onClick={onLearnMore}>
            Learn More
          </Text1>
        )}
      </Text1>
    )}

    {children}
    {onCreateNew && (
      <MediumClearButton LeftIcon={AddCircleIcon} onClick={onCreateNew}>
        Create New
      </MediumClearButton>
    )}
  </Div>
);

const CardGroup2 = ({
  heading,
  Icon,
  children,
  actions = [],
  footer,
  ...props
}) => (
  <Div {...props}>
    <Div display="row.flex-start.center" ml={1} mb={3}>
      {Icon && <Icon color="neutral7" sizeWFS={5} mr={2} />}
      <Div fs={4} fw={3} flex={1} color="gray7">
        {heading}
      </Div>
      {actions.length
        ? actions.map(([title, action]) => (
            <Text1
              key={title}
              ml={2}
              display="inline"
              underline
              onClick={action}
              color="gray6"
            >
              {title}
            </Text1>
          ))
        : ""}
    </Div>

    {children}
    <Div ml={1}>{footer}</Div>
  </Div>
);

export { CardGroup1, CardGroup2 };
