import React from "react";
import { Div, Text2 } from "../../index";

const BigDisplay = ({ text, subText, ...props }) => (
  <Div display={"column.flex-start.center"} {...props}>
    <Div noLineHeight pb={1} fs={7} fw={3} bold color={"neutral7"}>
      {text}
    </Div>
    <Text2 uppercase bold color={"neutral6"}>
      {subText}
    </Text2>
  </Div>
);

export { BigDisplay };
