export const NAMESPACE = "EventLightBiddingSettings";

export const FORM_ID = "EventLightBiddingSettingsForm";

export const FIELD_IDS = {
  REGISTRATION_SMS_MESSAGE: "registration_sms_message",
  DESCRIPTION: "description",
  ENABLE_EXTERNAL: "enable_external",
  TERMS: "terms",
  REGISTERED_NAMES_FORMAT: "registered_names_format",
  EXTERNAL_NAMES_FORMAT: "external_names_format",
  PAYMENT_INSTRUCTIONS: "payment_instructions",
  BIDDER_NUMBER_METHOD: "bidder_number_method",
  IS_BILLING_ADDRESS_REQUIRED: "is_billing_address_required",
  BIDDER_NUMBER_STARTING_NUMBER: "bidder_number_starting_number",
  IS_DONOR_COVERED_FEES_ENABLED: "auction_enable_donor_covered_fees",
  APPEAL_COVER_FEES: "auction_appeal_donor_covered_fees",
  BIDDING_CREDIT_CARD_REQUIREMENT: "bidding_credit_card_requirement",
};

export const NAME_FORMATS = [
  { id: "first_last_initial", label: "First Name & Initial (Ex: Jane D.)" },
  { id: "full_name", label: "Full Name (Ex: Jane Doe)" },
  { id: "bidder_number", label: "Bidder Number (Ex: Bidder #205)" },
  { id: "anonymous", label: "Anonymous (Ex: Anonymous Bidder)" },
];

export const BIDDER_NUMBER_OPTION_IDS = {
  AUTO_ASSIGN: "auto_assign",
  MANUAL: "manual",
};

export const BIDDER_NUMBER_OPTIONS = [
  {
    id: BIDDER_NUMBER_OPTION_IDS.AUTO_ASSIGN,
    label:
      "Bidder Numbers are automatically assigned during ticketing and registration",
  },
  {
    id: BIDDER_NUMBER_OPTION_IDS.MANUAL,
    label: "Bidder Numbers are left blank for manual assignment at check-in",
  },
];

export const IS_BILLING_ADDRESS_REQUIRED_OPTION_IDS = {
  BILLING_ADDRESS_REQUIRED: "true",
  BILLING_ADDRESS_NOT_REQUIRED: "false",
};

export const IS_BILLING_ADDRESS_REQUIRED_OPTIONS = [
  {
    id: IS_BILLING_ADDRESS_REQUIRED_OPTION_IDS.BILLING_ADDRESS_REQUIRED,
    label: "Require billing address",
  },
  {
    id: IS_BILLING_ADDRESS_REQUIRED_OPTION_IDS.BILLING_ADDRESS_NOT_REQUIRED,
    label: "Don't require billing address",
  },
];

export const DATA_ID_PREFIX = "cl-auction-settings";

/**
 * Default text for appeal to cover fees message.
 * @type {string}
 */
export const DEFAULT_TEXT_AREA =
  "I’d like to cover a portion of the fees associated with my purchase";

/**
 * CREDIT CARD REQUIREMENTS
 */

export const CREDIT_CARD_REQUIREMENT_OPTION_IDS = {
  REQUIRED: "required",
  OPTIONAL: "optional",
  NONE: "none",
};

export const CREDIT_CARD_REQUIREMENT_OPTION_IDS_REVERSE = {
  required: CREDIT_CARD_REQUIREMENT_OPTION_IDS.REQUIRED,
  optional: CREDIT_CARD_REQUIREMENT_OPTION_IDS.OPTIONAL,
  none: CREDIT_CARD_REQUIREMENT_OPTION_IDS.NONE,
};

export const CREDIT_CARD_REQUIREMENT_OPTIONS = [
  {
    id: CREDIT_CARD_REQUIREMENT_OPTION_IDS.REQUIRED,
    label: "Require a credit card on file",
  },
  {
    id: CREDIT_CARD_REQUIREMENT_OPTION_IDS.OPTIONAL,
    label: "Credit card information is optional",
  },
  {
    id: CREDIT_CARD_REQUIREMENT_OPTION_IDS.NONE,
    label: "Remove credit card requirement",
  },
];
