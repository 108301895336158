import { RECEIVE } from "./constants";

const settings = (
  state = {
    sections: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

export default settings;
