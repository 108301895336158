/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { RECEIVE_USER, UPDATE_PHONE } from "redux/modules/user/constants";
import { EventTypes } from "redux-segment";
import { clearSsoCredentials } from "utils/General";
import SsoSession from "App/Config/ssoSession";
import { push } from "react-router-redux";
import { ssoLoginRedirectCounter } from "utils/General/localStorage";
import { getSSoLoginParams } from "./sso";

const isLoginPage = (path) => {
  return path.includes("/login");
};

const isPortalLoginPage = (path) => {
  return (
    path.includes("/portal-login") && !path.includes("/portal-login-callback")
  );
};

const isTicketPortalLoginPage = (path) => {
  return (
    path.includes("/requests-login") &&
    !path.includes("/requests-login-callback")
  );
};

const isSignupPage = (path) => {
  return path.includes("/account/confirm");
};

const isAttendPage = (path) => {
  return path.includes("/attend/") || path.includes("/attend-login");
};

const isDonatePage = (path) => {
  return path.includes("/donate");
};

const isDisplayPage = (path) => {
  return path.includes("/display");
};

const isAuctionPage = (path) => {
  return path.includes("/auction") && !path.includes("/event-light/");
};

const isRegisterPage = (path) => {
  return path.includes("/register");
};

const isAssignmentManagerPage = (path) => {
  return path.startsWith("/assignment-manager");
};

const isPortalPage = (path) => {
  return (
    path.includes("/portals") ||
    path.includes("/portal-switch") ||
    path.includes("/portal-login-callback")
  );
};

const isSalesPortalPage = (path) => {
  return path.includes("/sales-portal");
};

const isIntakePage = (path) => {
  return path.includes("/intake");
};

const isHealthPassPage = (path) => {
  return path.includes("/health-pass");
};

const isTicketPortalPage = (path) => {
  return (
    path.startsWith("/requests") ||
    path.includes("/requests-portal-login-callback")
  );
};

const isNoAccessPage = (path) => {
  return path.startsWith("/no-access");
};

const isSessionIssuePage = (path) => {
  return path.startsWith("/session-issues");
};

const isViewingPageThatDoesntRequireAuth = (path) => {
  return (
    path.includes("/join/vu/") ||
    path.includes("/logout/callback") ||
    path.startsWith("/invoice") ||
    (path.includes("/batch-invoice") && !path.includes("admin")) ||
    path.startsWith("/forgot") ||
    path.startsWith("/reset")
  );
};

const isViewingPageThatRedirectsIfAuthorized = (path) => {
  return isNoAccessPage(path);
};

export const decorateUserPayload = (user, credentials = {}) => ({
  id: user.id,
  email: user.email,
  slug: user.slug,
  fname: user.fname,
  lname: user.lname,
  photo_url: user.photo_url,
  phone: user.phone,
  location: user.location,
  timezone: user.timezone,
  events: user.events,
  is_classy_admin: user.is_classy_admin,
  isAdmin: user.is_admin,
  mute_sounds: user.mute_sounds,
  member_id: user.member_id,
  org_id: user.org_id,
  credentials: {
    userId: user.id,
    ...credentials,
  },
});

const decorateUserMeta = (user) => {
  let meta = {};
  if (window.__SEGMENT_ENABLED__) {
    meta = {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: user.id,
          traits: {
            fname: user.fname,
            lname: user.lname,
            email: user.email,
            username: user.email,
          },
          options: {
            Intercom: { hideDefaultLauncher: true },
            context: {
              app: {
                env: window.__ENV__,
                name: window.__APP_NAME__,
                version: window.__APP_VERSION__,
                build: window.__APP_BUILD__,
                cookies_enabled: navigator.cookieEnabled,
              },
            },
          },
        },
      },
    };
  }
  return meta;
};

const configureErrorHandling = (user) => {
  try {
    if (window.__BUGSNAG_ENABLED__) {
      window.Bugsnag.setUser(
        user.id,
        user.email,
        user.first_name && user.first_name.length
          ? [user.first_name, user.last_name].join(" ")
          : null,
      );
    }
  } catch (e) {
    console.log("[Bugsnag Init Error]", e);
    /* swallow */
  }
};

/**
 * Redirects to the Classy SSO Login page
 */
export function redirectToSsoLogin() {
  const numPreviousRedirects = ssoLoginRedirectCounter.getCount();
  const ssoLoginParams = getSSoLoginParams({ numPreviousRedirects });

  const ssoLoginUrl = `${window.__CLASSY_BASE_URL__}/sso?${new URLSearchParams(
    ssoLoginParams,
  )}`;

  ssoLoginRedirectCounter.incrementCount();

  window.location.replace(ssoLoginUrl);
  return;
}

export function getLoggedInUserDetails() {
  return async (dispatch, getState) => {
    try {
      const { payload: user } = await api.getLoggedInUserDetails(
        getState().user.user.credentials,
      );

      configureErrorHandling(user);

      const decoratedUser = decorateUserPayload(
        user,
        getState().user.user.credentials,
      );

      dispatch({
        type: RECEIVE_USER,
        payload: decoratedUser,
        meta: decorateUserMeta(user),
      });

      return decoratedUser;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting logged in user details",
          },
        ]),
      );
    }
  };
}

/**
 * BEGIN LOGOUT
 */
const IDP_LOGOUT_PATH = `auth/logout`;
const SSO_LOGOUT_PATH = `sso/logout`;

function getFullLogoutPath({ isIdp }) {
  // conditional redirect depending on how the user is logged in

  if (isIdp) {
    return `${window.__CLASSY_BASE_URL__}/${IDP_LOGOUT_PATH}?r=${encodeURIComponent(`${window.__CLASSY_BASE_URL__}/${SSO_LOGOUT_PATH}`)}`;
  }

  return `${window.__CLASSY_BASE_URL__}/${SSO_LOGOUT_PATH}?r=${encodeURIComponent(`${window.__CLASSY_BASE_URL__}/${IDP_LOGOUT_PATH}`)}`;
}

export function logout() {
  return async () => {
    const credentials = SsoSession.getCredentials();

    // Clear all local storage, session storage, and cookies
    clearSsoCredentials();
    sessionStorage.clear();
    document.cookie = "";

    const isIdp = credentials?.isIdp === true;

    window.location.href = getFullLogoutPath({ isIdp });
  };
}

export function checkSsoSession() {
  return async (dispatch) => {
    // Allow access to whitelisted pages that don't require auth
    if (isViewingPageThatDoesntRequireAuth(window.location.pathname)) {
      dispatch({
        type: RECEIVE_USER,
        payload: decorateUserPayload({}),
      });
      return;
    }

    const currentlyViewingLoginPage = isLoginPage(window.location.pathname);
    const currentlyViewingPortalLoginPage = isPortalLoginPage(
      window.location.pathname,
    );
    const currentlyViewingTicketPortalLoginPage = isTicketPortalLoginPage(
      window.location.pathname,
    );

    if (SsoSession.getAccessToken()) {
      if (currentlyViewingLoginPage) {
        window.location = "/home?loggedIn=true";
        return;
      } else if (currentlyViewingPortalLoginPage) {
        const pathParts = window.location.pathname.split("/");
        window.location = `/portal-switch/${pathParts[2]}/${pathParts[3]}`;
        return;
      } else if (currentlyViewingTicketPortalLoginPage) {
        const pathParts = window.location.pathname.split("/");
        window.location = `/requests/${pathParts[2]}`;
        return;
      }

      try {
        await SsoSession.refreshTokenIfNecessary();
      } catch (err) {
        console.error(
          "Error while trying to obtain new access token via refresh token",
        );
        console.error(err);
        dispatch({
          type: RECEIVE_USER,
          payload: {},
          meta: {},
        });

        return;
      }

      const currentlyViewingPageThatRedirectsIfAuthorized =
        isViewingPageThatRedirectsIfAuthorized(window.location.pathname);

      try {
        const { payload: user } = await api.getLoggedInUserDetails(
          SsoSession.getCredentials(),
        );

        const decoratedUser = decorateUserPayload(
          user,
          SsoSession.getCredentials(),
        );

        dispatch({
          type: RECEIVE_USER,
          payload: decoratedUser,
          meta: decorateUserMeta(user),
        });

        if (currentlyViewingPageThatRedirectsIfAuthorized) {
          window.location = "/home?loggedIn=true";
          return;
        }
      } catch (e) {
        console.log(`Warning: User could not be fetched. ${e.message}`);
        dispatch({
          type: RECEIVE_USER,
          payload: {},
          meta: {},
        });

        //If user is on a /session-page page and refreshes,
        //should stay on the current page.
        if (isSessionIssuePage(window.location.pathname)) {
          return;
        }

        //If user is on the event or auction pages,
        //should redirected to the /session-issues page.
        if (
          isAuctionPage(window.location.pathname) ||
          isRegisterPage(window.location.pathname)
        ) {
          window.location = "/session-issues";
          return;
        }

        const currentlyViewingNoAccessPage = isNoAccessPage(
          window.location.pathname,
        );

        if (currentlyViewingNoAccessPage) {
          return;
        } else {
          window.location = `/no-access`;
          return;
        }
      }
      return;
    } else if (
      !currentlyViewingLoginPage &&
      !currentlyViewingPortalLoginPage &&
      !currentlyViewingTicketPortalLoginPage &&
      !isSignupPage(window.location.pathname) &&
      !isAttendPage(window.location.pathname) &&
      !isDonatePage(window.location.pathname) &&
      !isDisplayPage(window.location.pathname) &&
      !isAuctionPage(window.location.pathname) &&
      !isRegisterPage(window.location.pathname) &&
      !isAssignmentManagerPage(window.location.pathname) &&
      !isSalesPortalPage(window.location.pathname) &&
      !isIntakePage(window.location.pathname) &&
      !isHealthPassPage(window.location.pathname)
    ) {
      // otherwise, assume not logged in and redirect to login page (if not already on login page)
      if (isPortalPage(window.location.pathname)) {
        const pathParts = window.location.pathname.split("/");
        window.location = `/portal-login/${pathParts[2]}/${pathParts[3]}`;
        return;
      } else if (isTicketPortalPage(window.location.pathname)) {
        const pathParts = window.location.pathname.split("/");
        window.location = `/requests/${pathParts[2]}/login`;
        return;
      } else {
        dispatch({
          type: RECEIVE_USER,
          payload: {},
          meta: {},
        });
        let route = "/login";
        if (window.location.pathname) {
          const path = encodeURIComponent(window.location.pathname);
          route = `${route}?returnTo=${path}`;
        }
        dispatch(push(route));
        return;
      }
    }

    dispatch({
      type: RECEIVE_USER,
      payload: decorateUserPayload({}),
    });
  };
}

export function updatePhone(phone) {
  return {
    type: UPDATE_PHONE,
    payload: phone,
  };
}
