import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, RECEIVE_ALL_EVENTS } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const recordTypes = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], action.payload.recordTypes)
        .toJS();
    default:
      return state;
  }
};

const allEventRecordTypes = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ALL_EVENTS:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], action.payload.recordTypes)
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
      return false;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  recordTypes,
  allEventRecordTypes,
  fetching,
});
