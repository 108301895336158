import React from "react";
import { Popover } from "../index";
import SmallCalendar from "react-datetime";

const DatePicker = ({
  timeFormat = false,
  initialValue,
  onChange,
  ...props
}) => (
  <Popover {...props}>
    {({ closePopover }) => (
      <SmallCalendar
        initialValue={initialValue}
        timeFormat={timeFormat}
        input={false}
        open
        onChange={(value) => {
          closePopover();
          onChange(value);
        }}
      />
    )}
  </Popover>
);

export { DatePicker };
