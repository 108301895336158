import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "AttendGate",
  iniState: {
    authorizing: true,
    selectedNavItem: "lobby",
    selectedTab: "chat",
    rightCollapsed: false,
    leftCollapsed: false,
    eventSlug: "",
    eventUUID: "",
  },
  reducers: {
    init: (_, { payload: { eventSlug, eventUUID } }) => ({
      eventSlug,
      eventUUID,
    }),
  },
});

const { actions, getters } = model;

export { actions, getters };

export default model;
