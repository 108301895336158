import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  color: null,
  defaultColor: "#ccc",
  eventName: null,
  logoImageUrl: null,
  afterLoginParams: "",
  itemIdToBid: null,
  eventDetails: {
    eventUUID: null,
    eventSlug: null,
  },
  event: {},
  loading: true,
  showLoginModal: false,
  externalRegistrationUrl: "",
  orgName: "",
  classyOrgPrivacyPolicyUrl: "",
};

const model = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    handleLoginCallback: R.always({
      showLoginModal: false,
    }),
    loginToBidOnItem: R.identity,
    setLoginToBidData: (
      state,
      { payload: { itemId: itemIdToBid, afterLoginParams } },
    ) => ({
      itemIdToBid,
      afterLoginParams,
      showLoginModal: true,
    }),
    setInitialData: (
      state,
      {
        payload: {
          highlight_color: color,
          event_logo_image_url: logoImageUrl,
          event_name: name,
          event_uuid: eventUUID,
          event_slug: eventSlug,
        },
      },
    ) => ({
      itemIdToBid: null,
      afterLoginParams: "",
      logoImageUrl: logoImageUrl,
      eventName: name,
      color: color || state.defaultColor,
      eventDetails: {
        eventUUID,
        eventSlug,
      },
    }),
  },
});

const { actions, getters } = model;

export { actions, getters };

export default model;
