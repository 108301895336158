import "./publicPath";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import RootContainer from "./containers/Root";
import configureStore from "./redux/configureStore";
import "./assets/favicons";
import "./assets/images";
import "./assets/audio";
import routes from "./routes";

// eslint-disable-next-line no-underscore-dangle
const store = configureStore(window.__INITIAL_STATE__, browserHistory);

const history = syncHistoryWithStore(browserHistory, store);

// avoid ini location null in redux
history.replace(window.location);

ReactDOM.render(
  <RootContainer history={history} routes={routes} store={store} />,
  document.getElementById("root"),
);
