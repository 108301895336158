export const makeChunk = (rootImportFn, components, retries = {}) => {
  const result = {};

  components.forEach((name) => {
    result[name] = function loadComponent(location) {
      if (!retries[name]) {
        retries[name] = 0;
      }

      return rootImportFn(location)
        .then((component) => {
          return component.default[name];
        })
        .catch((e) => {
          if (retries[name] < 3) {
            // eslint-disable-next-line no-console
            console.log(
              `Retrying to load ${name}, attempt ${retries[name] + 1}`,
            );
            retries[name] += 1;
            // Recursive retry
            return result[name](location);
          }
          // If the retry limit is reached, throw the error.
          throw e;
        });
    };
  });

  return result;
};
