import React from "react";

import * as STANDARD_MODULES from "@classy/classy-live-value-types/src/constants/standard-modules";

import { Route, IndexRedirect } from "react-router";
import CoreLayout from "layouts/CoreLayout";

import DefaultNotFound from "components/Platform/NotFound";

import userView from "components/Global/Security/AuthenticatedRoute";
import { makeChunk } from "./make-chunk";

const canUserView =
  (f, ...args) =>
  (location) =>
    f(location).then((component) => userView(component, ...args));

const Platform = makeChunk(
  () => import(/* webpackChunkName: "platform" */ "./Platform"),
  [
    "PlatformView",
    "HomeView",
    "SuperAdminView",
    "SuperAdminViewFeatureFlags",
    "SuperAdminFeatureFlagView",
  ],
);

const Admin = makeChunk(
  () => import(/* webpackChunkName: "admin" */ "./Admin"),
  ["AddClassyAdmin"],
);

const ClassyToClassyLive = makeChunk(
  () =>
    import(/* webpackChunkName: "classytoclassylive" */ "./ClassyToClassyLive"),
  ["AddClassyCampaign", "ClassyOrgRedirect"],
);

const Auth = makeChunk(
  () => import(/* webpackChunkName: "auth" */ "./Auth"),
  [
    "SignupStart",
    "SignupComplete",
    "SignupResend",
    "SignupVerificationConfirm",
    "LoginView",
    "CallbackView",
    "LogoutView",
    "LogoutCallbackView",
    "ForgotPasswordHandler",
    "InvoiceHandler",
    "JoinVirtualUserHandler",
    "LoggedOutView",
    "SignupOnboard",
    "NoAccessView",
    "SessionIssueView",
  ],
);

const Invoices = makeChunk(
  () => import(/* webpackChunkName: "invoices" */ "./Invoices"),
  ["InvoiceHandler", "InvoiceGate", "BulkInvoice"],
);

const Attend = makeChunk(
  () => import(/* webpackChunkName: "attend" */ "./Attend"),
  [
    "AttendAuction",
    "AttendAuctionItem",
    "AttendAuctionRules",
    "AttendAuctionDonate",
    "AttendAuctionPageWrapper",
    "AttendAuctionCheckout",
    "AttendLogin",
    "AttendRegister",
    "AttendDonate",
    "AttendGate",
    "AttendOnboard",
    "AttendLayout",
    "AttendLobby",
    "AttendStage",
    "AttendRooms",
    "AttendBackstage",
    "AttendNetworking",
    "AttendAttendees",
    "AttendBooth",
    "AttendBooths",
    "AttendAgenda",
    "AttendeAttendeeProfile",
    "AttendSponsors",
    "AttendSpeakers",
    "AttendContent",
    "AlertsList",
    "AlertDetails",
    "AttendDisplay",
    "AttendMyProfile",
    "AttendAssignTickets",
  ],
);

const OrgLight = makeChunk(
  () => import(/* webpackChunkName: "orglight" */ "./OrgLight"),
  [
    "OrgLightGate",
    "OrgLightLayout",
    "OrgLightDashboard",
    "OrgLightEvents",
    "OrgLightBilling",
    "OrgLightTeam",
    "OrgLightDetails",
    "OrgLightIntegrations",
  ],
);

const EventLight = makeChunk(
  () => import(/* webpackChunkName: "eventlight" */ "./EventLight"),
  [
    "EventLightGate",
    "EventLightLayout",
    "EventLightDashboard",
    "EventLightDetails",
    "EventLightVenue",
    "EventLightStage",
    "EventLightAttendees",
    "EventLightBidding",
    "EventLightText",
    "EventLightSeating",
    "EventLighNetworking",
    "EventLightFundraising",
    "EventLightRegistration",
    "EventLightContent",
    "EventLightExhibitors",
    "EventLightReports",
    "EventLightTeam",
    "EventLightAgenda",
    "EventLightRecordings",
    "EventLightAlerts",
    "EventLightBooths",
    "EventLightRooms",
    "EventLightAnalytics",
    "EventLightExpo",
    "EventLightSponsors",
    "EventLightIntegrations",
    "EventLightSendEmail",
    "EventLightSettings",
    "EventLightQuickAdd",
    "EventLightAddToCart",
    "EventLightCheckinAttendees",
    "EventLightHelp",
  ],
);

const Account = makeChunk(
  () => import(/* webpackChunkName: "account" */ "./Account"),
  ["SettingsSettingsHandler", "SettingsHandler"],
);

export default (
  <Route component={CoreLayout}>
    <Route
      name="SignupVerificationConfirm"
      path="/account/confirm/:verificationHash"
      getComponent={Auth.SignupVerificationConfirm}
    />
    <Route
      name="SignupComplete"
      path="/signup/complete"
      getComponent={Auth.SignupComplete}
    />
    <Route
      name="SignupResend"
      path="/signup/resend"
      getComponent={Auth.SignupResend}
    />
    <Route name="Signup" path="/signup" getComponent={Auth.SignupStart} />
    <Route
      name="SignupVerificationOnboard"
      path="/account/onboard"
      getComponent={Auth.SignupOnboard}
    />
    <Route
      name="NoAccessView"
      path="/no-access"
      getComponent={Auth.NoAccessView}
    />
    <Route
      name="SessionIssueView"
      path="/session-issues"
      getComponent={Auth.SessionIssueView}
    />
    <Route name="LoggedOutView" getComponent={Auth.LoggedOutView}>
      <Route name="LoginView" getComponent={Auth.LoginView} path="/" />
      <Route
        name="LoginView"
        getComponent={Auth.LoginView}
        path="/auth/login"
      />
      <Route name="LoginView" getComponent={Auth.LoginView} path="/login" />
      <Route name="LogoutView" getComponent={Auth.LogoutView} path="/logout" />
      <Route
        name="LogoutCallbackView"
        getComponent={Auth.LogoutCallbackView}
        path="/logout/callback"
      />
      <Route
        name="CallbackView"
        getComponent={Auth.CallbackView}
        path="/auth/callback"
      />
      <Route
        name="forgotPassword"
        path="/forgot"
        getComponent={Auth.ForgotPasswordHandler}
      />
      <Route
        name="joinVirtualUser"
        path="/join/vu/:virtualUserHash"
        getComponent={Auth.JoinVirtualUserHandler}
      />
      {/* The single order invoice is using a different redux pattern than bulk invoice, so it will remain nested in this LoggedOutView until we upgrade its redux pattern. */}
      <Route
        name="Invoice"
        path="/invoice/:invoiceId"
        getComponent={Invoices.InvoiceHandler}
      />
      <Route
        name="BulkInvoicePublic"
        path="/event/:eventId/batch-invoice/:batchId"
        getComponent={Invoices.BulkInvoice}
      />
    </Route>
    <Route
      name="invoiceGate"
      path="/event/:eventId"
      getComponent={Invoices.InvoiceGate}
    >
      <Route
        name="BulkInvoice"
        path="admin/batch-invoice/:batchId"
        getComponent={Invoices.BulkInvoice}
      />
    </Route>

    {/* @NOTE: We are using this route for `attend-login` with params in the route */}
    <Route
      name="AttendLogin"
      path="/attend-login/:eventUUID/:auth/:code"
      getComponent={Attend.AttendLogin}
    />

    {/* @NOTE: We have deprecated this route and are using the route above for `attend-login` */}
    <Route
      name="AttendLogin"
      path="/attend-login/:eventSlug/:eventUUID"
      getComponent={Attend.AttendLogin}
    />

    <Route
      name="AttendLoginCallback"
      path="/attend-login-callback"
      getComponent={Attend.AttendLogin}
    />
    <Route
      name="attendDonate"
      path="/donate/:donationId"
      getComponent={Attend.AttendDonate}
    />
    <Route
      name="publicLoginCallback"
      path="/public-login-callback"
      getComponent={Attend.AttendAuctionPageWrapper}
    />
    <Route
      name="publicAuctionPage"
      path="/auction"
      getComponent={Attend.AttendAuctionPageWrapper}
    >
      <Route
        name="attendAuction"
        path="/auction/:eventSlug/:eventUUID"
        getComponent={Attend.AttendAuction}
      />
      <Route
        name="attendAuctionItem"
        path="/auction/:eventSlug/:eventUUID/item/:itemId"
        getComponent={Attend.AttendAuctionItem}
      />
      <Route
        name="attendAuctionCheckout"
        path="/auction/:eventSlug/:eventUUID/checkout"
        getComponent={Attend.AttendAuctionCheckout}
      />
      <Route
        name="attendAuctionRules"
        path="/auction/:eventSlug/:eventUUID/rules"
        getComponent={Attend.AttendAuctionRules}
      />
      <Route
        name="attendAuctionDonate"
        path="/auction/:eventSlug/:eventUUID/donate"
        getComponent={Attend.AttendAuctionDonate}
      />
    </Route>
    <Route
      name="attendDisplay"
      path="/display/:displayId"
      getComponent={Attend.AttendDisplay}
    />
    <Route
      name="attendRegisterWrapper"
      path="/register"
      getComponent={Attend.AttendAuctionPageWrapper}
    >
      <Route
        name="attendMyProfile"
        path="/register/:eventSlug/:eventUUID/profile"
        getComponent={Attend.AttendMyProfile}
      />
      <Route
        name="attendeeManageGroupPage"
        path="/register/:eventSlug/:eventUUID/group/:allocationId"
        getComponent={Attend.AttendAssignTickets}
      />
      <Route
        name="attendRegister"
        path="/register/:eventSlug/:eventUUID"
        getComponent={Attend.AttendRegister}
      />
      <Route
        name="attendRegisterCallback"
        path="/register-callback"
        getComponent={Attend.AttendRegister}
      />
    </Route>
    <Route
      name="attendGate"
      path="/attend/:eventSlug/:eventUUID"
      getComponent={Attend.AttendGate}
    >
      <IndexRedirect to="lobby" />
      <Route
        name="attendOnboard"
        path="onboard(/:step)"
        getComponent={Attend.AttendOnboard}
      />
      <Route
        name="AttendLobby"
        path="lobby(/alerts/:alertId)(/alerts)(/attendees)(/expo)(/videos)(/overview-and-resources)(/sections/:sectionId)"
        getComponent={Attend.AttendLobby}
      />
      <Route
        name="AttendAttendees"
        path="attendees"
        getComponent={Attend.AttendAttendees}
      />
      <Route
        name="AttendStage"
        path="stage/:stageId"
        getComponent={Attend.AttendStage}
      />
      <Route
        path="stage/:stageId/backstage"
        getComponent={Attend.AttendBackstage}
      />
      <Route
        name="AttendRoom"
        path="rooms/:roomId"
        getComponent={Attend.AttendRooms}
      />
      <Route
        name="AttendRooms"
        path="rooms"
        getComponent={Attend.AttendRooms}
      />

      <Route
        name="AttendNetworking"
        path="networking"
        getComponent={Attend.AttendNetworking}
      />
      <Route
        name="AttendBooths"
        path="expo"
        getComponent={Attend.AttendBooths}
      />
      <Route
        name="AttendBooth"
        path="booth/:boothId"
        getComponent={Attend.AttendBooth}
      />
      <Route
        name="AttendAgenda"
        path="agenda"
        getComponent={Attend.AttendAgenda}
      />
      <Route
        name="AttendSponsors"
        path="sponsors"
        getComponent={Attend.AttendSponsors}
      />
      <Route
        name="AttendSpeakers"
        path="speakers"
        getComponent={Attend.AttendSpeakers}
      />
      <Route
        name="AttendAttendeeProfile"
        path="attendee/:attendeeId"
        getComponent={Attend.AttendeAttendeeProfile}
      />
      <Route
        name="AttendAlerts"
        path="alerts"
        getComponent={Attend.AlertsList}
      />
      <Route
        name="AttendAlertDetails"
        path="alerts/:alertId"
        getComponent={Attend.AlertDetails}
      />
      <Route
        name="AttendContent"
        path="content"
        getComponent={Attend.AttendContent}
      />
    </Route>
    <Route
      name="OrgLightGate"
      path="/org-light/:orgId"
      getComponent={OrgLight.OrgLightGate}
    >
      <Route
        name="OrgLightDashboard"
        path="dashboard"
        getComponent={OrgLight.OrgLightDashboard}
      />
      <Route
        name="OrgLightDetails"
        path="details"
        getComponent={OrgLight.OrgLightDetails}
      />

      <Route
        name="OrgLightEvents"
        path="events"
        getComponent={OrgLight.OrgLightEvents}
      />
      <Route
        name="OrgLightBilling"
        path="billing"
        getComponent={OrgLight.OrgLightBilling}
      />
      <Route
        name="OrgLightTeam"
        path="team"
        getComponent={OrgLight.OrgLightTeam}
      />

      <Route
        name="OrgLightIntegrations"
        path="integrations"
        getComponent={OrgLight.OrgLightIntegrations}
      />
    </Route>
    <Route
      name="AdminAddClassyAdmin"
      path="/admin/event/:eventId/add-classy-admin"
      getComponent={Admin.AddClassyAdmin}
    />
    <Route
      name="AdminAddClassyAdmin"
      path="/admin/organization/:orgId/add-classy-admin"
      getComponent={Admin.AddClassyAdmin}
    />
    <Route
      name="ClassyToClassyLive"
      path="/campaign-redirect/:campaignId"
      getComponent={ClassyToClassyLive.AddClassyCampaign}
    />
    <Route
      name="ClassyOrgRedirect"
      path="/org-redirect/:classyOrgId"
      getComponent={ClassyToClassyLive.ClassyOrgRedirect}
    />
    <Route
      name="EventLightCheckinAttendees"
      path="/event-light/:eventId/checkin"
      getComponent={EventLight.EventLightCheckinAttendees}
    />
    <Route
      name="eventLightGate"
      path="/event-light/:eventId"
      getComponent={EventLight.EventLightGate}
    >
      <Route
        name="EventLightQuickAdd"
        path="quick-add"
        getComponent={EventLight.EventLightQuickAdd}
      />
      <Route
        name="EventLightQuickAdd"
        path="add-to-cart"
        getComponent={EventLight.EventLightAddToCart}
      />
      <Route
        name="EventLightDashboard"
        path="dashboard"
        getComponent={EventLight.EventLightDashboard}
      />
      <Route
        name="EventLightDetails"
        path="details"
        getComponent={EventLight.EventLightDetails}
      />
      <Route
        name="EventLightAnalyticsSummary"
        path="analytics"
        getComponent={EventLight.EventLightAnalytics}
      />
      <Route
        name="EventLightVenue"
        path="venue"
        getComponent={EventLight.EventLightVenue}
      />
      <Route
        name="EventLightStage"
        path="stage"
        getComponent={EventLight.EventLightStage}
      />

      {/* */}

      <Route
        name="EventLightExpoExhibitors"
        path="expo/exhibitors"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read",
        )}
      />

      {/* */}

      <Route
        name="EventLightSponsors"
        path="sponsors"
        getComponent={canUserView(
          EventLight.EventLightSponsors,
          STANDARD_MODULES.booths.id,
          "read",
        )}
      />
      <Route
        name="EventLightSponsorsSettingsSponsorships"
        path="sponsors/settings"
        getComponent={canUserView(
          EventLight.EventLightSponsors,
          STANDARD_MODULES.booths.id,
          "manage",
        )}
      />

      {/* */}

      <Route
        name="EventLightBiddingDashboard"
        path="auction/dashboard"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingBidders"
        path="auction/bidders"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingItems"
        path="auction/items"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingActivity"
        path="auction/activity"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingTransactions"
        path="auction/transactions"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingDisplays"
        path="auction/displays"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingSettings"
        path="auction/settings"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingSettingsCategories"
        path="auction/settings/categories"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingSettingsDevices"
        path="auction/settings/devices"
        getComponent={EventLight.EventLightBidding}
      />
      <Route
        name="EventLightBiddingSettingsDonate"
        path="auction/settings/donate"
        getComponent={EventLight.EventLightBidding}
      />

      {/* */}

      <Route
        name="EventLightTextDashboard"
        path="text-to-donate/dashboard"
        getComponent={EventLight.EventLightText}
      />
      <Route
        name="EventLightTextManage"
        path="text-to-donate/manage"
        getComponent={EventLight.EventLightText}
      />
      <Route
        name="EventLightTextSettings"
        path="text-to-donate/settings"
        getComponent={EventLight.EventLightText}
      />

      {/* */}

      <Route
        name="EventLightSeatingManage"
        path="seating/manage"
        getComponent={EventLight.EventLightSeating}
      />
      <Route
        name="EventLightSeatingSettings"
        path="seating/settings"
        getComponent={EventLight.EventLightSeating}
      />

      {/* */}

      <Route
        name="EventLightAttendees"
        path="attendees"
        getComponent={EventLight.EventLightAttendees}
      />
      <Route
        name="EventLightAttendeesGroups"
        path="attendees/groups"
        getComponent={EventLight.EventLightAttendees}
      />
      <Route
        name="EventLightAttendeesSettings"
        path="attendees/groups/settings"
        getComponent={EventLight.EventLightAttendees}
      />
      <Route
        name="EventLightAttendeesGroup"
        path="attendees/groups/:transactionId"
        getComponent={EventLight.EventLightAttendees}
      />
      <Route
        name="EventLightAttendeesAttendee"
        path="attendees/attendees/:registrationId"
        getComponent={EventLight.EventLightAttendees}
      />

      <Route
        name="EventLightNetworking"
        path="networking"
        getComponent={EventLight.EventLighNetworking}
      />
      <Route
        name="EventLightFundraising"
        path="fundraising"
        getComponent={EventLight.EventLightFundraising}
      />
      <Route
        name="EventLightRegistration"
        path="registration"
        getComponent={EventLight.EventLightRegistration}
      />
      <Route
        name="EventLightContent"
        path="content"
        getComponent={EventLight.EventLightContent}
      />
      <Route
        name="EventLightContentTags"
        path="content/tags"
        getComponent={EventLight.EventLightContent}
      />
      <Route
        name="EventLightRecordings"
        path="recordings"
        getComponent={EventLight.EventLightRecordings}
      />
      <Route
        name="EventLightRooms"
        path="rooms"
        getComponent={EventLight.EventLightRooms}
      />
      <Route
        name="EventLightHelp"
        path="Help"
        getComponent={EventLight.EventLightHelp}
      />
      <Route
        name="EventLightReports"
        path="Reports"
        getComponent={EventLight.EventLightReports}
      />
      <Route
        name="EventLightTeam"
        path="team"
        getComponent={canUserView(
          EventLight.EventLightTeam,
          STANDARD_MODULES.settings.id,
          "manage_users",
        )}
      />

      {/* */}
      <Route
        name="EventLightAgenda"
        path="agenda"
        getComponent={EventLight.EventLightAgenda}
      />
      <Route
        name="EventLightAgendaSpeakers"
        path="agenda/speakers"
        getComponent={EventLight.EventLightAgenda}
      />
      <Route
        name="EventLightAgendaTags"
        path="agenda/tags"
        getComponent={EventLight.EventLightAgenda}
      />

      {/* */}

      <Route
        name="EventLightAlerts"
        path="alerts"
        getComponent={EventLight.EventLightAlerts}
      />

      <Route
        name="EventLightBoothsDashboard"
        path="booths"
        getComponent={canUserView(
          EventLight.EventLightBooths,
          STANDARD_MODULES.booths.id,
          "read",
        )}
      />

      {/* */}

      <Route
        name="EventLightSettingsEmailTemplates"
        path="settings/email-templates"
        getComponent={canUserView(
          EventLight.EventLightSettings,
          STANDARD_MODULES.settings.id,
          "manage_email_templates",
        )}
      />

      <Route
        name="EventLightSettingsReceiptSettings"
        path="settings/receipt-settings"
        getComponent={EventLight.EventLightSettings}
      />

      <Route
        name="EventLightSettingsInvoiceSettings"
        path="settings/invoice-settings"
        getComponent={EventLight.EventLightSettings}
      />
    </Route>
    <Route name="PlatformView" getComponent={Platform.PlatformView}>
      <Route name="HomeView" getComponent={Platform.HomeView} path="/home" />
      <Route
        name="SuperAdminView"
        getComponent={Platform.SuperAdminView}
        path="/super-admin"
      />
      <Route
        name="SuperAdminViewFeatureFlags"
        getComponent={Platform.SuperAdminViewFeatureFlags}
        path="/super-admin/feature-flags"
      />
      <Route
        name="SuperAdminFeatureFlagView"
        getComponent={Platform.SuperAdminFeatureFlagView}
        path="/super-admin/feature-flags/:name"
      />

      <Route name="accountSettings" getComponent={Account.SettingsHandler}>
        <Route
          name="accountSettingsSettings"
          path="/account/settings"
          getComponent={Account.SettingsSettingsHandler}
        />
      </Route>

      <Route name="platformNotFound" path="*" component={DefaultNotFound} />
    </Route>
  </Route>
);
