const ns = "EVENT_PERMISSION_ROLES";
export const UPDATE_ROLE_PERMISSIONS = `${ns}_UPDATE_ROLE_PERMISSIONS`;
export const ADD_OR_UPDATE_ROLE = `${ns}_ADD_OR_UPDATE_ROLE`;
export const DELETE_ROLE = `${ns}_DELETE_ROLE`;
export const RECEIVE_ROLES = `${ns}_RECEIVE_ROLES`;
export const FETCH_RECEIVE = `${ns}_FETCH_RECEIVE`;
export const FETCH_REQUEST = `${ns}_FETCH_REQUEST`;
export const SAVE_RECEIVE = `${ns}_SAVE_RECEIVE`;
export const SAVE_REQUEST = `${ns}_SAVE_REQUEST`;
export const CLEAR_LAST_SAVE = `${ns}_CLEAR_LAST_SAVE`;
export const ERROR = `${ns}_ERROR`;
export const CLEAR_ERROR = `${ns}_CLEAR_ERROR`;
