import React from "react";
import Select from "react-select";
import TetherComponent from "react-tether";
import * as R from "ramda";

// This component allows the react select component to render correctly within a modal.
// Turns the dropdown into a portal that renders outside of the modal.

/** from https://github.com/JedWatson/react-select/issues/810#issuecomment-250274937 * */
export default class TetheredSelectWrap extends Select {
  constructor(props) {
    super(props);

    this.renderOuter = this.renderOuterCustom;
    this.handleTouchOutside = this.customHandleTouchOutside.bind(this);
  }

  customHandleTouchOutside({ target }) {
    // event from react-select
    if (this.props.noTether) {
      // from react-select v1.3 definition
      if (this.wrapper && !this.wrapper.contains(target)) {
        this.closeMenu();
      }
    } else {
      const tetheredOptions =
        (R.path(["refs", "tethered-component"], this) &&
          this.refs["tethered-component"].getTetherInstance()) ||
        {};
      const tetheredHtmlElement = R.prop("element", tetheredOptions);
      if (tetheredHtmlElement && !tetheredHtmlElement.contains(target)) {
        this.closeMenu();
      }
    }
  }
  /* eslint-disable consistent-return */
  renderOuterCustom() {
    // event from react-select
    const menu = super.renderOuter.apply(this, arguments);

    // Don't return an updated menu render if we don't have one
    if (!menu) {
      return;
    }

    // LENND noTether prop
    if (this.props.noTether) return menu;
    /** this.wrapper comes from the ref of the main Select component (super.render()) * */
    const selectWidth = this.wrapper ? this.wrapper.offsetWidth : null;
    return (
      <TetherComponent
        classPrefix="portal-tether" /* LENND */
        renderElementTo="body"
        ref="tethered-component"
        attachment="top left"
        targetAttachment="top left"
        constraints={[
          {
            to: "window",
            attachment: "together",
            pin: ["top"],
          },
        ]}
      >
        {/* Apply position: static to our menu so that it's parent will get the correct dimensions and we can tether the parent */}
        <div />
        {React.cloneElement(menu, {
          style: { position: "static", width: selectWidth },
        })}
      </TetherComponent>
    );
  }
}
