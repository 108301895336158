import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";

import {
  Tooltip as BootstrapTooltip,
  OverlayTrigger as BootstrapOverlayTrigger,
} from "react-bootstrap";

class Tooltip extends Component {
  render() {
    const { enableTouchDevice } = this.props;
    // disable tooltip for touch devices
    // prevents forced doubleclick issue when tooltip child has an onClick handler.
    if (!enableTouchDevice && Helpers.isTouchDevice()) {
      return <div>{this.props.children}</div>;
    }

    return (
      <BootstrapOverlayTrigger
        placement={this.props.placement}
        overlay={
          <BootstrapTooltip
            className={this.props.className}
            id={this.props.key}
          >
            {this.props.tooltip}
          </BootstrapTooltip>
        }
      >
        {this.props.children}
      </BootstrapOverlayTrigger>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
  key: PropTypes.string,
};

Tooltip.defaultProps = {
  key: "tip",
  placement: "bottom",
};

export default Tooltip;
