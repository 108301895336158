import { combineReducers } from "redux";
import { RECEIVE, REQUEST, INVALIDATE } from "./constants";

const form = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.form.form;
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  form,
  fetching,
});
