import React from "react";
import { Div, FontIcon, Text2 } from "../../index";
import { RightArrowCard } from "./RightArrowCard";

const Type2Card = ({ heading, icon, iconColor, onClick }) => (
  <RightArrowCard pl={3} py={2} onClick={onClick}>
    <Div display="row.flex-start.center">
      <FontIcon icon={icon} color={iconColor} flex={0} />
      <Text2 bold pl={1} flex={1}>
        {heading}
      </Text2>
    </Div>
  </RightArrowCard>
);

export { Type2Card };
