const ns = "FORMS_V2_FORM_FIELDS";
export const CREATE = `${ns}_CREATE`;
export const CREATE_COMPLETE = `${ns}_CREATE_COMPLETE`;
export const UPDATE = `${ns}_UPDATE`;
export const UPDATE_COMPLETE = `${ns}_UPDATE_COMPLETE`;
export const BULK_UPDATE = `${ns}_BULK_UPDATE`;
export const BULK_UPDATE_COMPLETE = `${ns}_BULK_UPDATE_COMPLETE`;
export const DELETE = `${ns}_DELETE`;
export const DELETE_COMPLETE = `${ns}_DELETE_COMPLETE`;
export const ERROR = `${ns}_ERROR`;
export const CLEAR_ERROR = `${ns}_CLEAR_ERROR`;
export const REORDER = `${ns}_REORDER`;
export const DROP_AND_REORDER = `${ns}_DROP_AND_REORDER`;
export const START_DRAGGING_SIDEBAR_FIELD = `${ns}_START_DRAGGING_SIDEBAR_FIELD`;
export const STOP_DRAGGING_SIDEBAR_FIELD = `${ns}_STOP_DRAGGING_SIDEBAR_FIELD`;
export const TOGGLE_EDIT_FIELD_DESCRIPTION = `${ns}_TOGGLE_EDIT_FIELD_DESCRIPTION`;
export const SELECT_FORM_FIELD = `${ns}_SELECT_FORM_FIELD`;
export const DESELECT_FORM_FIELD = `${ns}_DESELECT_FORM_FIELD`;

export const SUBFORM_CREATE = `${ns}_SUBFORM_CREATE`;
export const SUBFORM_CREATE_COMPLETE = `${ns}_SUBFORM_CREATE_COMPLETE`;
export const SUBFORM_UPDATE = `${ns}_SUBFORM_UPDATE`;
export const SUBFORM_UPDATE_COMPLETE = `${ns}_SUBFORM_UPDATE_COMPLETE`;
export const SUBFORM_UPDATE_WIDTH = `${ns}_SUBFORM_UPDATE_WIDTH`;
export const SUBFORM_BULK_UPDATE = `${ns}_SUBFORM_BULK_UPDATE`;
export const SUBFORM_BULK_UPDATE_COMPLETE = `${ns}_SUBFORM_BULK_UPDATE_COMPLETE`;
export const SUBFORM_DELETE = `${ns}_SUBFORM_DELETE`;
export const SUBFORM_DELETE_COMPLETE = `${ns}_SUBFORM_DELETE_COMPLETE`;
export const SUBFROM_REORDER = `${ns}_SUBFORM_REORDER`;
