import React from "react";
import { Div, EditIcon, FontIcon, DragIcon, Text2, Text1 } from "../../index";

const Type11Card = ({
  isDragging,
  onEdit,
  name,
  subname,
  iconColor = "gray5",
  icon,
}) => (
  <Div
    className="hoverContainer"
    my={2}
    p={2}
    bra={1}
    bg="white"
    shadow={{ default: 1, hover: 2 }}
    display="row.flex-start.center"
    style={{ userSelect: "none", opacity: isDragging ? 0.2 : 1 }}
  >
    {icon && (
      <FontIcon mr={3} color={iconColor}>
        {icon}
      </FontIcon>
    )}
    <Div display="column" flex={1}>
      <Text2 bold flex={1}>
        {name}
      </Text2>
      {subname ? <Text1 color="gray5">{subname}</Text1> : null}
    </Div>
    {onEdit ? (
      <Div px={1} className="showOnHover" onClick={onEdit}>
        <EditIcon sizeWFS={2} color="neutral8" />
      </Div>
    ) : undefined}

    <DragIcon size={20} flex={0} />
  </Div>
);

export { Type11Card };
