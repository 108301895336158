import { ADD_REFERENCE, ADD_REFERENCES } from "./constants";
import Immutable from "immutable";

const mergeState = (a, b) => Immutable.fromJS(a).mergeDeep(b).toJS();

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_REFERENCES:
      // Expects an object with keys of reference types `{ contact: {} }`
      return mergeState(state, action.payload);
    case ADD_REFERENCE:
      // Expects an object with keys of references `{ '123': {} }`
      return mergeState(state, {
        [action.payload.type]: { records: action.payload.reference },
      });
    default:
      return state;
  }
};
