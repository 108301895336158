import React from "react";
import { Div, FontIcon } from "../../index";

const RightArrowCard = ({ children, ...props }) => (
  <Div
    bra={1}
    bg="white"
    display="row.space-between.center"
    shadow={{ default: 1, hover: 2 }}
    pl={4}
    pr={2}
    py={3}
    my={2}
    {...props}
  >
    <Div flex={1}>{children}</Div>
    <FontIcon flex={0}>keyboard_arrow_right</FontIcon>
  </Div>
);

export { RightArrowCard };
