import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Loading from "components/Global/Loading";

@CSSModules(css)
export default class PlatformLoading extends Component {
  render() {
    return (
      <div styleName="loadingWrapper">
        <Loading content={this.props.message || "Loading..."} />
      </div>
    );
  }
}
