/**
 *
 * @param {object} param
 * @param {boolean} param.publicApi
 * @param {boolean} param.isIdp
 * @param {Window} param.windowRef
 *
 * @returns {string}
 */
export function getBaseApiUrl({ publicApi, isIdp, windowRef }) {
  // okta authenticated users issue requests through the Okta proxy
  // to Classy Live
  if (!publicApi && isIdp) {
    return windowRef.__CLASSY_LIVE_OKTA_PROXY_API_URL__;
  }

  // Non-public and non-idp requests go through the API
  // behind Kong
  if (!publicApi && !isIdp) {
    return windowRef.__LIVE_API_URL__;
  }

  // all other requets are public and go to the api publically exposed
  return windowRef.__EXTERNAL_API_URL__;
}
