const ns = "CREDENTIAL_ORDERS";
export const RECEIVE = `${ns}_RECEIVE`;
export const RECEIVE_APPEND = `${ns}_RECEIVE_APPEND`;
export const REQUEST = `${ns}_REQUEST`;
export const CREATE = `${ns}_UPDATE`;
export const CREATE_COMPLETE = `${ns}_CREATE_COMPLETE`;
export const UPDATE_META = `${ns}_UPDATE_META`;
export const INVALIDATE = `${ns}_INVALIDATE`;
export const SYNC = `${ns}_SYNC`;
export const SYNC_COMPLETE = `${ns}_SYNC_COMPLETE`;
