import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import {
  SHOW as SHOW_RELATED_MODULE,
  HIDE as HIDE_RELATED_MODULE,
  UPDATE as UPDATE_RELATED_MODULES,
} from "redux/modules/modules/relatedModules/constants";
import {
  SHOW as SHOW_STARRED_FIELD,
  HIDE as HIDE_STARRED_FIELD,
  UPDATE as UPDATE_STARRED_FIELDS,
} from "redux/modules/modules/starredFields/constants";
import {
  SHOW as SHOW_VISIBLE_FIELD,
  HIDE as HIDE_VISIBLE_FIELD,
} from "redux/modules/modules/visibleFields/constants";
import { ERROR } from "redux/modules/errors/constants";

const layout = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], action.payload.layout)
        .toJS();
    case SHOW_RELATED_MODULE: {
      const indexOfRelatedModule = state[
        action.payload.moduleId
      ].related_modules.findIndex(
        (m) =>
          m.id === action.payload.relatedModuleId &&
          m.lookup_field.id === action.payload.fieldId,
      );
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.moduleId,
            "related_modules",
            indexOfRelatedModule,
            "is_visible",
          ],
          true,
        )
        .setIn(
          [
            action.payload.moduleId,
            "related_modules",
            indexOfRelatedModule,
            "order",
          ],
          action.payload.order,
        )
        .toJS();
    }
    case HIDE_RELATED_MODULE: {
      const indexOfRelatedModule = state[
        action.payload.moduleId
      ].related_modules.findIndex(
        (m) =>
          m.id === action.payload.relatedModuleId &&
          m.lookup_field.id === action.payload.fieldId,
      );
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.moduleId,
            "related_modules",
            indexOfRelatedModule,
            "is_visible",
          ],
          false,
        )
        .toJS();
    }
    case UPDATE_RELATED_MODULES: {
      const updateMap = action.payload.relatedModules.reduce((a, b) => {
        a[`${b.id}_${b.fieldId}`] = b;
        return a;
      }, {});
      const map = state[action.payload.moduleId].related_modules.map(
        (relatedModule) => ({
          ...relatedModule,
          order:
            updateMap[`${relatedModule.id}_${relatedModule.lookup_field.id}`] &&
            typeof updateMap[
              `${relatedModule.id}_${relatedModule.lookup_field.id}`
            ].order !== "undefined"
              ? updateMap[
                  `${relatedModule.id}_${relatedModule.lookup_field.id}`
                ].order
              : relatedModule.order,
          sort_by:
            updateMap[`${relatedModule.id}_${relatedModule.lookup_field.id}`] &&
            updateMap[`${relatedModule.id}_${relatedModule.lookup_field.id}`]
              .sortBy
              ? updateMap[
                  `${relatedModule.id}_${relatedModule.lookup_field.id}`
                ].sortBy
              : relatedModule.sort_by,
        }),
      );

      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId, "related_modules"], map)
        .toJS();
    }
    case SHOW_STARRED_FIELD: {
      // const indexOfStarredField = state[action.payload.moduleId].starred_fields.findIndex(
      //  f => f.field_id === action.payload.fieldId
      // );
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.moduleId, "starred_fields"],
          [
            ...state[action.payload.moduleId].starred_fields,
            {
              module_id: action.payload.moduleId,
              layout_id: action.payload.layoutId,
              field_id: action.payload.field.id,
              order: action.payload.order,
              field: action.payload.field,
            },
          ],
        )
        .toJS();
    }
    case HIDE_STARRED_FIELD: {
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.moduleId, "starred_fields"],
          state[action.payload.moduleId].starred_fields.filter(
            (f) => f.field_id !== action.payload.fieldId,
          ),
        )
        .toJS();
    }
    case UPDATE_STARRED_FIELDS: {
      const updateMap = action.payload.starredFields.reduce((a, b) => {
        a[b.fieldId] = b;
        return a;
      }, {});
      const map = state[action.payload.moduleId].starred_fields.map(
        (starredField) => ({
          ...starredField,
          order: updateMap[starredField.field_id]
            ? updateMap[starredField.field_id].order
            : starredField.order,
        }),
      );

      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId, "starred_fields"], map)
        .toJS();
    }
    case SHOW_VISIBLE_FIELD:
    case HIDE_VISIBLE_FIELD: {
      const indexOfFieldGroup = state[
        action.payload.moduleId
      ].field_groups.findIndex((g) => g.id === action.payload.fieldGroupId);
      const indexOfField = state[action.payload.moduleId].field_groups[
        indexOfFieldGroup
      ].fields.findIndex((f) => f.field_id === action.payload.fieldId);
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.moduleId,
            "field_groups",
            indexOfFieldGroup,
            "fields",
            indexOfField,
            "field",
            "visible",
          ],
          action.type === SHOW_VISIBLE_FIELD,
        )
        .toJS();
    }
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
      return false;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  layout,
  fetching,
});
