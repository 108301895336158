import React from "react";
import { Div, Text2, FontIcon, NullFontIcon } from "../../index";

const IconLabel = ({ text, icon, ...props }) => {
  return (
    <Div display={"row.flex-start.center"} {...props}>
      {icon ? <FontIcon icon={icon} size={20} /> : <NullFontIcon size={20} />}
      <Div ml={2} display={"row.flex-start.center"}>
        {text && <Text2 bold>{text}</Text2>}
      </Div>
    </Div>
  );
};

export { IconLabel };
