import React from "react";
import * as R from "ramda";
import { SVG } from "../Base/index";

const materialIconPaths = {
  add: (
    <path d="M18.984 12.984h-6v6h-1.969v-6h-6v-1.969h6v-6h1.969v6h6v1.969z" />
  ),
  add_box: [
    <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />,
    <path d="M0 0h24v24H0z" fill="none" />,
  ],
  add_a_photo: (
    <path d="M9.797 14.016c0-1.781 1.406-3.234 3.188-3.234s3.234 1.453 3.234 3.234-1.453 3.188-3.234 3.188-3.188-1.406-3.188-3.188zm3.187 4.968c2.766 0 5.016-2.203 5.016-4.969s-2.25-5.016-5.016-5.016-4.969 2.25-4.969 5.016 2.203 4.969 4.969 4.969zM6 9.984v-3h3v-3h6.984L17.812 6H21c1.078 0 2.016.938 2.016 2.016v12c0 1.078-.938 1.969-2.016 1.969H5.016C3.938 21.985 3 21.094 3 20.016V9.985h3zm-3-6v-3h2.016v3h3V6h-3v3H3V6H0V3.984h3z" />
  ),
  airport_shuttle: (
    <path d="M15 11.016h5.016l-4.031-4.031h-.984v4.031zm2.484 6.468c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-4.5-6.468V6.985H9v4.031h3.984zM6 17.484c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-3-6.468h3.984V6.985H3v4.031zm14.016-6l6 6v4.969h-2.531c0 1.641-1.359 3-3 3s-3-1.359-3-3H9.001c0 1.641-1.359 3-3 3s-3-1.359-3-3H.985v-9c0-1.125.938-1.969 2.016-1.969h14.016z" />
  ),
  alarm: (
    <path d="M12 20.016c3.891 0 6.984-3.141 6.984-7.031S15.89 6.001 12 6.001s-6.984 3.094-6.984 6.984S8.11 20.016 12 20.016zm0-16.032c4.969 0 9 4.031 9 9s-4.031 9-9 9-9-4.031-9-9 4.031-9 9-9zm.516 4.032v5.25L16.5 15.61l-.75 1.219-4.734-2.813v-6h1.5zM7.875 3.375L3.281 7.219l-1.266-1.5 4.594-3.844zm14.109 2.344l-1.266 1.547-4.594-3.891 1.266-1.5z" />
  ),
  arrow_back: (
    <path d="M20.016 11.016v1.969H7.828l5.578 5.625L12 20.016 3.984 12 12 3.984l1.406 1.406-5.578 5.625h12.188z" />
  ),
  arrow_downward: (
    <path d="M20.016 12L12 20.016 3.984 12l1.453-1.406 5.578 5.578V3.984h1.969v12.188l5.625-5.578z" />
  ),
  arrow_drop_down: <path d="M6.984 9.984h10.031L11.999 15z" />,
  arrow_drop_up: <path d="M6.984 14.016L12 9l5.016 5.016H6.985z" />,
  arrow_forward: (
    <path d="M12 3.984L20.016 12 12 20.016l-1.406-1.406 5.578-5.625H3.984v-1.969h12.188l-5.578-5.625z" />
  ),
  arrow_upward: (
    <path d="M3.984 12L12 3.984 20.016 12l-1.453 1.406-5.578-5.578v12.188h-1.969V7.828l-5.625 5.578z" />
  ),
  assignment: (
    <path d="M17.016 9V6.984H6.985V9h10.031zm0 3.984v-1.969H6.985v1.969h10.031zm-3 4.032V15H6.985v2.016h7.031zM12 3c-.563 0-.984.422-.984.984s.422 1.031.984 1.031.984-.469.984-1.031S12.562 3 12 3zm6.984 0C20.062 3 21 3.938 21 5.016v13.969c0 1.078-.938 2.016-2.016 2.016H5.015c-1.078 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.937 3 5.015 3h4.172C9.609 1.828 10.687.984 12 .984s2.391.844 2.813 2.016h4.172z" />
  ),
  assignment_turned_in: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
    </g>
  ),
  assignment_ind: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z" />
    </g>
  ),
  attach_file: (
    <path d="M16.5 6H18v11.484c0 3.047-2.438 5.531-5.484 5.531s-5.531-2.484-5.531-5.531V5.015c0-2.203 1.828-4.031 4.031-4.031S15 2.812 15 5.015v10.5c0 1.359-1.125 2.484-2.484 2.484s-2.531-1.125-2.531-2.484V5.999h1.5v9.516c0 .563.469.984 1.031.984s.984-.422.984-.984v-10.5c0-1.359-1.125-2.531-2.484-2.531S8.485 3.656 8.485 5.015v12.469c0 2.203 1.828 4.031 4.031 4.031s3.984-1.828 3.984-4.031V6z" />
  ),
  attach_money: (
    <path d="M11.813 10.922c2.25.609 4.688 1.5 4.688 4.359 0 2.063-1.547 3.188-3.516 3.563V21h-3v-2.156C8.063 18.422 6.469 17.203 6.329 15h2.203c.094 1.172.891 2.109 2.953 2.109 2.203 0 2.719-1.078 2.719-1.781 0-.938-.516-1.828-3-2.438-2.813-.656-4.688-1.828-4.688-4.125 0-1.922 1.547-3.188 3.469-3.609V3h3v2.203c2.109.516 3.188 2.063 3.234 3.797h-2.203c-.047-1.266-.75-2.109-2.531-2.109-1.688 0-2.672.797-2.672 1.875 0 .938.75 1.547 3 2.156z" />
  ),
  attachment: (
    <path d="M2.016 12.516c0-3.047 2.438-5.531 5.484-5.531H18c2.203 0 3.984 1.828 3.984 4.031S20.203 15 18 15H9.516c-1.359 0-2.531-1.125-2.531-2.484s1.172-2.531 2.531-2.531h7.5v2.016H9.422c-.563 0-.563.984 0 .984H18c1.078 0 2.016-.891 2.016-1.969S19.078 9 18 9H7.5c-1.922 0-3.516 1.594-3.516 3.516s1.594 3.469 3.516 3.469h9.516v2.016H7.5a5.461 5.461 0 0 1-5.484-5.484z" />
  ),
  audiotrack: (
    <path d="M12 3h6.984v3H15v11.016h-.047C14.719 19.266 12.797 21 10.5 21 8.016 21 6 18.984 6 16.5S8.016 12 10.5 12a4.07 4.07 0 0 1 1.5.281V3z" />
  ),
  build: (
    <path d="M22.688 18.984c.422.281.422.984-.094 1.406l-2.297 2.297c-.422.422-.984.422-1.406 0l-9.094-9.094c-2.297.891-4.969.422-6.891-1.5C.89 10.077.375 7.077 1.593 4.687L5.999 9l3-3-4.313-4.313C7.077.609 10.077.984 12.092 3c1.922 1.922 2.391 4.594 1.5 6.891z" />
  ),
  business_center: (
    <path d="M14.016 6.984V5.015H9.985v1.969h4.031zm6 0c1.078 0 1.969.938 1.969 2.016v3c0 1.078-.891 2.016-1.969 2.016h-6V12H9.985v2.016h-6c-1.125 0-1.969-.891-1.969-2.016V9c0-1.078.891-2.016 1.969-2.016h3.984V5.015l2.016-2.016h3.984l2.016 2.016v1.969h4.031zm-10.032 9h4.031V15h6.984v3.984A2.001 2.001 0 0 1 18.983 21H5.014a2.001 2.001 0 0 1-2.016-2.016V15h6.984v.984z" />
  ),
  credit_card: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
    </g>
  ),
  cached: (
    <path d="M6 12h3l-3.984 3.984L.985 12h3c0-4.406 3.609-8.016 8.016-8.016 1.547 0 3.047.469 4.266 1.266l-1.453 1.453C13.97 6.234 13.033 6 12.001 6c-3.328 0-6 2.672-6 6zm12.984-3.984L23.015 12h-3c0 4.406-3.609 8.016-8.016 8.016a7.858 7.858 0 0 1-4.266-1.266l1.453-1.453c.844.469 1.781.703 2.813.703 3.328 0 6-2.672 6-6h-3z" />
  ),
  cancel: (
    <path d="M17.016 15.609L13.407 12l3.609-3.609-1.406-1.406-3.609 3.609-3.609-3.609-1.406 1.406L10.595 12l-3.609 3.609 1.406 1.406 3.609-3.609 3.609 3.609zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  ),
  center_focus_weak: [
    <path d="M0 0h24v24H0z" fill="none" />,
    <path d="M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />,
  ],
  check: (
    <path d="M9 16.172L19.594 5.578 21 6.984l-12 12-5.578-5.578L4.828 12z" />
  ),
  check_box: (
    <path d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563L5.015 12zm9-14.016C20.109 3 21 3.938 21 5.016v13.969c0 1.078-.891 2.016-2.016 2.016H5.015c-1.125 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.89 3 5.015 3h13.969z" />
  ),
  check_box_outline_blank: (
    <path d="M18.984 3C20.062 3 21 3.938 21 5.016v13.969c0 1.078-.938 2.016-2.016 2.016H5.015c-1.078 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.937 3 5.015 3h13.969zm0 2.016H5.015v13.969h13.969V5.016z" />
  ),
  check_circle: (
    <path d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563L5.015 12zm2.016-15c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  ),
  close: (
    <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z" />
  ),
  cloud_upload: (
    <path d="M14.016 12.984h3L12 8.015l-5.016 4.969h3v4.031h4.031v-4.031zm5.343-2.953C21.937 10.219 24 12.375 24 15a5.021 5.021 0 0 1-5.016 5.016H6c-3.328 0-6-2.672-6-6 0-3.094 2.344-5.625 5.344-5.953C6.61 5.672 9.094 3.985 12 3.985c3.656 0 6.656 2.578 7.359 6.047z" />
  ),
  code: (
    <path d="M14.578 16.594L19.219 12l-4.641-4.594L15.984 6l6 6-6 6zm-5.156 0L8.016 18l-6-6 6-6 1.406 1.406L4.781 12z" />
  ),
  control_point: (
    <path d="M12 20.016c4.406 0 8.016-3.609 8.016-8.016S16.407 3.984 12 3.984 3.984 7.593 3.984 12 7.593 20.016 12 20.016zm0-18c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016zm.984 4.968v4.031h4.031v1.969h-4.031v4.031h-1.969v-4.031H6.984v-1.969h4.031V6.984h1.969z" />
  ),
  control_point_duplicate: (
    <path d="M15 18.984c3.844 0 6.984-3.141 6.984-6.984S18.843 5.016 15 5.016 8.016 8.157 8.016 12s3.141 6.984 6.984 6.984zM15 3c4.969 0 9 4.031 9 9s-4.031 9-9 9-9-4.031-9-9 4.031-9 9-9zM2.016 12c0 2.813 1.594 5.203 3.984 6.328v2.156C2.531 19.265 0 15.89 0 12s2.531-7.266 6-8.484v2.156C3.609 6.797 2.016 9.188 2.016 12zm13.968-3.984v3h3v1.969h-3v3h-1.969v-3h-3v-1.969h3v-3h1.969z" />
  ),
  date_range: (
    <path d="M18.984 20.016V9H5.015v11.016h13.969zm0-16.032C20.062 3.984 21 4.922 21 6v14.016c0 1.078-.938 1.969-2.016 1.969H5.015c-1.125 0-2.016-.891-2.016-1.969V6c0-1.078.891-2.016 2.016-2.016h.984V2.015h2.016v1.969h7.969V2.015H18v1.969h.984zm-1.968 7.032v1.969H15v-1.969h2.016zm-4.032 0v1.969h-1.969v-1.969h1.969zm-3.984 0v1.969H6.984v-1.969H9z" />
  ),
  delete: (
    <path d="M18.984 3.984V6H5.015V3.984h3.469L9.515 3h4.969l1.031.984h3.469zM6 18.984v-12h12v12C18 20.062 17.062 21 15.984 21H8.015c-1.078 0-2.016-.938-2.016-2.016z" />
  ),
  delete_forever: (
    <path d="M15.516 3.984h3.469V6H5.016V3.984h3.469L9.516 3h4.969zm-7.078 7.875l2.156 2.156-2.109 2.109 1.406 1.406L12 15.421l2.109 2.109 1.406-1.406-2.109-2.109 2.109-2.156-1.406-1.406L12 12.609l-2.109-2.156zM6 18.984v-12h12v12C18 20.062 17.062 21 15.984 21H8.015c-1.078 0-2.016-.938-2.016-2.016z" />
  ),
  domain: (
    <path d="M18 15v2.016h-2.016V15H18zm0-3.984v1.969h-2.016v-1.969H18zm2.016 7.968V9H12v2.016h2.016v1.969H12v2.016h2.016v2.016H12v1.969h8.016zm-10.032-12V5.015H8.015v1.969h1.969zm0 4.032V9H8.015v2.016h1.969zm0 3.984v-2.016H8.015V15h1.969zm0 3.984v-1.969H8.015v1.969h1.969zM6 6.984V5.015H3.984v1.969H6zm0 4.032V9H3.984v2.016H6zM6 15v-2.016H3.984V15H6zm0 3.984v-1.969H3.984v1.969H6zm6-12h9.984V21H2.015V3h9.984v3.984z" />
  ),
  done: (
    <path d="M9 16.219L19.594 5.578 21 6.984l-12 12-5.578-5.578L4.781 12z" />
  ),
  done_all: (
    <path d="M.422 13.406L1.828 12l5.578 5.578L6 18.984zm21.797-7.828l1.453 1.406-12 12-5.625-5.578L7.5 12l4.172 4.172zM18 6.984l-6.328 6.375-1.406-1.406 6.328-6.375z" />
  ),
  drag_handle: (
    <path d="M3.984 15v-2.016h16.031V15H3.984zm16.032-6v2.016H3.985V9h16.031z" />
  ),
  error: (
    <path d="M12.984 12.984v-6h-1.969v6h1.969zm0 4.032V15h-1.969v2.016h1.969zm-.984-15c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  ),
  event_busy: (
    <path d="M18.984 18.984V8.015H5.015v10.969h13.969zm0-15.984C20.062 3 21 3.938 21 5.016v13.969c0 1.078-.938 2.016-2.016 2.016H5.015c-1.125 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.89 3 5.015 3h.984V.984h2.016V3h7.969V.984H18V3h.984zM9.328 17.016L8.25 15.938l2.438-2.438-2.438-2.438 1.078-1.078 2.438 2.438 2.438-2.438 1.031 1.078-2.438 2.438 2.438 2.438-1.031 1.078-2.438-2.438z" />
  ),
  event_seat: (
    <path d="M17.016 12.984H6.985V5.015c0-1.078.938-2.016 2.016-2.016h6c1.078 0 2.016.938 2.016 2.016v7.969zm-15-3h3v3h-3v-3zm16.968 0h3v3h-3v-3zM3.984 21v-6h16.031v6h-3v-3H6.984v3h-3z" />
  ),
  expand_less: (
    <path d="M12 8.016l6 6-1.406 1.406L12 10.828l-4.594 4.594L6 14.016z" />
  ),
  expand_more: (
    <path d="M16.594 8.578L18 9.984l-6 6-6-6 1.406-1.406L12 13.172z" />
  ),
  explore: (
    <path d="M14.203 14.203L18 6 9.797 9.797 6 18zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016zm0 8.906c.609 0 1.078.469 1.078 1.078s-.469 1.078-1.078 1.078-1.078-.469-1.078-1.078.469-1.078 1.078-1.078z" />
  ),
  extension: (
    <path d="M20.484 11.016c1.359 0 2.531 1.125 2.531 2.484s-1.172 2.484-2.531 2.484h-1.5v4.031a1.981 1.981 0 0 1-1.969 1.969h-3.797v-1.5c0-1.5-1.219-2.672-2.719-2.672S7.78 18.984 7.78 20.484v1.5H3.983a1.981 1.981 0 0 1-1.969-1.969v-3.797h1.5c1.5 0 2.672-1.219 2.672-2.719S5.014 10.78 3.514 10.78h-1.5V6.983c0-1.078.891-1.969 1.969-1.969h4.031v-1.5c0-1.359 1.125-2.531 2.484-2.531s2.484 1.172 2.484 2.531v1.5h4.031c1.078 0 1.969.891 1.969 1.969v4.031h1.5z" />
  ),
  file_upload: (
    <path d="M5.016 18h13.969v2.016H5.016V18zM9 15.984v-6H5.016L12 3l6.984 6.984H15v6H9z" />
  ),
  filter_list: (
    <path d="M6 12.984v-1.969h12v1.969H6zM3 6h18v2.016H3V6zm6.984 12v-2.016h4.031V18H9.984z" />
  ),
  flight: (
    <path d="M21 15.984L12.984 13.5v5.484l2.016 1.5v1.5L11.484 21l-3.469.984v-1.5l1.969-1.5V13.5l-7.969 2.484v-1.969l7.969-5.016V3.515c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v5.484L21 14.015v1.969z" />
  ),
  get_app: (
    <path d="M5.016 18h13.969v2.016H5.016V18zm13.968-9L12 15.984 5.016 9H9V3h6v6h3.984z" />
  ),
  grid_on: (
    <path d="M20.016 8.016V3.985h-4.031v4.031h4.031zm0 6V9.985h-4.031v4.031h4.031zm0 6v-4.031h-4.031v4.031h4.031zm-6-12V3.985H9.985v4.031h4.031zm0 6V9.985H9.985v4.031h4.031zm0 6v-4.031H9.985v4.031h4.031zm-6-12V3.985H3.985v4.031h4.031zm0 6V9.985H3.985v4.031h4.031zm0 6v-4.031H3.985v4.031h4.031zm12-18c1.078 0 1.969.891 1.969 1.969v16.031a1.981 1.981 0 0 1-1.969 1.969H3.985a1.981 1.981 0 0 1-1.969-1.969V3.985c0-1.078.891-1.969 1.969-1.969h16.031z" />
  ),
  group_work: (
    <path d="M15.984 17.484c1.359 0 2.531-1.125 2.531-2.484s-1.172-2.484-2.531-2.484S13.5 13.641 13.5 15s1.125 2.484 2.484 2.484zM9.516 8.016c0 1.359 1.125 2.484 2.484 2.484s2.484-1.125 2.484-2.484S13.359 5.485 12 5.485 9.516 6.657 9.516 8.016zm-1.5 9.468c1.359 0 2.484-1.125 2.484-2.484s-1.125-2.484-2.484-2.484S5.485 13.641 5.485 15s1.172 2.484 2.531 2.484zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  ),
  home: (
    <path d="M9.984 20.016H5.015V12h-3l9.984-9 9.984 9h-3v8.016h-4.969v-6H9.983v6z" />
  ),
  indeterminate_check_box: (
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z" />
  ),
  info: [
    <path fill="none" d="M0 0h24v24H0V0z" />,
    <path d="M11 7h2v2h-2zM11 11h2v6h-2z" />,
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />,
  ],
  keyboard_arrow_down: (
    <path d="M7.406 7.828L12 12.422l4.594-4.594L18 9.234l-6 6-6-6z" />
  ),
  keyboard_arrow_left: (
    <path d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z" />
  ),
  keyboard_arrow_right: (
    <path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z" />
  ),
  keyboard_arrow_up: (
    <path d="M7.406 15.422L6 14.016l6-6 6 6-1.406 1.406L12 10.828z" />
  ),
  keyboard_backspace: (
    <path d="M21 11.016v1.969H6.844l3.563 3.609L9.001 18l-6-6 6-6 1.406 1.406-3.563 3.609H21z" />
  ),
  keyboard_return: (
    <path d="M18.984 6.984H21v6H5.812l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172V6.983z" />
  ),
  line_style: (
    <path d="M3 3.984h18v4.031H3V3.984zM12.984 12V9.984H21V12h-8.016zM3 12V9.984h8.016V12H3zm15.984 8.016V18H21v2.016h-2.016zm-3.984 0V18h2.016v2.016H15zm-3.984 0V18h1.969v2.016h-1.969zm-4.032 0V18H9v2.016H6.984zm-3.984 0V18h2.016v2.016H3zm12.984-4.032v-1.969H21v1.969h-5.016zm-6.468 0v-1.969h4.969v1.969H9.516zm-6.516 0v-1.969h5.016v1.969H3z" />
  ),
  link: (
    <path d="M17.016 6.984c2.766 0 4.969 2.25 4.969 5.016s-2.203 5.016-4.969 5.016h-4.031v-1.922h4.031c1.688 0 3.094-1.406 3.094-3.094s-1.406-3.094-3.094-3.094h-4.031V6.984h4.031zm-9 6v-1.969h7.969v1.969H8.016zM3.891 12c0 1.688 1.406 3.094 3.094 3.094h4.031v1.922H6.985c-2.766 0-4.969-2.25-4.969-5.016s2.203-5.016 4.969-5.016h4.031v1.922H6.985c-1.688 0-3.094 1.406-3.094 3.094z" />
  ),
  list: (
    <path d="M6.984 6.984H21V9H6.984V6.984zm0 10.032V15H21v2.016H6.984zm0-4.032v-1.969H21v1.969H6.984zM3 9V6.984h2.016V9H3zm0 8.016V15h2.016v2.016H3zm0-4.032v-1.969h2.016v1.969H3z" />
  ),
  local_shipping: (
    <path d="M18 18.516c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm1.5-9h-2.484V12h4.453zm-13.5 9c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm14.016-10.5l3 3.984v5.016H21c0 1.641-1.359 3-3 3s-3-1.359-3-3H9c0 1.641-1.359 3-3 3s-3-1.359-3-3H.984V6c0-1.078.938-2.016 2.016-2.016h14.016v4.031h3z" />
  ),
  lock_open: (
    <path d="M18 20.016V9.985H6v10.031h12zm0-12c1.078 0 2.016.891 2.016 1.969v10.031c0 1.078-.938 1.969-2.016 1.969H6c-1.078 0-2.016-.891-2.016-1.969V9.985c0-1.078.938-1.969 2.016-1.969h9.094V6c0-1.688-1.406-3.094-3.094-3.094S8.906 4.312 8.906 6H6.984C6.984 3.234 9.234.984 12 .984S17.016 3.234 17.016 6v2.016H18zm-6 9c-1.078 0-2.016-.938-2.016-2.016s.938-2.016 2.016-2.016 2.016.938 2.016 2.016-.938 2.016-2.016 2.016z" />
  ),
  lock_outline: (
    <path d="M18 20.016V9.985H6v10.031h12zM8.906 6v2.016h6.188V6c0-1.688-1.406-3.094-3.094-3.094S8.906 4.312 8.906 6zM18 8.016c1.078 0 2.016.891 2.016 1.969v10.031c0 1.078-.938 1.969-2.016 1.969H6c-1.078 0-2.016-.891-2.016-1.969V9.985c0-1.078.938-1.969 2.016-1.969h.984V6C6.984 3.234 9.234.984 12 .984S17.016 3.234 17.016 6v2.016H18zm-6 9c-1.078 0-2.016-.938-2.016-2.016s.938-2.016 2.016-2.016 2.016.938 2.016 2.016-.938 2.016-2.016 2.016z" />
  ),
  mail_outline: (
    <path d="M12 11.016L20.016 6H3.985zM20.016 18V8.016L12 12.985 3.984 8.016V18h16.031zm0-14.016c1.078 0 1.969.938 1.969 2.016v12c0 1.078-.891 2.016-1.969 2.016H3.985c-1.078 0-1.969-.938-1.969-2.016V6c0-1.078.891-2.016 1.969-2.016h16.031z" />
  ),
  map: (
    <path d="M15 18.984V7.125L9 5.016v11.859zM20.484 3a.52.52 0 0 1 .516.516V18.61a.506.506 0 0 1-.375.469L15 21.001l-6-2.109-5.344 2.063-.141.047a.52.52 0 0 1-.516-.516V5.392c0-.234.188-.422.375-.469l5.625-1.922 6 2.109 5.344-2.063z" />
  ),
  markunread: (
    <path d="M20.016 8.016V6L12 11.016 3.984 6v2.016L12 12.985zm0-4.032c1.078 0 1.969.938 1.969 2.016v12c0 1.078-.891 2.016-1.969 2.016H3.985c-1.078 0-1.969-.938-1.969-2.016V6c0-1.078.891-2.016 1.969-2.016h16.031z" />
  ),
  menu: (
    <path d="M3 6h18v2.016H3V6zm0 6.984v-1.969h18v1.969H3zM3 18v-2.016h18V18H3z" />
  ),
  mic: (
    <path d="M17.297 11.016h1.688c0 3.422-2.719 6.234-6 6.703V21h-1.969v-3.281c-3.281-.469-6-3.281-6-6.703h1.688c0 3 2.531 5.063 5.297 5.063s5.297-2.063 5.297-5.063zm-5.297 3a3.02 3.02 0 0 1-3-3v-6c0-1.641 1.359-3 3-3s3 1.359 3 3v6c0 1.641-1.359 3-3 3z" />
  ),
  mode_edit: (
    <path d="M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828a1.02 1.02 0 0 1 1.406 0l2.344 2.344a1.02 1.02 0 0 1 0 1.406zM3 17.25L14.063 6.187l3.75 3.75L6.75 21H3v-3.75z" />
  ),
  more_horiz: (
    <path d="M12 9.984c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 13.078 9.984 12 10.922 9.984 12 9.984zm6 0c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016-2.016-.938-2.016-2.016.938-2.016 2.016-2.016zm-12 0c1.078 0 2.016.938 2.016 2.016S7.078 14.016 6 14.016 3.984 13.078 3.984 12 4.922 9.984 6 9.984z" />
  ),
  more_vert: (
    <path d="M12 15.984c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 19.078 9.984 18s.938-2.016 2.016-2.016zm0-6c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 13.078 9.984 12 10.922 9.984 12 9.984zm0-1.968c-1.078 0-2.016-.938-2.016-2.016S10.922 3.984 12 3.984s2.016.938 2.016 2.016S13.078 8.016 12 8.016z" />
  ),
  open_in_new: (
    <path d="M14.016 3H21v6.984h-2.016V6.421l-9.797 9.797-1.406-1.406 9.797-9.797h-3.563V2.999zm4.968 15.984V12H21v6.984C21 20.062 20.062 21 18.984 21H5.015c-1.125 0-2.016-.938-2.016-2.016V5.015c0-1.078.891-2.016 2.016-2.016h6.984v2.016H5.015v13.969h13.969z" />
  ),
  people: (
    <path d="M15.984 12.984c2.344 0 7.031 1.172 7.031 3.516v2.484h-6V16.5c0-1.5-.797-2.625-1.969-3.469.328-.047.656-.047.938-.047zm-7.968 0C10.36 12.984 15 14.156 15 16.5v2.484H.984V16.5c0-2.344 4.688-3.516 7.031-3.516zm0-1.968c-1.641 0-3-1.359-3-3s1.359-3 3-3 2.953 1.359 2.953 3-1.313 3-2.953 3zm7.968 0c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3z" />
  ),
  person: (
    <path d="M12 14.016c2.672 0 8.016 1.313 8.016 3.984v2.016H3.985V18c0-2.672 5.344-3.984 8.016-3.984zM12 12c-2.203 0-3.984-1.781-3.984-3.984S9.797 3.985 12 3.985s3.984 1.828 3.984 4.031S14.203 12 12 12z" />
  ),
  person_add: [
    <path d="M0 0h24v24H0z" fill="none" />,
    <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />,
  ],
  photo: (
    <path d="M8.484 13.5L5.015 18h13.969l-4.5-6-3.469 4.5zM21 18.984C21 20.062 20.062 21 18.984 21H5.015c-1.078 0-2.016-.938-2.016-2.016V5.015c0-1.078.938-2.016 2.016-2.016h13.969c1.078 0 2.016.938 2.016 2.016v13.969z" />
  ),
  play_arrow: <path d="M8.016 5.016L18.985 12 8.016 18.984V5.015z" />,
  playlist_add: (
    <path d="M2.016 15.984v-1.969h7.969v1.969H2.016zM18 14.016h3.984v1.969H18v4.031h-2.016v-4.031H12v-1.969h3.984V9.985H18v4.031zM14.016 6v2.016h-12V6h12zm0 3.984V12h-12V9.984h12z" />
  ),
  power: (
    <path d="M16.031 6.984C17.015 6.984 18 8.015 18 9v5.484L14.484 18v3H9.515v-3l-3.516-3.516V9c0-.984.984-2.016 1.969-2.016h.047V3h1.969v3.984h4.031V3h1.969z" />
  ),
  print: (
    <path d="M18 3v3.984H6V3h12zm.984 9c.563 0 1.031-.422 1.031-.984s-.469-1.031-1.031-1.031-.984.469-.984 1.031.422.984.984.984zm-3 6.984v-4.969H8.015v4.969h7.969zm3-10.968c1.641 0 3 1.359 3 3v6H18V21H6v-3.984H2.016v-6c0-1.641 1.359-3 3-3h13.969z" />
  ),
  receipt: (
    <path d="M3 21.984V2.015l1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5v19.969l-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5zM18 9V6.984H6V9h12zm0 3.984v-1.969H6v1.969h12zm0 4.032V15H6v2.016h12z" />
  ),
  refresh: (
    <path d="M17.672 6.328l2.344-2.344v7.031h-7.031l3.234-3.234C15.141 6.703 13.641 6 12 6c-3.328 0-6 2.672-6 6s2.672 6 6 6c2.625 0 4.875-1.641 5.672-3.984h2.063c-.891 3.469-3.984 6-7.734 6-4.406 0-7.969-3.609-7.969-8.016s3.563-8.016 7.969-8.016a8.01 8.01 0 0 1 5.672 2.344z" />
  ),
  remove: <path d="M18.984 12.984H5.015v-1.969h13.969v1.969z" />,
  reply: (
    <path d="M9.984 9c6.984.984 10.031 6 11.016 11.016-2.484-3.516-6-5.109-11.016-5.109v4.078L3 12.001l6.984-6.984v3.984z" />
  ),
  restaurant: (
    <path d="M15.984 6c0-1.781 2.25-3.984 5.016-3.984v19.969h-2.484v-7.969h-2.531V6zm-4.968 3V2.016h1.969V9a4 4 0 0 1-3.75 3.984v9H6.751v-9c-2.109-.141-3.75-1.875-3.75-3.984V2.016h2.016V9h1.969V2.016h2.016V9h2.016z" />
  ),
  restaurant_menu: (
    <path d="M14.859 11.531l-1.453 1.453 6.891 6.891-1.406 1.406L12 14.39l-6.891 6.891-1.406-1.406 9.75-9.75c-.703-1.547-.188-3.703 1.406-5.297 1.922-1.922 4.641-2.25 6.094-.797s1.125 4.219-.797 6.141c-1.594 1.594-3.75 2.063-5.297 1.359zm-6.75 1.828L3.89 9.14a3.989 3.989 0 0 1 0-5.625l7.031 6.984z" />
  ),
  restore: (
    <path d="M12 8.016h1.5v4.219l3.516 2.109-.75 1.219L12 12.985V8.016zM12.984 3c4.969 0 9 4.031 9 9s-4.031 9-9 9a8.85 8.85 0 0 1-6.328-2.625l1.406-1.453c1.266 1.266 3 2.063 4.922 2.063 3.891 0 7.031-3.094 7.031-6.984s-3.141-6.984-7.031-6.984S6 8.111 6 12.001h3l-4.031 4.031-.094-.141L.984 12h3c0-4.969 4.031-9 9-9z" />
  ),
  room: (
    <path d="M12 11.484c1.359 0 2.484-1.125 2.484-2.484s-1.125-2.484-2.484-2.484-2.484 1.125-2.484 2.484 1.125 2.484 2.484 2.484zM12 2.016c3.891 0 6.984 3.094 6.984 6.984 0 5.25-6.984 12.984-6.984 12.984s-6.984-7.734-6.984-12.984c0-3.891 3.094-6.984 6.984-6.984z" />
  ),
  search: (
    <path d="M9.516 14.016c2.484 0 4.5-2.016 4.5-4.5s-2.016-4.5-4.5-4.5-4.5 2.016-4.5 4.5 2.016 4.5 4.5 4.5zm6 0l4.969 4.969-1.5 1.5-4.969-4.969v-.797l-.281-.281c-1.125.984-2.625 1.547-4.219 1.547C5.907 15.985 3 13.126 3 9.516S5.906 3 9.516 3s6.469 2.906 6.469 6.516c0 1.594-.563 3.094-1.547 4.219l.281.281h.797z" />
  ),
  send: <path d="M2.016 21v-6.984l15-2.016-15-2.016V3l21 9z" />,
  settings: (
    <path d="M12 15.516c1.922 0 3.516-1.594 3.516-3.516S13.922 8.484 12 8.484 8.484 10.078 8.484 12s1.594 3.516 3.516 3.516zm7.453-2.532l2.109 1.641c.188.141.234.422.094.656L19.64 18.75c-.141.234-.375.281-.609.188l-2.484-.984c-.516.375-1.078.75-1.688.984l-.375 2.625c-.047.234-.234.422-.469.422H9.984c-.234 0-.422-.188-.469-.422l-.375-2.625a6.145 6.145 0 0 1-1.688-.984l-2.484.984c-.234.094-.469.047-.609-.188l-2.016-3.469c-.141-.234-.094-.516.094-.656l2.109-1.641c-.047-.328-.047-.656-.047-.984s0-.656.047-.984L2.437 9.375c-.188-.141-.234-.422-.094-.656L4.359 5.25c.141-.234.375-.281.609-.188l2.484.984c.516-.375 1.078-.75 1.688-.984l.375-2.625c.047-.234.234-.422.469-.422h4.031c.234 0 .422.188.469.422l.375 2.625a6.145 6.145 0 0 1 1.688.984l2.484-.984c.234-.094.469-.047.609.188l2.016 3.469c.141.234.094.516-.094.656l-2.109 1.641c.047.328.047.656.047.984s0 .656-.047.984z" />
  ),
  share: (
    <path d="M18 16.078c1.594 0 2.906 1.313 2.906 2.906S19.593 21.937 18 21.937s-2.906-1.359-2.906-2.953c0-.234 0-.469.047-.656l-7.078-4.125A3.02 3.02 0 0 1 3 12a3.02 3.02 0 0 1 5.063-2.203l7.031-4.078A3.608 3.608 0 0 1 15 5.016c0-1.641 1.359-3 3-3s3 1.359 3 3-1.359 3-3 3c-.797 0-1.5-.328-2.063-.844l-7.031 4.125c.047.234.094.469.094.703s-.047.469-.094.703l7.125 4.125a2.934 2.934 0 0 1 1.969-.75z" />
  ),
  short_text: (
    <path d="M3.984 12.984h10.031V15H3.984v-2.016zm0-3.984h16.031v2.016H3.984V9z" />
  ),
  star: (
    <path d="M12 17.25L5.812 21l1.641-7.031-5.438-4.734 7.172-.609L12 2.017l2.813 6.609 7.172.609-5.438 4.734L18.188 21z" />
  ),
  star_border: (
    <path d="M12 15.422l3.75 2.25-.984-4.266 3.328-2.906-4.406-.375L12 6.094l-1.688 4.031-4.406.375 3.328 2.906-.984 4.266zm9.984-6.188l-5.438 4.734 1.641 7.031-6.188-3.75-6.188 3.75 1.641-7.031-5.438-4.734 7.172-.609 2.813-6.609 2.813 6.609z" />
  ),
  stars: (
    <path d="M16.219 18l-1.125-4.828 3.75-3.234-4.922-.422L12 5.016l-1.922 4.547-4.922.375 3.75 3.234L7.781 18 12 15.469zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  ),
  timeline: (
    <path d="M23.016 8.016c0 1.078-.938 1.969-2.016 1.969-.188 0-.375 0-.516-.047l-3.563 3.563c.047.141.094.328.094.516 0 1.078-.938 1.969-2.016 1.969s-2.016-.891-2.016-1.969c0-.188.047-.375.094-.516l-2.578-2.578c-.141.047-.328.094-.516.094s-.375-.047-.516-.094L4.92 15.47c.047.141.094.328.094.516 0 1.078-.938 2.016-2.016 2.016S.982 17.064.982 15.986s.938-1.969 2.016-1.969c.188 0 .375 0 .516.047l4.547-4.547c-.047-.141-.047-.328-.047-.516 0-1.078.891-2.016 1.969-2.016s2.016.938 2.016 2.016c0 .188 0 .375-.047.516l2.531 2.531c.141-.047.328-.047.516-.047s.375 0 .516.047l3.563-3.516a1.683 1.683 0 0 1-.094-.516C18.984 6.938 19.922 6 21 6s2.016.938 2.016 2.016z" />
  ),
  view_agenda: (
    <path d="M20.016 3a.96.96 0 0 1 .984.984v6c0 .563-.422 1.031-.984 1.031H3c-.563 0-.984-.469-.984-1.031v-6A.96.96 0 0 1 3 3h17.016zm0 9.984c.563 0 .984.469.984 1.031v6a.96.96 0 0 1-.984.984H3a.96.96 0 0 1-.984-.984v-6c0-.563.422-1.031.984-1.031h17.016z" />
  ),
  view_column: (
    <path d="M15.984 5.016H21V18h-5.016V5.016zM3.984 18V5.016H9V18H3.984zm6 0V5.016H15V18H9.984z" />
  ),
  view_list: (
    <path d="M9 5.016h12V9H9V5.016zm0 13.968V15h12v3.984H9zm0-4.968V9.985h12v4.031H9zM3.984 9V5.016h4.031V9H3.984zm0 9.984V15h4.031v3.984H3.984zm0-4.968V9.985h4.031v4.031H3.984z" />
  ),
  visibility: (
    <path d="M12 9c1.641 0 3 1.359 3 3s-1.359 3-3 3-3-1.359-3-3 1.359-3 3-3zm0 8.016c2.766 0 5.016-2.25 5.016-5.016S14.766 6.984 12 6.984 6.984 9.234 6.984 12s2.25 5.016 5.016 5.016zM12 4.5c5.016 0 9.281 3.094 11.016 7.5-1.734 4.406-6 7.5-11.016 7.5S2.719 16.406.984 12c1.734-4.406 6-7.5 11.016-7.5z" />
  ),
  visibility_off: [
    <path
      d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
      fill="none"
    />,
    <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46A11.804 11.804 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3z" />,
  ],
  vpn_key: (
    <path d="M6.984 14.016C8.062 14.016 9 13.078 9 12s-.938-2.016-2.016-2.016-1.969.938-1.969 2.016.891 2.016 1.969 2.016zm5.672-4.032h10.359v4.031h-2.016v3.984h-3.984v-3.984h-4.359c-.797 2.344-3.047 3.984-5.672 3.984-3.328 0-6-2.672-6-6s2.672-6 6-6c2.625 0 4.875 1.641 5.672 3.984z" />
  ),
  warning: (
    <path d="M12.984 14.016V9.985h-1.969v4.031h1.969zm0 3.984v-2.016h-1.969V18h1.969zm-12 3L12 2.016 23.016 21H.985z" />
  ),
};

const otherMaterialSVGs = {}; // none right now

const materialSVGs = {
  ...R.mapObjIndexed(
    (v) =>
      ({ otherTags = null, ...svgProps }) => (
        <SVG {...svgProps} viewBox="0 0 24 24">
          {otherTags}
          {v}
        </SVG>
      ),
    materialIconPaths,
  ),
  ...otherMaterialSVGs,
};

export { materialSVGs };
