import { combineReducers } from "redux";
import { RECEIVE, REQUEST, UPDATE_SEARCH_TERM } from "./constants";

const searchResults = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const searchTerm = (state = "", action) => {
  switch (action.type) {
    case UPDATE_SEARCH_TERM:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  searchTerm,
  searchResults,
  fetching,
});
