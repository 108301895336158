import { ADD_COUNT } from "./constants";
import Immutable from "immutable";

const defaultState = {
  pending: {},
  approved: {},
  denied: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_COUNT:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.status, action.payload.moduleId],
          Number(action.payload.count),
        )
        .toJS();
    default:
      return state;
  }
};
