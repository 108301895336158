import * as R from "ramda";
import PropTypes from "prop-types";
import { deepMapFlat, getFirstDefined } from "../utils";

const emptyObj = {};

const maxRadius = 99999;

const propGetter = (scale) => (step) => {
  if (step < 0) {
    return scale[step * -1];
  }
  return scale[step];
};

const colorGetter = (scale) => (step) => {
  let appliedStep = step;
  if (step === "") {
    appliedStep = undefined;
  }
  if (appliedStep === "inherit") {
    return "inherit";
  } else if (appliedStep === "transparent") {
    return "transparent";
  } else if (scale[appliedStep]) {
    return scale[appliedStep];
  }
  return appliedStep;
};

const byScaler = (scaler) => (step) => scaler * step;

const sameStep = () => (x) => x;

const orEmptySwitch = (styleObj) => (b) => (b ? styleObj : emptyObj);

const oneOfIndex = (propName) => (props) =>
  PropTypes.oneOf(R.addIndex(R.map)((e, i) => i, props[propName]));

const oneOfKeys =
  (propName, additionalKeys = []) =>
  (props) =>
    PropTypes.oneOf([...R.keys(props[propName]), ...additionalKeys]);

const globalValues = ["inherit", "initial", "unset"];

const textAlignProps = [
  ...globalValues,
  "left",
  "right",
  "center",
  "justify",
  "justify-all",
  "start",
  "end",
  "match-parent",
];

const displayPropParts = {
  nonFlex: [
    "none",
    "block",
    "flex",
    "inline-flex",
    "inline",
    "inline-block",
    "table",
    "table-cell",
    "table-row",
  ],
  flex: ["flex", "row", "column", "inline-flex", "inline-row", "inline-column"],
  justify: [
    "flex-start",
    "flex-end",
    "center",
    "space-around",
    "space-between",
    "space-evenly",
  ],
  align: ["flex-start", "flex-end", "center", "stretch", "baseline"],
};

const displayProps = [
  ...globalValues,
  ...displayPropParts.nonFlex,
  ...displayPropParts.flex,
  ...deepMapFlat(
    [displayPropParts.flex, displayPropParts.justify, displayPropParts.align],
    ([[f], [j], [a]]) => `${f}.${j}.${a}`,
  ),
];

const conversionDict = {
  border: [propGetter, oneOfIndex("border")],
  borderRadius: [propGetter, oneOfIndex("borderRadius")],
  fontSize: [propGetter, oneOfIndex("fontSize")],
  fontWeight: [propGetter, oneOfIndex("fontWeight")],
  color: [colorGetter, R.always(PropTypes.string)], // oneOfKeys('color', ['inherit', 'transparent'])
  // @TODO: maybe change the shadow imp in the future to not allow number keys
  shadow: [
    propGetter,
    ({ shadow }) =>
      PropTypes.oneOf([
        ...R.map((k) => (isNaN(k) ? k : parseInt(k, 10)), R.keys(shadow)),
        ...R.keys(shadow),
      ]),
  ],
  fontFamily: [propGetter, oneOfKeys("fontFamily")],
  transition: [propGetter, oneOfKeys("transition")],
  position: [
    sameStep,
    oneOfKeys("position", ["relative", "absolute", "fixed", "static"]),
  ],
  display: [sameStep, R.always(PropTypes.oneOf(displayProps))],
  textAlign: [sameStep, R.always(PropTypes.oneOf(textAlignProps))],
  flexWrap: [sameStep, R.always(PropTypes.string)],
  flex: [sameStep, R.always(PropTypes.number)],
  dimensions: [sameStep, R.always(PropTypes.number)],
  spacing: [byScaler, R.always(PropTypes.number)],
  sameStepBool: [sameStep, R.always(PropTypes.bool)],
};

const oneProp = (n) => (x) => ({ [n]: x });
const borderProp = (n) => (x) => ({
  [`${n}Width`]: `${x}px`,
  [`${n}Style`]: "solid",
});

const implDict = {
  p: ["spacing", oneProp("padding")],
  pt: ["spacing", oneProp("paddingTop")],
  pr: ["spacing", oneProp("paddingRight")],
  pb: ["spacing", oneProp("paddingBottom")],
  pl: ["spacing", oneProp("paddingLeft")],
  px: [
    "spacing",
    (x) => ({
      paddingLeft: x,
      paddingRight: x,
    }),
  ],
  py: [
    "spacing",
    (x) => ({
      paddingTop: x,
      paddingBottom: x,
    }),
  ],
  m: ["spacing", oneProp("margin")],
  mt: ["spacing", oneProp("marginTop")],
  mr: ["spacing", oneProp("marginRight")],
  mb: ["spacing", oneProp("marginBottom")],
  ml: ["spacing", oneProp("marginLeft")],
  mx: [
    "spacing",
    (x) => ({
      marginLeft: x,
      marginRight: x,
    }),
  ],
  my: [
    "spacing",
    (x) => ({
      marginTop: x,
      marginBottom: x,
    }),
  ],
  bra: [
    "borderRadius",
    (x) => ({
      borderRadius: x,
    }),
  ],
  brt: [
    "borderRadius",
    (x) => ({
      borderTopLeftRadius: x,
      borderTopRightRadius: x,
    }),
  ],
  brr: [
    "borderRadius",
    (x) => ({
      borderTopRightRadius: x,
      borderBottomRightRadius: x,
    }),
  ],
  brb: [
    "borderRadius",
    (x) => ({
      borderBottomLeftRadius: x,
      borderBottomRightRadius: x,
    }),
  ],
  brl: [
    "borderRadius",
    (x) => ({
      borderBottomLeftRadius: x,
      borderTopLeftRadius: x,
    }),
  ],
  ba: ["border", borderProp("border")],
  bt: ["border", borderProp("borderTop")],
  br: ["border", borderProp("borderRight")],
  bb: ["border", borderProp("borderBottom")],
  bl: ["border", borderProp("borderLeft")],
  fs: ["fontSize", oneProp("fontSize")],
  fw: ["fontWeight", oneProp("fontWeight")],
  ff: ["fontFamily", oneProp("fontFamily")],
  color: ["color", oneProp("color")],
  bg: ["color", oneProp("backgroundColor")],
  bc: ["color", oneProp("borderColor")],
  flexWrap: ["flexWrap", oneProp("flexWrap")],
  shadow: ["shadow", oneProp("boxShadow")],
  textAlign: ["textAlign", oneProp("textAlign")],
  transition: ["transition", oneProp("transition")],
  underline: ["sameStepBool", orEmptySwitch({ textDecoration: "underline" })],
  uppercase: ["sameStepBool", orEmptySwitch({ textTransform: "uppercase" })],
  lowercase: ["sameStepBool", orEmptySwitch({ textTransform: "lowercase" })],
  capitalize: ["sameStepBool", orEmptySwitch({ textTransform: "capitalize" })],
  italics: ["sameStepBool", orEmptySwitch({ fontStyle: "italic" })],
  noWrap: [
    "sameStepBool",
    orEmptySwitch({
      whiteSpace: "nowrap",
    }),
  ],
  truncate: [
    "sameStepBool",
    orEmptySwitch({
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  ],
  pill: [
    "sameStepBool",
    orEmptySwitch({
      borderRadius: maxRadius,
    }),
  ],
  position: ["position", oneProp("position")],
  noLineHeight: [
    "sameStepBool",
    orEmptySwitch({
      lineHeight: "100%",
    }),
  ],
  display: [
    "display",
    (x) => {
      if (
        R.contains(x, [
          "flex",
          "inline-flex",
          "block",
          "inline",
          "inline-block",
          "table",
          "table-cell",
          "table-row",
        ])
      ) {
        return {
          display: x,
        };
      }
      // can specify flex display with shorthand like:
      // display={'row'} // defaults to 'row.flex-start.flex-start'
      // display={'row.center.center'}
      // display={'inline-row.flex-start.stretch'}
      // display={'column.center.flex-end'}
      // etc.
      const [flexDirectionAndDisplay, justifyValue, alignValue] = x.split(".");
      const [a, b] = flexDirectionAndDisplay.split("-");
      const display = a === "inline" ? "inline-flex" : "flex";
      const flexDirection = b === undefined ? a : b;
      return {
        display,
        flexDirection,
        justifyContent: justifyValue === "" ? "flex-start" : justifyValue,
        alignItems: alignValue === "" ? "flex-start" : alignValue,
      };
    },
  ],
  flex: [
    "flex",
    (x) => ({
      flex: `${x} 1 auto`,
    }),
  ],
  h: ["spacing", oneProp("height")],
  w: ["spacing", oneProp("width")],
  height: [
    "dimensions",
    (x) =>
      x > 1
        ? {
            height: x,
          }
        : {
            height: `${x * 100}%`,
          },
  ],
  maxHeight: [
    "dimensions",
    (x) =>
      x > 1
        ? {
            maxHeight: x,
          }
        : {
            maxHeight: `${x * 100}%`,
          },
  ],
  width: [
    "dimensions",
    (x) =>
      x > 1
        ? {
            width: x,
          }
        : {
            width: `${x * 100}%`,
          },
  ],
  maxWidth: [
    "dimensions",
    (x) =>
      x > 1
        ? {
            maxWidth: x,
          }
        : {
            maxWidth: `${x * 100}%`,
          },
  ],
  size: [
    "spacing",
    (x) => ({
      width: x / 4,
      height: x / 4,
      minWidth: x / 4,
    }),
  ],
  sizeWFS: [
    "fontSize",
    (x) => ({
      width: x,
      height: x,
      minWidth: x,
    }),
  ],
};

const propKeys = Object.keys(implDict);

// //////////////////////////////////////////////////////////////////////////////

const applyStyles = (
  oldStyles,
  mediaSpecificValue,
  mediaIndex,
  mediaKeys,
  implValueGetter,
  implFunc,
) => {
  let styles = oldStyles;
  if (typeof mediaSpecificValue === "object") {
    Object.keys(mediaSpecificValue).map((pClass) => {
      const pClassValue = mediaSpecificValue[pClass];
      const implObject = implFunc(implValueGetter(pClassValue));
      if (pClass === "default") {
        styles = {
          ...styles,
          [mediaKeys[mediaIndex]]: {
            ...styles[mediaKeys[mediaIndex]],
            ...implObject,
          },
        };
      } else {
        const styleToSpread = R.pathOr(
          emptyObj,
          [mediaKeys[mediaIndex], `:${pClass}`],
          styles,
        );

        styles = {
          ...styles,
          [mediaKeys[mediaIndex]]: {
            ...styles[mediaKeys[mediaIndex]],
            [`:${pClass}`]: {
              ...styleToSpread,
              ...implObject,
            },
          },
        };
      }
    });
  } else {
    const implObject = implFunc(implValueGetter(mediaSpecificValue));
    styles = {
      ...styles,
      [mediaKeys[mediaIndex]]: {
        ...styles[mediaKeys[mediaIndex]],
        ...implObject,
      },
    };
  }
  return styles;
};

const getCorrectSpacing = (props) => {
  const {
    w,
    h,
    width,
    height,
    pt,
    pb,
    pr,
    pl,
    px,
    py,
    p,
    mt,
    mb,
    mr,
    ml,
    mx,
    my,
    m,
    ba,
    bb,
    bt,
    br,
    bl,
    bra,
    pill,
    ...other
  } = props;

  // don't apply w/h if width/height is present
  const widthProp = !R.isNil(width) ? { width } : { w };
  const heightProp = !R.isNil(height) ? { height } : { h };

  return {
    ...widthProp,
    ...heightProp,
    // correctly order so pill supercedes bra
    bra,
    pill,
    pt: getFirstDefined([pt, py, p]),
    pb: getFirstDefined([pb, py, p]),
    pr: getFirstDefined([pr, px, p]),
    pl: getFirstDefined([pl, px, p]),
    mt: getFirstDefined([mt, my, m]),
    mb: getFirstDefined([mb, my, m]),
    mr: getFirstDefined([mr, mx, m]),
    ml: getFirstDefined([ml, mx, m]),
    bb: getFirstDefined([bb, ba]),
    bt: getFirstDefined([bt, ba]),
    br: getFirstDefined([br, ba]),
    bl: getFirstDefined([bl, ba]),
    ...other,
  };
};

const addDefaultBorder = (props) => {
  const { bc, ba, bb, bt, br, bl, ...other } = props;

  if (bc && R.all(R.isNil)([ba, bb, bt, br, bl])) {
    return {
      bc,
      ba: 1,
      ...other,
    };
  }
  return props;
};

const getStyles = (styleSpecs) => (rawProps, style) => {
  // preformatters applied
  const props = R.compose(
    R.reject(R.isNil),
    addDefaultBorder,
    getCorrectSpacing,
  )(rawProps);

  let styles = {};
  const mediaKeys = [
    "defaultStyles",
    ...styleSpecs.breakpoints.map(
      (b) => `@media screen and (min-width: ${b}px)`,
    ),
  ];

  Object.keys(props).forEach((prop) => {
    const propValue = props[prop];

    if (propValue !== undefined) {
      const [conversionFunc, implFunc] = implDict[prop];

      const implValueGetter = conversionDict[conversionFunc][0](
        styleSpecs[conversionFunc],
      );

      if (Array.isArray(propValue)) {
        propValue.forEach((mediaSpecificValue, mediaIndex) => {
          styles = applyStyles(
            styles,
            mediaSpecificValue,
            mediaIndex,
            mediaKeys,
            implValueGetter,
            implFunc,
          );
        });
      } else {
        styles = applyStyles(
          styles,
          propValue,
          0,
          mediaKeys,
          implValueGetter,
          implFunc,
        );
      }
    }
  });

  // extract defaults
  const { defaultStyles, ...otherStyles } = styles;
  styles = {
    ...defaultStyles,
    ...otherStyles,
  };

  // for debugging
  // console.log({ styles });

  return R.mergeDeepRight(styles, style);
};

export default getStyles;
export { implDict, propKeys, conversionDict };

// ===> This prop spec
//   color=[{default:"blue", hover: "red"},{default:"green", hover: "yellow"},"orange"]
//   fontSize=[{default: 1, hover: 2},{hover:3},{hover:4 }]
//   fontWeight=[1,2,3]

// ===> Will produce something like this style (dependent on your style spec)
//   {
//       color: 'blue',
//       fontSize: 12px,
//       fontWeight: 300,
//       ":hover": {
//           color: "red",
//           fontSize: 14px
//       }
//       "@media ...": {
//         color: 'green',
//         fontWeight: 500,
//         ":hover": {
//             color: "yellow",
//             fontSize: 16px
//         }
//       }
//       "@media ...": {
//         color: 'orange',
//         fontWeight: 700,
//         ":hover": {
//             fontSize: 20px
//         }
//       }
//   }
