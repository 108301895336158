import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "UISnackbar",
  iniState: {
    snackbars: [],
    show: false,
  },
  reducers: {
    show: ({ snackbars }, { payload: snackbar }) => ({
      snackbars: R.append(snackbar, snackbars),
      show: true,
    }),
    hide: R.always({ show: false }),
    next: ({ snackbars }) => ({
      snackbars: R.tail(snackbars),
      show: R.length(R.tail(snackbars)) > 0 ? true : false,
    }),
  },
});

const { actions, getters } = model;

export { actions, getters };

export default model;
