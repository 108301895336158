import React from "react";
import { Div, Text1, Text3 } from "../../index";
import { RightArrowCard } from "./RightArrowCard";

const Type1Card = ({ heading, subtext, onClick }) => (
  <RightArrowCard onClick={onClick}>
    <Div display="column.center.flex-start">
      <Text3 bold>{heading}</Text3>
      <Text1 bold color="neutral5">
        {subtext}
      </Text1>
    </Div>
  </RightArrowCard>
);

export { Type1Card };
