import React from "react";
import { Div, FontIcon, Text1, Text3 } from "../../index";
import { RightArrowCard } from "./RightArrowCard";

const Type3Card = ({ heading, subtext, icon, onClick }) => (
  <RightArrowCard py={2} onClick={onClick}>
    <Div>
      <Text3 bold flex={0}>
        {heading}
      </Text3>
      <Div pt={1} display="row.flex-start.center">
        <FontIcon flex={0} icon={icon} fs={3} />
        <Text1 pl={1} flex={0} color="neutral6">
          {subtext}
        </Text1>
      </Div>
    </Div>
  </RightArrowCard>
);

export { Type3Card };
