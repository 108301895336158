/* eslint-disable no-underscore-dangle */
import * as R from "ramda";

import { call, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import Pusher from "pusher-js";

import superagent from "superagent";

const debug = require("debug")("lennd:apiCall");

import SsoSession from "App/Config/ssoSession";
import { getCredentials } from "redux/modules/user/selectors";

import { getBaseApiUrl } from "utils/get-base-api-url";

/**
 *
 * @param {object} opts
 * @param {string} [opts.method]
 * @param {string} [opts.url]
 * @param {string} [opts.baseUrl]
 * @param {boolean} [opts.public]
 * @param {object} [opts.data]
 * @param {object} [opts.qs]
 * @param {import('./types').AuthToken } opts.credentials
 */
export const makeApiCall = (opts = {}) => {
  const authMode = window.__AUTH_MODE__;
  const publicApi = opts?.public === true;

  let authToken = null;
  let isIdp = false;

  if (opts?.credentials?.isIdp === true) {
    isIdp = true;
  }

  if (!publicApi) {
    authToken = opts?.credentials?.idToken || "";

    if (authMode == "classy-sso") {
      SsoSession.refreshTokenIfNecessary();
      authToken = opts?.credentials?.access_token || "";
    }
  }

  const url = `${getBaseApiUrl({ publicApi, isIdp, windowRef: window })}${
    opts.url
  }`;

  let request = R.propOr(R.always(Promise.resolve()), opts.method, {
    get: (baseRequest) => baseRequest.get(url),
    post: (baseRequest) =>
      baseRequest
        .post(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    put: (baseRequest) =>
      baseRequest
        .put(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    delete: (baseRequest) =>
      baseRequest
        .del(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    head: (baseRequest) =>
      baseRequest
        .head(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
  })(superagent);

  request = request.query(opts.qs).set("Accept", "application/json");

  if (!publicApi) {
    request = request.set("Authorization", `Bearer ${authToken}`);
  }

  return request
    .then((response) => {
      debug(`API Success: ${opts.method} ${opts.url}`);
      return response.body;
    })
    .catch((error) => {
      if (R.pathEq(["response", "body", "message"], "jwt expired", error)) {
        const message = "[Error] JWT Expired... refreshing session";

        // eslint-disable-next-line no-console
        console.error(message);
        window.location.reload();
        return false;
      }

      if (error.response) {
        throw error.response;
      }

      throw error;
    });
};

/**
 * @param {object} options
 * @param {string} options.method
 * @param {string} options.url
 * @param {string} [options.baseUrl]
 * @param {boolean} [options.public]
 * @param {object} [options.data]
 * @param {object} [options.qs]
 */
export const apiCall = function* (options) {
  const credentials = yield select(getCredentials);
  return yield call(makeApiCall, {
    ...options,
    credentials,
  });
};

let pusherInstance = null;
export const getPusherInstance = () => {
  if (!pusherInstance) {
    pusherInstance = new Pusher(window.__PUSHER_APP_KEY__, {
      cluster: window.__PUSHER_APP_CLUSTER__,
      forceTLS: true,
    });
  }
  return pusherInstance;
};

export const createPusherChannel = ({
  channelId = "",
  eventTypes = [],
  withEventTypes = false,
  decoratePayload = R.identity,
}) => {
  const pusher = getPusherInstance();
  return eventChannel((emit) => {
    const channel = pusher.subscribe(channelId);
    const errorHandler = (error) => emit(new Error(error));

    channel.bind("pusher:subscription_error", errorHandler);

    for (let event of eventTypes) {
      // eslint-disable-next-line no-unused-expressions
      channel.bind(event, (data) =>
        emit(
          decoratePayload(withEventTypes ? { eventType: event, data } : data),
        ),
      );
    }

    return () => {
      channel.unsubscribe();
      channel.unbind("pusher:subscription_error", errorHandler);
    };
  });
};
