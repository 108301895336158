import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { CREATE as CREATE_APPROVAL } from "redux/modules/approvalWorkflows/reviews/constants";
import { INVALIDATE as INVALIDATE_RECORD } from "redux/modules/modules/record/constants";
import { INVALIDATE as INVALIDATE_CONTACT } from "redux/modules/contacts/profile/constants";
import { INVALIDATE as INVALIDATE_ACCOUNT } from "redux/modules/accounts/profile/constants";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import * as R from "ramda";
import formatApprovalManifestForRecord from "utils/format-approval-manifest-for-record";

const updateValue = (
  state,
  immutableState,
  { parentRecordId, moduleId, recordId, fieldId, value },
) => {
  const indexOfRecord = state[moduleId].records.findIndex(
    (r) => r.id === recordId,
  );

  if (indexOfRecord === -1) {
    return immutableState;
  }

  return immutableState.setIn(
    [parentRecordId, moduleId, "records", indexOfRecord, "values", fieldId],
    value,
  );
};

const updateApprovalValue = (
  state,
  immutableState,
  { parentRecordId, moduleId, recordId, fieldId, userId, status },
) => {
  if (!state[moduleId]) return immutableState;

  const indexOfRecord = state[moduleId].records.findIndex(
    (r) => r.id === recordId,
  );

  if (indexOfRecord === -1) return immutableState;

  const manifest = state[moduleId].records[indexOfRecord].values[fieldId].value;

  if (!manifest) return immutableState;

  const approverIndex = R.findIndex(R.propEq("user_id", userId))(
    manifest.all_approvers,
  );

  if (approverIndex === -1) return immutableState;

  const formatted = formatApprovalManifestForRecord({
    approval_manifest: {
      id: manifest.id,
      approvers: R.compose(
        R.set(R.lensPath([approverIndex, "reviewed_at"]), new Date()),
        R.set(R.lensPath([approverIndex, "review"]), status),
      )(manifest.all_approvers),
    },
  });

  return immutableState.setIn(
    [parentRecordId, moduleId, "records", indexOfRecord, "values", fieldId],
    {
      type: "approval",
      value: formatted,
    },
  );
};

const records = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.recordId,
            action.payload.moduleId,
            action.payload.fieldId,
          ],
          action.payload.records,
        )
        .toJS();

    case INVALIDATE_RECORD:
    case INVALIDATE_CONTACT:
    case INVALIDATE_ACCOUNT:
      return Immutable.fromJS(state).deleteIn([action.payload.recordId]).toJS();

    case CREATE_APPROVAL: {
      return Object.keys(state)
        .reduce((immutableState, parentRecordId) => {
          const moduleIds = Object.keys(state[parentRecordId]);
          moduleIds
            .filter((m) => m === action.payload.moduleId)
            .forEach((moduleId) => {
              immutableState = updateApprovalValue(
                state[parentRecordId],
                immutableState,
                {
                  parentRecordId,
                  moduleId,
                  recordId: action.payload.recordId,
                  fieldId: SYSTEM_FIELD_IDS.APPROVAL,
                  userId: action.payload.userId,
                  status: action.payload.status,
                },
              );
            });
          return immutableState;
        }, Immutable.fromJS(state))
        .toJS();
    }

    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.recordId,
            action.payload.moduleId,
            action.payload.fieldId,
          ],
          true,
        )
        .toJS();
    case ERROR:
      // @NOTE: On error of any fetching, we clear out all fetching states since
      // we don't know where exactly we failed
      return {};
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [
            action.payload.recordId,
            action.payload.moduleId,
            action.payload.fieldId,
          ],
          false,
        )
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  records,
  fetching,
});
