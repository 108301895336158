import * as R from "ramda";
import { withStateHandlers, mapProps, findBy, noop } from "utils/General";

const tabStateHandler = R.compose(
  withStateHandlers(
    ({ defaultTabValue = "" }) => ({
      activeTabValue: defaultTabValue,
    }),
    {
      setActiveTabValue:
        (_, { tabs }) =>
        (newValue) => {
          const { onClick = noop } = findBy("value", newValue)(tabs);
          onClick();
          return {
            activeTabValue: newValue,
          };
        },
    },
  ),
  mapProps(({ setActiveTabValue, activeTabValue, tabs = [], ...props }) => ({
    tabs: R.map(
      ({ onClick = noop, value, component, ...other }) => ({
        onClick: () => {
          if (activeTabValue === value) {
            noop();
          } else {
            setActiveTabValue(value);
            onClick();
          }
        },
        active: activeTabValue === value,
        value,
        ...other,
      }),
      tabs,
    ),
    activeComponent: R.prop("component", findBy("value", activeTabValue)(tabs)),
    ...props,
  })),
);

export { tabStateHandler };
