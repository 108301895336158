import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        isExternal: true,

        // non-secured endpoint
        url: `/user`,
        credentials,
        data,
        success: (result) => {
          resolve(result);
        },
        error: (err) => {
          reject(err);
        },
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  // @NOTE: This is used by portal logins for converting virtual users
  convert: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        isExternal: true,

        // non-secured endpoint
        url: `/user/convert`,
        credentials,
        data,
        success: (result) => {
          resolve(result);
        },
        error: (err) => {
          reject(err);
        },
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  createPortalUsersFromContacts: (credentials, eventId, contacts, save) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `/portal/event/${eventId}/users/contacts`,
        credentials,
        data: {
          contacts,
          save,
        },
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  createPortalUserFromContact: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `/portal/event/${data.eventId}/users/contact/${data.contactId}`,
        credentials,
        data,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getLoggedInUserDetails: (credentials) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        skipUserId: true,
        method: "get",
        url: `/user/me`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  updateUser: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        skipUserId: true,
        method: "put",
        data,
        url: `/user/me`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
};
