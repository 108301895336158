import * as Colors from "@mui/material/colors";

export default {
  fontFamily: `Mulish, -apple-system, system-ui, Helvetica Neue, Helvetica, Arial, sans-serif`,
  palette: {
    primary1Color: "#425cc3",
    primary2Color: Colors.deepPurple[400],
    primary3Color: "rgba(0, 0, 0, 0.54)",
    accent1Color: Colors.deepPurple[400],
    accent2Color: Colors.grey[100],
    accent3Color: Colors.grey[500],
    textColor: "rgba(0, 0, 0, 0.54)",
    alternateTextColor: Colors.common.white,
    canvasColor: Colors.common.white,
    borderColor: Colors.grey[300],
    disabledColor: "rgba(0, 0, 0, 0.87)",
  },
  zIndex: {
    popover: 2101,
    layer: 2100,
    modal: 999,
  },
};
