import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/user/me/access-levels`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
};
