import React from "react";
import { Div, SmallCheckbox, AvatarLabel } from "../../index";

const CheckboxAvatarLabel = ({
  selected,
  toggle,
  frontText,
  backText,
  photoURL,
}) => {
  return (
    <Div display={"row.flex-start.center"}>
      <SmallCheckbox selected={selected} onClick={toggle} />
      <AvatarLabel
        frontText={frontText}
        photoURL={photoURL}
        backText={backText}
        py={1}
        ml={1}
      />
    </Div>
  );
};

export { CheckboxAvatarLabel };
