import React from "react";
import * as R from "ramda";
import { Div, NoneIcon, UpFilledIcon, DownFilledIcon } from "../index";
import { mapProps } from "../utils";
import { collapsableHandler, Collapsable } from "./Collapsable";
import { Cards } from "./Sortable";
import { keySorter } from "./keySorter";

const addSortIcon = mapProps(
  ({ sortDirection, toggleSort, sortKey, headerData, ...props }) => {
    let SortIcon = NoneIcon;
    if (sortDirection === "Asc") {
      SortIcon = DownFilledIcon;
    } else if (sortDirection === "Desc") {
      SortIcon = UpFilledIcon;
    }
    return {
      headerData: {
        toggleSort,
        sortKey,
        sortDirection,
        SortIcon,
        ...headerData,
      },
      ...props,
    };
  },
);

const rowsDataSorter = (
  sorter,
  { rowsData = [], rowsCollapsableData = [], ...other },
) => {
  const injectCollapsable = R.addIndex(R.map)((props, i) => ({
    ...props,
    collapsableData: rowsCollapsableData[i],
  }));

  const ejectCollapsable = R.addIndex(R.map)((
    { collapsableData, ...props },
    i,
  ) => {
    rowsCollapsableData[i] = collapsableData;
    return props;
  });

  return {
    rowsData: R.compose(ejectCollapsable, sorter, injectCollapsable)(rowsData),
    rowsCollapsableData,
    ...other,
  };
};

const RegularRow = ({
  rowsData,
  columnProps,
  rowCellComps,
  RowStyler,
  RowCellStyler,
}) => (
  <RowStyler {...rowsData}>
    {rowCellComps.map((RowCellComp, i) => (
      <RowCellStyler {...columnProps[i]} key={i}>
        <RowCellComp {...rowsData} />
      </RowCellStyler>
    ))}
  </RowStyler>
);

const CollapsableRow = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    rowsData,
    columnProps,
    rowsCollapsableData,
    rowCellComps,
    RowStyler,
    RowCellStyler,
    RowCollapsableComp,
    CollapsableStyler,
  }) => {
    return (
      <CollapsableStyler>
        <RowStyler
          {...rowsData}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        >
          {rowCellComps.map((RowCellComp, i) => (
            <RowCellStyler {...columnProps[i]} key={i}>
              <RowCellComp
                {...rowsData}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
              />
            </RowCellStyler>
          ))}
        </RowStyler>
        <Collapsable collapsed={collapsed}>
          <RowCollapsableComp {...rowsCollapsableData} />
        </Collapsable>
      </CollapsableStyler>
    );
  },
);

const makeTable = ({
  TableStyler = Div,
  HeaderStyler = Div,
  RowStyler = Div,
  HeaderCellStyler = Div,
  RowCellStyler = Div,
  CollapsableStyler = Div,
  RowCollapsableComp = Div,
  RowsSectionStyler = Div,
  Footer = Div,
  columnProps = [],
  headerCellComps = [],
  rowCellComps = [],
  // onSort = []
}) =>
  keySorter(rowsDataSorter)(
    addSortIcon(
      ({
        headerData = {},
        rowsData = [],
        rowsCollapsableData = [],
        onReorder,
        dragTypeId,
        emptyState,
        // sortKey,
        footerData,
        // setSortKey
      }) => {
        const Row = ({ index }) => {
          const sharedProps = {
            key: index,
            rowsData: rowsData[index],
            columnProps: columnProps,
            rowCellComps: rowCellComps,
            RowStyler: RowStyler,
            RowCellStyler: RowCellStyler,
          };
          return R.isNil(rowsCollapsableData[index]) ? (
            <RegularRow {...sharedProps} />
          ) : (
            <CollapsableRow
              {...sharedProps}
              rowsCollapsableData={rowsCollapsableData[index]}
              collapsedDefault={R.propOr(
                true,
                "collapsedDefault",
                rowsCollapsableData[index],
              )}
              RowCollapsableComp={RowCollapsableComp}
              CollapsableStyler={CollapsableStyler}
            />
          );
        };
        const Rows =
          R.isNil(onReorder) || R.isNil(dragTypeId)
            ? () => (
                <div>
                  {rowsData.map((data, j) => (
                    <Row key={data.id ?? j} index={j} />
                  ))}
                </div>
              )
            : Cards(Row, dragTypeId);

        return (
          <TableStyler>
            <HeaderStyler>
              {headerCellComps.map((HeaderCellComp, i) => (
                <HeaderCellStyler {...columnProps[i]} key={i}>
                  <HeaderCellComp {...headerData} />
                </HeaderCellStyler>
              ))}
            </HeaderStyler>
            <RowsSectionStyler>
              {emptyState && !rowsData.length ? (
                emptyState
              ) : (
                <Rows cards={rowsData} onReorder={onReorder} />
              )}
            </RowsSectionStyler>
            <Footer {...footerData} />
          </TableStyler>
        );
      },
    ),
  );

export { makeTable };
