import Immutable from "immutable";
import { REQUEST, RECEIVE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { combineReducers } from "redux";
import addListOfPermissionSlugs from "../utils/add-list-of-permission-slugs";

const userProfile = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.key, action.payload.userId],
          action.payload.permissionProfile
            ? addListOfPermissionSlugs(action.payload.permissionProfile)
            : null,
        )
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn([action.payload.key, action.payload.userId], true)
        .toJS();
    case ERROR:
      // @NOTE: On error of any fetching, we clear out all fetching states since
      // we don't know where exactly we failed
      return {};
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.key, action.payload.userId], false)
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  userProfile,
  fetching,
});
