import { put, select, call } from "redux-saga/effects";
import * as R from "ramda";

import { userId as getUserId } from "redux/modules/user/selectors";

import { actions } from "../model";
import { apiCall } from "./utils";

export const review = function* ({
  response,
  targetType,
  recordIds,
  transactionId,
}) {
  yield put(actions.fetching(transactionId));
  const userId = yield select(getUserId);

  try {
    // make review
    const res = yield call(apiCall, {
      method: response === "undoReview" ? "delete" : "post",
      url: "/approval-workflows/reviews",
      data: {
        userId,
        bulk: true,
        reviews: R.map(
          (id) => ({
            [targetType]: id,
            response,
            userId,
          }),
          recordIds,
        ),
      },
      qs: {
        userId,
      },
    });

    return res;
  } catch (error) {
    yield put(actions.error(error, { meta: { transactionId } }, true));
    throw error;
  } finally {
    yield put(actions.fetching(transactionId));
  }
};
