import React from "react";
import {
  Div,
  SmallOutlineInput,
  SmallOutlineButton,
  SmallFilledButton,
  SmallShadedButton,
  MediumOutlineInput,
  MediumOutlineButton,
  MediumFilledButton,
  MediumShadedButton,
  BigOutlineInput,
  BigOutlineButton,
  BigFilledButton,
  BigShadedButton,
  RemoveIcon,
  AddIcon,
} from "../index";

const Sizes = {
  Small: {
    width: 88,
    OutlineInput: SmallOutlineInput,
    OutlineButton: SmallOutlineButton,
    FilledButton: SmallFilledButton,
    ShadedButton: SmallShadedButton,
  },
  Medium: {
    width: 100,
    OutlineInput: MediumOutlineInput,
    OutlineButton: MediumOutlineButton,
    FilledButton: MediumFilledButton,
    ShadedButton: MediumShadedButton,
  },
  Big: {
    width: 145,
    OutlineInput: BigOutlineInput,
    OutlineButton: BigOutlineButton,
    FilledButton: BigFilledButton,
    ShadedButton: BigShadedButton,
  },
};

const NumberSelector = ({
  size = "Small",
  value,
  onChangeValue,
  maxValue,
  minValue,
  disabled,
  WrapperProps = {},
  color = "primary8",
}) => {
  const { OutlineInput, OutlineButton, FilledButton, ShadedButton, width } =
    Sizes[size];

  return (
    <Div width={width} display={"row.center.center"} {...WrapperProps}>
      {value > 0 ? (
        <Div width={1} display={"row.center.center"}>
          <ShadedButton
            type="button"
            style={{
              flexShrink: 0,
            }}
            mr={1 / 2}
            color={"neutral7"}
            disabled={disabled}
            OnlyIcon={RemoveIcon}
            onClick={() => onChangeValue(value - 1)}
          />
          <OutlineInput
            flex={1}
            inputStyle={{
              textAlign: "right",
            }}
            disabled={disabled}
            continuous
            value={value}
            max={maxValue}
            min={minValue}
            onChange={(x) => onChangeValue(parseInt(x, 10))}
            color="neutral7"
            bg="white"
          />
          <FilledButton
            type="button"
            style={{
              flexShrink: 0,
            }}
            ml={1 / 2}
            disabled={disabled || value >= maxValue}
            OnlyIcon={AddIcon}
            bg={color}
            onClick={() => onChangeValue(value + 1)}
          />
        </Div>
      ) : (
        <OutlineButton
          type="button"
          disabled={disabled || maxValue === 0}
          flex={1}
          OnlyIcon={AddIcon}
          onClick={() => onChangeValue(1)}
          color={disabled || maxValue === 0 ? "gray6" : color}
          bg="white"
        />
      )}
    </Div>
  );
};

const MediumNumberSelector = (props) => (
  <NumberSelector {...props} size="Medium" />
);

const BigNumberSelector = (props) => <NumberSelector {...props} size="Big" />;

export { NumberSelector, MediumNumberSelector, BigNumberSelector };
