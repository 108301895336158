import Immutable from "immutable";
import { RECEIVE } from "./constants";

const assignments = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.formId], action.payload.assignments)
        .toJS();
    default:
      return state;
  }
};

export default assignments;
