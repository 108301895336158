import Immutable from "immutable";
import { RECEIVE } from "./constants";

const setup = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.eventId], action.payload.result)
        .toJS();
    default:
      return state;
  }
};

export default setup;
