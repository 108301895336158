import React from "react";
import { Div } from "../index";
import { withProps } from "../utils";

const BadgeWrapper = ({ children, style, ...props }) => {
  return (
    <Div
      display={"inline-block"}
      style={{
        position: "relative",
        ...style,
      }}
      {...props}
    >
      {children}
    </Div>
  );
};

const topLeftTransform = {
  transform: "translate(-50%, -50%)",
};
const topRightTransform = {
  right: 0,
  transform: "translate(50%, -50%)",
};
const bottomLeftTransform = {
  bottom: 0,
  transform: "translate(-50%, 50%)",
};
const bottomRightTransform = {
  bottom: 0,
  right: 0,
  transform: "translate(50%, 50%)",
};
const rightTransform = {
  bottom: "50%",
  right: 0,
  transform: "translate(50%, 50%)",
};
const leftTransform = {
  bottom: "50%",
  left: 0,
  transform: "translate(50%, 50%)",
};

const makeBadge = (additional) =>
  withProps({
    style: {
      position: "absolute",
      ...additional,
    },
  });

const makeTopLeftBadge = makeBadge(topLeftTransform);
const makeTopRightBadge = makeBadge(topRightTransform);
const makeBottomLeftBadge = makeBadge(bottomLeftTransform);
const makeBottomRightBadge = makeBadge(bottomRightTransform);
const makeRightBadge = makeBadge(rightTransform);
const makeLeftBadge = makeBadge(leftTransform);

export {
  BadgeWrapper,
  makeRightBadge,
  makeLeftBadge,
  makeTopLeftBadge,
  makeTopRightBadge,
  makeBottomLeftBadge,
  makeBottomRightBadge,
};

// This does not render properly, yet
/*

const makeBadgeGroup = cornerProps => ({ children, style, ...props }) => {
  return (
    <Div
      display={"inline-block"}
      style={{
        position: "relative",
        ...style
      }}
      {...props}
    >
      {React.cloneElement(children[0], {
        style: { ...children[0].props.style, ...cornerProps }
      })}
      {React.cloneElement(children[1])}
    </Div>
  );
};

const TopLeftBadge = makeBadgeGroup(topLeftTransform);
const TopRightBadge = makeBadgeGroup(topRightTransform);
const BottomLeftBadge = makeBadgeGroup(bottomLeftTransform);
const BottomRightBadge = makeBadgeGroup(bottomRightTransform);


const AvatarWithDotBadge = () => {
  return (
    <TopLeftBadge>
      <FullSizeDotIcon size={10} color={"red5"} />
      <SmallAvatar text={"John Doe"} />
    </TopLeftBadge>
  );
}
*/
