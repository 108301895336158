import * as R from "ramda";
import { noop } from "utils/General";

const makeSubscribe = ({ globalStore, observedDomains, ns }) => {
  let lastState = globalStore.getState();

  return (store) => () => {
    store.dispatch({
      type: `${(ns && ns + "/") || ns}GlobalUpdate`,
      payload: R.pick(observedDomains, lastState),
    });

    return globalStore.subscribe(() => {
      const newState = globalStore.getState();

      observedDomains.forEach((key) => {
        if (newState[key] !== lastState[key]) {
          store.dispatch({
            type: `${(ns && ns + "/") || ns}GlobalUpdate`,
            payload: R.pick(observedDomains, newState),
          });
          lastState = newState;
          return;
        }
      });
    });
  };
};

export const bridge =
  (observedDomains, dispatchToGlobalActionsFilter, module) => (globalStore) => {
    // TODO: add validations

    const filter =
      typeof dispatchToGlobalActionsFilter === "function"
        ? dispatchToGlobalActionsFilter
        : R.compose(
            R.not,
            R.test(new RegExp(`(${R.join("|", module.modules)})\/`)),
            R.prop("type"),
          );

    let subscription = noop;
    let subscribe = noop;

    const middleware = (store) => {
      subscribe = makeSubscribe({
        globalStore,
        observedDomains,
        ns: module.namespace,
      })(store);

      if (filter !== noop) {
        // eslint-disable-next-line consistent-return
        return (next) => (action) => {
          if (filter(action)) {
            if (typeof action === "function") {
              // send thunks only to global
              return globalStore.dispatch(action);
            } else {
              const result = next(action);
              globalStore.dispatch(action);
              return result;
            }
          } else {
            return next(action);
          }
        };
      }

      return R.identity;
    };

    return {
      middleware,
      subscribe: () => {
        subscription = subscribe();
      },
      unsubscribe: () => subscription(),
    };
  };
