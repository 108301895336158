import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";

const records = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return { ...state, [action.recordId]: action.payload };
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  records,
  fetching,
});
