import React from "react";
import { Div, TinyOutlineBox, TinyFilledBox } from "../index";
import { mapProps } from "../utils";

const makeLabeledToggle =
  (labels, specs) =>
  ({ isLeft, onClick }) => {
    const { Left, Right, leftBg, rightBg } = specs[isLeft ? 0 : 1];
    const Bg = isLeft ? rightBg : leftBg;

    return (
      <Div
        style={{
          flexShrink: 0,
        }}
        flex={0}
        bra={1}
        display={"inline-row.center.center"}
        ba={2}
        onClick={onClick}
        bg={Bg}
        bc={Bg}
      >
        <Left bg={leftBg}>{labels["left"]}</Left>
        <Right bg={rightBg}>{labels["right"]}</Right>
      </Div>
    );
  };

const TinyAndOrToggle = mapProps(({ isAnd, ...props }) => ({
  isLeft: isAnd,
  ...props,
}))(
  makeLabeledToggle(
    {
      left: "And",
      right: "Or",
    },
    [
      {
        Left: TinyOutlineBox,
        Right: TinyFilledBox,
        leftBg: "white",
        rightBg: "neutral4",
      },
      {
        Left: TinyFilledBox,
        Right: TinyFilledBox,
        leftBg: "primary4",
        rightBg: "primary8",
      },
    ],
  ),
);

export { TinyAndOrToggle, makeLabeledToggle };
