import { createModule } from "redux-mvc";

import model from "./model";

import rootSaga from "./sagas";

const thisModule = createModule({
  ...model,
  observedDomains: ["user", "event"],
});

thisModule.setRootSaga(rootSaga);

export default thisModule;
