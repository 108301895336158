const MAX_NUMBER_OF_REDIRECTS_TO_SHOW_SSO_MESSAGE = 3;

export function getOriginalUrl() {
  let returnUrl;

  try {
    if (window.location?.search) {
      const returnPart = window.location.search
        .split("&")
        .find((s) => s.includes("returnTo"));
      if (returnPart) {
        const encodedPart = returnPart.split("=")[1];
        returnUrl = `${decodeURIComponent(encodedPart)}`;
      }
    }
  } catch {
    // ignore errors, let the default redirect to /home take over
  }

  if (!returnUrl) {
    return `${window.__LIVE_APP_URL__}/home?loggedIn=true`;
  }

  if (!returnUrl.includes("http")) {
    returnUrl = `${window.__LIVE_APP_URL__}${returnUrl}`;
  }

  return returnUrl;
}

export function getSSoLoginParams({ numPreviousRedirects }) {
  const appState = { originalUrl: getOriginalUrl() };
  const urlEncodedAppState = encodeURIComponent(JSON.stringify(appState));
  const redirectUri = `${window.__LIVE_APP_URL__}/auth/classy-sso`;

  const ssoLoginParams = {
    client_id: window.__CLASSY_SSO_CLIENT_ID__,
    okta_client_id: window.__OKTA_CLIENT_ID__,
    response_type: "code",
    state: urlEncodedAppState,
    redirect_uri: redirectUri,
    mode: "json",
    okta_scope: `organization:write member:read member:write api-app:read api-app:write me offline_access openid service-provider:read service-provider:write`,
    app_cookie_name: "clive",
    ...(numPreviousRedirects < MAX_NUMBER_OF_REDIRECTS_TO_SHOW_SSO_MESSAGE
      ? {
          message:
            "To access Classy Live, log in with your Classy account email and password.",
        }
      : {}),
  };

  return ssoLoginParams;
}
