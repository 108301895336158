import { combineReducers } from "redux";
import {
  RECEIVE_EVENT,
  RECEIVE_GROUP,
  RECEIVE_SETTINGS,
  REQUEST,
  ERROR,
  CLEAR_ERROR,
} from "./constants";
import people from "./people/reducer";
import resources from "./resources/reducer";

const group = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_GROUP:
      return action.payload;
    default:
      return state;
  }
};
const event = (state = { date_groups: [], catering: {} }, action) => {
  switch (action.type) {
    case RECEIVE_EVENT:
      return action.payload;
    default:
      return state;
  }
};
const settings = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};
const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE_GROUP:
    case RECEIVE_EVENT:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  event,
  people,
  resources,
  settings,
  group,
  error,
  fetching,
});
