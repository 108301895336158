const ns = "FORMS_V2_SUBMISSION";
export const RECEIVE = `${ns}_RECEIVE`;
export const REQUEST = `${ns}_REQUEST`;
export const CREATE = `${ns}_CREATE`;
export const CREATE_COMPLETE = `${ns}_CREATE_COMPLETE`;
export const UPDATE = `${ns}_UPDATE`;
export const UPDATE_COMPLETE = `${ns}_UPDATE_COMPLETE`;
export const DELETE = `${ns}_DELETE`;
export const ERROR = `${ns}_ERROR`;
export const CLEAR_ERROR = `${ns}_CLEAR_ERROR`;
export const INVALIDATE = `${ns}_INVALIDATE`;
export const REFRESH_RELATED_ORDER = `${ns}_REFRESH_RELATED_ORDER`;
export const REFRESH_PEOPLE_BLOCK_ORDER = `${ns}_REFRESH_PEOPLE_BLOCK_ORDER`;

export const CREATE_SUBFORM = `${ns}_CREATE_SUBFORM`;
export const CREATE_SUBFORM_COMPLETE = `${ns}_CREATE_SUBFORM_COMPLETE`;
export const CREATE_SUBFORM_DEFAULT = `${ns}_CREATE_SUBFORM_DEFAULT`;
export const CREATE_SUBFORM_DEFAULT_COMPLETE = `${ns}_CREATE_SUBFORM_DEFAULT_COMPLETE`;
export const CLONE_SUBFORM = `${ns}_CLONE_SUBFORM`;
export const CLONE_SUBFORM_COMPLETE = `${ns}_CLONE_SUBFORM_COMPLETE`;
export const CLONE_SUBFORM_DEFAULT = `${ns}_CLONE_SUBFORM_DEFAULT`;
export const CLONE_SUBFORM_DEFAULT_COMPLETE = `${ns}_CLONE_SUBFORM_DEFAULT_COMPLETE`;
export const BULK_DELETE_SUBFORM_DEFAULT = `${ns}_BULK_DELETE_SUBFORM_DEFAULT`;
export const BULK_DELETE_SUBFORM = `${ns}_BULK_DELETE_SUBFORM`;
