import { lenndSVGs } from "./lenndSVGs";
import { materialSVGs } from "./materialSVGs";
import { featherSVGs } from "./featherSVGs";
import { samHerbertSVGs } from "./samHerbertSVGs";
import { specialSVGs } from "./specialSVGs";

const iconNameMapping = {
  none: lenndSVGs.none,

  // special
  donut: specialSVGs.donut,
  threeCircles: specialSVGs.threeCircles,
  addBlocks: specialSVGs.addBlocks,
  // loading
  loading: samHerbertSVGs.tailSpinLoader,

  // arrows
  up: materialSVGs.keyboard_arrow_up,
  right: materialSVGs.keyboard_arrow_right,
  down: materialSVGs.keyboard_arrow_down,
  left: materialSVGs.keyboard_arrow_left,
  upFilled: materialSVGs.arrow_drop_up,
  downFilled: materialSVGs.arrow_drop_down,
  leftArrow: materialSVGs.arrow_back,
  rightArrow: materialSVGs.arrow_forward,
  upArrow: materialSVGs.arrow_upward,
  downArrow: materialSVGs.arrow_downward,
  expand: lenndSVGs.expand,
  expandVert: lenndSVGs.expandVert,
  cheveronsRight: featherSVGs.cheveronsRight,
  cheveronsLeft: featherSVGs.cheveronsLeft,
  // materialSVGs['expand_less'],
  // materialSVGs['expand_more'],
  // materialSVGs['play_arrow'],
  // materialSVGs['keyboard_backspace'],
  // materialSVGs['keyboard_return'],

  // add
  add: materialSVGs.add,
  addBox: materialSVGs.add_box,
  addCircle: materialSVGs.control_point,
  addCircleMulti: materialSVGs.control_point_duplicate,
  addList: materialSVGs.playlist_add,
  addPhoto: materialSVGs.add_a_photo,
  addPerson: materialSVGs.person_add,

  // check
  check: materialSVGs.check,
  checkBox: materialSVGs.check_box,
  checkBoxBlank: materialSVGs.check_box_outline_blank,
  indeterminateCheckBox: materialSVGs.indeterminate_check_box,
  checkCircle: materialSVGs.check_circle,

  // copy
  copy: featherSVGs.copy,

  // close
  delete: materialSVGs.delete, // deleteForever: materialSVGs['delete_forever'],
  done: materialSVGs.done,
  doneMulti: materialSVGs.done_all,
  cancel: materialSVGs.cancel,
  close: materialSVGs.close,
  remove: materialSVGs.remove,

  // abstract shapes
  drag: lenndSVGs.drag, // materialSVGs['drag_handle'],
  more: materialSVGs.more_horiz,
  moreVert: materialSVGs.more_vert,
  menu: materialSVGs.menu,
  list: materialSVGs.list, // materialSVGs['view_list'],
  agenda: materialSVGs.view_agenda,
  column: materialSVGs.view_column,
  filter: materialSVGs.filter_list,
  grid: materialSVGs.grid_on,
  inverseGrid: lenndSVGs.inverseGrid,
  shortText: materialSVGs.short_text,
  lineStyle: materialSVGs.line_style,
  group: materialSVGs.group_work,
  scan: materialSVGs.center_focus_weak,

  // block: lenndSVGs.block,
  fullSizeDot: lenndSVGs.fullSizeDot,
  threeQuarterSizeDot: lenndSVGs.threeQuarterSizeDot,
  halfSizeDot: lenndSVGs.halfSizeDot,
  quarterSizeDot: lenndSVGs.quarterSizeDot,
  eigthSizeDot: lenndSVGs.eigthSizeDot,
  fullSizeSquare: lenndSVGs.fullSizeSquare,
  threeQuarterSizeSquare: lenndSVGs.threeQuarterSizeSquare,
  halfSizeSquare: lenndSVGs.halfSizeSquare,
  quarterSizeSquare: lenndSVGs.quarterSizeSquare,
  eigthSizeSquare: lenndSVGs.eigthSizeSquare,
  circleHalfFilled: lenndSVGs.circleHalfFilled,
  circle: lenndSVGs.circle,
  searchFolder: lenndSVGs.searchFolder,

  // other
  info: materialSVGs.info, // materialSVGs['attachment'],
  attach: materialSVGs.attach_file, // materialSVGs['attachment'],
  location: materialSVGs.room,
  clock: featherSVGs.clock,
  alignCenter: featherSVGs.alignCenter,
  code: materialSVGs.code,
  mail: materialSVGs.mail_outline, // materialSVGs['markunread'],
  send: materialSVGs.send,
  calendar: materialSVGs.date_range,
  calendarClose: materialSVGs.event_busy,
  creditCard: materialSVGs.credit_card,
  assignment: materialSVGs.assignment,
  assignmentTurnedIn: materialSVGs.assignment_turned_in,
  assignmentIndividual: materialSVGs.assignment_ind,
  cloudUpload: materialSVGs.cloud_upload,
  upload: materialSVGs.file_upload,
  download: materialSVGs.get_app,
  search: materialSVGs.search,
  settings: materialSVGs.settings,
  share: materialSVGs.share,
  starOutline: materialSVGs.star_border,
  star: materialSVGs.star,
  link: materialSVGs.link,
  home: materialSVGs.home,
  lock: materialSVGs.lock_outline,
  lockOpen: materialSVGs.lock_open,
  people: materialSVGs.people,
  person: materialSVGs.person,
  image: materialSVGs.photo,
  print: materialSVGs.print,
  openInNew: materialSVGs.open_in_new,
  visible: materialSVGs.visibility,
  notVisible: materialSVGs.visibility_off,
  key: materialSVGs.vpn_key,
  error: materialSVGs.error, // materialSVGs['warning'],
  warning: materialSVGs.warning,
  history: materialSVGs.restore,
  reply: materialSVGs.reply,
  refresh: materialSVGs.refresh, // materialSVGs['cached'],
  edit: materialSVGs.mode_edit,

  // module exclusive icons
  money: materialSVGs.attach_money,
  airplane: materialSVGs.flight,
  map: materialSVGs.map,
  receipt: materialSVGs.receipt,
  music: materialSVGs.audiotrack,
  starFilledCircle: materialSVGs.stars,
  van: materialSVGs.airport_shuttle,
  truck: materialSVGs.local_shipping,
  mic: materialSVGs.mic,
  building: materialSVGs.domain,
  wrench: materialSVGs.build,
  bag: materialSVGs.business_center,
  restaurant: materialSVGs.restaurant, // materialSVGs['local_dining'],
  puzzle: materialSVGs.extension,
  alarm: materialSVGs.alarm,
  seat: materialSVGs.event_seat,
  compass: materialSVGs.explore,
  segmentedLine: materialSVGs.timeline,
  powerPlug: materialSVGs.power,
};

export { iconNameMapping };
