const localStorageKey = `classySsoToken-${window.__CLASSY_LIVE_APP_ENV__}`;
const ssoLoginRedirectCounterKey = `classySsoLoginRedirectCount-${window.__CLASSY_LIVE_APP_ENV__}`;

function getSsoCredentials() {
  return localStorage.getItem(localStorageKey);
}

function setSsoCredentials(credentials) {
  localStorage.setItem(localStorageKey, credentials);
}

function clearSsoCredentials() {
  localStorage.removeItem(localStorageKey);
}

const ssoLoginRedirectCounter = {
  getCount: () => +localStorage.getItem(ssoLoginRedirectCounterKey),
  incrementCount: () => {
    // +null == 0, so no need to explicitly initialize the count as 0
    const currCount = +localStorage.getItem(ssoLoginRedirectCounterKey);
    localStorage.setItem(
      ssoLoginRedirectCounterKey,
      (currCount + 1).toString(),
    );
  },
};

export {
  getSsoCredentials,
  setSsoCredentials,
  clearSsoCredentials,
  ssoLoginRedirectCounter,
};
