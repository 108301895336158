import { combineReducers } from "redux";
import Immutable from "immutable";
import {
  SYNC,
  SYNC_COMPLETE,
  FETCH,
  RECEIVE,
  RECEIVE_APPEND,
  UPDATE_META,
} from "./constants";

const list = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.values;
    case RECEIVE_APPEND:
      return {
        ...state,
        ...action.payload.values,
      };
    default:
      return state;
  }
};

const syncing = (state = false, action) => {
  switch (action.type) {
    case SYNC:
      return true;
    case SYNC_COMPLETE:
      return false;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case FETCH:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const references = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .mergeDeep(Immutable.fromJS(action.payload.references))
        .toJS();
    default:
      return state;
  }
};

const fields = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.fields;
    default:
      return state;
  }
};

const meta = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_META:
    case RECEIVE:
      return action.payload.meta || state;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  references,
  fields,
  meta,
  fetching,
  syncing,
});
