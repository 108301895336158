import Immutable from "immutable";
import { combineReducers } from "redux";
import { get } from "lodash";
import * as R from "ramda";
import {
  RECEIVE,
  REQUEST,
  CREATE_SUBFORM_COMPLETE,
  CLONE_SUBFORM_COMPLETE,
  BULK_DELETE_SUBFORM,
  UPDATE,
  UPDATE_COMPLETE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE,
  REFRESH_RELATED_ORDER,
  REFRESH_PEOPLE_BLOCK_ORDER,
} from "./constants";
import {
  CREATE as VALUE_CREATE,
  CREATE_COMPLETE as VALUE_CREATE_COMPLETE,
  BULK_CREATE as BULK_VALUE_CREATE,
  BULK_CREATE_COMPLETE as BULK_VALUE_CREATE_COMPLETE,
  CREATE_SUBFORM as SUBFORM_VALUE_CREATE,
  BULK_CREATE_SUBFORM as SUBFORM_VALUE_BULK_CREATE,
} from "./values/constants";
import {
  CREATE as REVIEW_CREATE,
  CREATE_COMPLETE as REVIEW_CREATE_COMPLETE,
  BULK_CREATE as REVIEW_BULK_CREATE,
  BULK_CREATE_COMPLETE as REVIEW_BULK_CREATE_COMPLETE,
} from "./reviews/constants";
import {
  FIELD_WIDTH_UPDATE as PREFERENCES_FIELD_WIDTH_UPDATE,
  ROW_ORDER_UPDATE as PREFERENCES_ROW_ORDER_UPDATE,
} from "../form/preferences/constants";
import { CREATE_COMPLETE as CONFIRMATION_CREATE_COMPLETE } from "./confirmation/constants";
import { CREATE_COMPLETE as DONE_CREATE_COMPLETE } from "./done/constants";
import { CREATE as CREATE_APPROVAL } from "./approvals/constants";
import {
  ADD as ADD_ITEM_TO_CART,
  REMOVE as REMOVE_ITEM_FROM_CART,
  ADD_COMPLETE as ADD_ITEM_TO_CART_COMPLETE,
  REMOVE_COMPLETE as REMOVE_ITEM_FROM_CART_COMPLETE,
} from "redux/modules/formsV2/submission/cart/constants";
import {
  ADD as ADD_PERSON,
  REMOVE as REMOVE_PERSON,
  ADD_COMPLETE as ADD_PERSON_COMPLETE,
  REMOVE_COMPLETE as REMOVE_PERSON_COMPLETE,
  SAVE_VALUE_COMPLETE as SAVE_PERSON_VALUE_COMPLETE,
} from "redux/modules/formsV2/people-blocks/people/constants";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import formatApprovalManifestForRecord from "utils/format-approval-manifest-for-record";

const updateApprovalValue = (
  state,
  immutableState,
  { fieldId, userId, status },
) => {
  const valueLens = R.lensPath([
    "module_record",
    "record",
    "values",
    fieldId,
    "value",
  ]);

  const manifest = R.view(valueLens)(state);

  if (!manifest) return immutableState;

  const approverIndex = R.findIndex(R.propEq("user_id", userId))(
    manifest.all_approvers,
  );

  if (approverIndex === -1) return immutableState;

  const formatted = formatApprovalManifestForRecord({
    approval_manifest: {
      id: manifest.id,
      approvers: R.compose(
        R.set(R.lensPath([approverIndex, "reviewed_at"]), new Date()),
        R.set(R.lensPath([approverIndex, "review"]), status),
      )(manifest.all_approvers),
    },
  });

  return immutableState.setIn(["module_record", "record", "values", fieldId], {
    type: "approval",
    value: formatted,
  });
};

const updateSubformApprovalValue = (
  state,
  immutableState,
  { fieldId, userId, status, subformId, rowId },
) => {
  const valueLens = R.lensPath([
    "values",
    subformId,
    "value",
    "submissions",
    rowId,
    "values",
    fieldId,
    "value",
  ]);

  const manifest = R.view(valueLens)(state);

  if (!manifest) return immutableState;

  const approverIndex = R.findIndex(R.propEq("user_id", userId))(
    manifest.all_approvers,
  );

  if (approverIndex === -1) return immutableState;

  const formatted = formatApprovalManifestForRecord({
    approval_manifest: {
      id: manifest.id,
      approvers: R.compose(
        R.set(R.lensPath([approverIndex, "reviewed_at"]), new Date()),
        R.set(R.lensPath([approverIndex, "review"]), status),
      )(manifest.all_approvers),
    },
  });

  return immutableState.setIn(
    ["values", subformId, "value", "submissions", rowId, "values", fieldId],
    {
      type: "approval",
      value: formatted,
    },
  );
};

const submission = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.submission.submission;
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case REFRESH_RELATED_ORDER:
      return {
        ...state,
        related_order: action.payload.order,
      };
    case CONFIRMATION_CREATE_COMPLETE:
      return {
        ...state,
        is_confirmation_sent: action.payload.confirmationId,
      };
    case DONE_CREATE_COMPLETE:
      return {
        ...state,
        is_done: action.payload.confirmationId,
        is_locked: true,
      };
    case VALUE_CREATE_COMPLETE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.value.field_id]: action.payload.value.value,
        },
      };
    case BULK_VALUE_CREATE_COMPLETE: {
      const bulkValues = action.payload.reduce((bv, { field_id, value }) => {
        bv[field_id] = value;
        return bv;
      }, {});
      return {
        ...state,
        values: {
          ...state.values,
          ...bulkValues,
        },
      };
    }
    case CREATE_SUBFORM_COMPLETE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.subformId]: {
            ...state.values[action.payload.subformId],
            value: {
              ...state.values[action.payload.subformId].value,
              submissions: {
                ...state.values[action.payload.subformId].value.submissions,
                [action.payload.submission.id]: {
                  id: action.payload.submission.id,
                  submission_record_id:
                    action.payload.submission.submission_record_id,
                  values: {},
                  review: {},
                },
              },
            },
          },
        },
      };
    case PREFERENCES_ROW_ORDER_UPDATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.formId]: {
            ...state.preferences[action.payload.formId],
            row_order: action.payload.rowOrder,
          },
        },
      };
    case PREFERENCES_FIELD_WIDTH_UPDATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.formId]: {
            ...state.preferences[action.payload.formId],
            field_widths: {
              ...get(
                state,
                `preferences[${action.payload.formId}].field_widths`,
              ),
              [action.payload.fieldId]: action.payload.width,
            },
          },
        },
      };
    case SUBFORM_VALUE_CREATE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.subformFieldId]: {
            ...state.values[action.payload.subformFieldId],
            value: {
              ...state.values[action.payload.subformFieldId].value,
              submissions: {
                ...state.values[action.payload.subformFieldId].value
                  .submissions,
                [action.payload.submissionId]: {
                  ...state.values[action.payload.subformFieldId].value
                    .submissions[action.payload.submissionId],
                  values: {
                    ...state.values[action.payload.subformFieldId].value
                      .submissions[action.payload.submissionId].values,
                    [action.payload.fieldId]: action.payload.value,
                  },
                },
              },
            },
          },
        },
      };
    case SUBFORM_VALUE_BULK_CREATE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.subformId]: {
            ...state.values[action.payload.subformId],
            value: {
              ...state.values[action.payload.subformId].value,
              submissions: {
                ...state.values[action.payload.subformId].value.submissions,
                ...action.payload.values.reduce((submissions, value) => {
                  if (!submissions[value.submissionId]) {
                    submissions[value.submissionId] = {
                      ...state.values[action.payload.subformId].value
                        .submissions[value.submissionId],
                      values: {
                        ...state.values[action.payload.subformId].value
                          .submissions[value.submissionId].values,
                        [value.fieldId]: value.value,
                      },
                    };
                  } else {
                    submissions[value.submissionId] = {
                      ...submissions[value.submissionId],
                      values: {
                        ...submissions[value.submissionId].values,
                        [value.fieldId]: value.value,
                      },
                    };
                  }
                  return submissions;
                }, {}),
              },
            },
          },
        },
      };
    case REVIEW_CREATE:
      return {
        ...state,
        // if handling a single submission review, process update
        review:
          action.payload.submissionId === state.id
            ? {
                status: action.payload.status,
              }
            : state.review,
        values: {
          ...state.values,
          // if handling a subform review, process update
          [action.payload.subformId]: action.payload.subformId
            ? {
                ...state.values[action.payload.subformId],
                value: {
                  ...state.values[action.payload.subformId].value,
                  submissions: {
                    ...state.values[action.payload.subformId].value.submissions,
                    [action.payload.submissionId]: {
                      ...state.values[action.payload.subformId].value
                        .submissions[action.payload.submissionId],
                      review: {
                        status: action.payload.status,
                      },
                    },
                  },
                },
              }
            : undefined,
        },
      };
    case REVIEW_BULK_CREATE:
      return {
        ...state,
        ...action.payload.reviews.reduce(
          (updatedState, review) => ({
            ...updatedState,
            // if handling a single submission review, process update
            review:
              review.submissionId === updatedState.id
                ? {
                    status: review.status,
                  }
                : updatedState.review,
            values: {
              ...updatedState.values,
              // if handling a subform review, process update
              [action.payload.subformId]: action.payload.subformId
                ? {
                    ...updatedState.values[action.payload.subformId],
                    value: {
                      ...updatedState.values[action.payload.subformId].value,
                      submissions: {
                        ...updatedState.values[action.payload.subformId].value
                          .submissions,
                        [review.submissionId]: {
                          ...updatedState.values[action.payload.subformId].value
                            .submissions[review.submissionId],
                          review: {
                            status: review.status,
                          },
                        },
                      },
                    },
                  }
                : undefined,
            },
          }),
          state,
        ),
      };
    case CREATE_APPROVAL: {
      if (action.payload.submissionId === state.id) {
        // if handling a single submission review, process update
        return updateApprovalValue(state, Immutable.fromJS(state), {
          fieldId: SYSTEM_FIELD_IDS.APPROVAL,
          userId: action.payload.userId,
          status: action.payload.status,
        }).toJS();
      } else {
        // if handling a subform review, process update
        return updateSubformApprovalValue(state, Immutable.fromJS(state), {
          fieldId: SYSTEM_FIELD_IDS.APPROVAL,
          userId: action.payload.userId,
          status: action.payload.status,
          subformId: action.payload.subformId,
          rowId: action.payload.rowId,
        }).toJS();
      }
    }

    case CLONE_SUBFORM_COMPLETE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.subformId]: {
            ...state.values[action.payload.subformId],
            value: {
              ...state.values[action.payload.subformId].value,
              submissions: {
                ...state.values[action.payload.subformId].value.submissions,
                ...Object.keys(action.payload.submissions).reduce(
                  (submissions, originalSubmissionId) => ({
                    ...submissions,
                    ...action.payload.submissions[originalSubmissionId]
                      .cloned_submissions,
                  }),
                  {},
                ),
              },
            },
          },
        },
      };
    case BULK_DELETE_SUBFORM:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.subformId]: {
            ...state.values[action.payload.subformId],
            value: {
              ...state.values[action.payload.subformId].value,
              submissions: Object.keys(
                state.values[action.payload.subformId].value.submissions,
              ).reduce((updatedSubmissions, submissionId) => {
                if (!action.payload.submissionIds.includes(submissionId)) {
                  updatedSubmissions[submissionId] =
                    state.values[action.payload.subformId].value.submissions[
                      submissionId
                    ];
                }
                return updatedSubmissions;
              }, {}),
            },
          },
        },
      };

    case ADD_PERSON_COMPLETE: {
      return Immutable.fromJS(state)
        .setIn(
          ["people_blocks", action.payload.blockFieldId, "people"],
          [
            ...R.pathOr(
              [],
              ["people_blocks", action.payload.blockFieldId, "people"],
            )(state),
            action.payload.person,
          ],
        )
        .toJS();
    }

    case REFRESH_PEOPLE_BLOCK_ORDER: {
      const index = R.compose(
        R.findIndex(R.propEq("id", action.payload.contactId)),
        R.pathOr([], ["people_blocks", action.payload.blockFieldId, "people"]),
      )(state);

      return Immutable.fromJS(state)
        .setIn(
          [
            "people_blocks",
            action.payload.blockFieldId,
            "people",
            index,
            "related_order",
          ],
          action.payload.order,
        )
        .toJS();
    }

    case SAVE_PERSON_VALUE_COMPLETE: {
      const index = R.findIndex(
        (p) => p.id === action.payload.contactId,
        R.pathOr(
          [],
          ["people_blocks", action.payload.blockFieldId, "people"],
          state,
        ),
      );
      return R.assocPath(
        [
          "people_blocks",
          action.payload.blockFieldId,
          "people",
          index,
          "values",
          action.payload.fieldId,
        ],
        action.payload.value,
        state,
      );
    }

    case REMOVE_PERSON_COMPLETE: {
      return R.assocPath(
        ["people_blocks", action.payload.blockFieldId, "people"],
        R.filter(
          (p) => p.id !== action.payload.contactId,
          R.pathOr(
            [],
            ["people_blocks", action.payload.blockFieldId, "people"],
            state,
          ),
        ),
        state,
      );
    }

    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const saving = (state = false, action) => {
  switch (action.type) {
    case VALUE_CREATE:
    case BULK_VALUE_CREATE:
    case REVIEW_CREATE:
    case REVIEW_BULK_CREATE:
    case UPDATE:
    case ADD_ITEM_TO_CART:
    case REMOVE_ITEM_FROM_CART:
    case ADD_PERSON:
    case REMOVE_PERSON:
      return true;
    case VALUE_CREATE_COMPLETE:
    case REVIEW_CREATE_COMPLETE:
    case REVIEW_BULK_CREATE_COMPLETE:
    case UPDATE_COMPLETE:
    case ADD_ITEM_TO_CART_COMPLETE:
    case REMOVE_ITEM_FROM_CART_COMPLETE:
    case ADD_PERSON_COMPLETE:
    case REMOVE_PERSON_COMPLETE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  submission,
  error,
  fetching,
  saving,
});
