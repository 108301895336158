export const NAMESPACE = "AuctionPageWrapper";

export const HEADER_LINKS = {
  EVENT_DETAILS: "eventDetails",
  AUCTION: "auction",
  RULES: "rules",
  LOG_IN: "login",
  CART: "cart",
  BACK: "back",
  DONATE: "donate",
  PROFILE: "profile",
};

export const ROUTES_WITH_BACK_BUTTON = [
  "attendAuctionItem",
  // "attendAuctionCheckout"
  // "attendAuctionRules"
];
