import { compile, serialize, stringify, middleware, prefixer } from "stylis";

let cache = {};
const rules = [];
let insert = function insert(rule) {
  return rules.push(rule);
};
const hyph = function hyph(s) {
  return s.replace(/[A-Z]|^ms/g, "-$&").toLowerCase();
};
const px = function px(n) {
  return typeof n === "number" ? `${n}px` : n;
};
const mx = function mx(rule, media) {
  return media ? `${media}{${rule}}` : rule;
};
const rx = function rx(cn, prop, val) {
  const cssRule = `.${cn} { ${hyph(prop)}: ${px(val)}; }`;

  const compiled = compile(cssRule);
  return serialize(compiled, middleware([prefixer, stringify]));
};
const noAnd = function noAnd(s) {
  return s.replace(/&/g, "");
};

const parse = function parse(obj) {
  const child =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  const media = arguments[2];
  return Object.keys(obj)
    .map((key) => {
      const val = obj[key];
      if (val === null) return "";
      if (typeof val === "object") {
        const m2 = /^@/.test(key) ? key : null;
        const c2 = m2 ? child : child + key;
        return parse(val, c2, m2 || media);
      }
      const _key = key + val + child + media;
      if (cache[_key]) return cache[_key];
      const className = `x${rules.length.toString(36)}`;
      insert(mx(rx(className + noAnd(child), key, val), media));
      cache[_key] = className;
      return className;
    })
    .join(" ");
};

module.exports = function () {
  for (
    var _len = arguments.length, styles = Array(_len), _key2 = 0;
    _key2 < _len;
    _key2++
  ) {
    styles[_key2] = arguments[_key2];
  }
  return styles
    .map((style) => parse(style))
    .join(" ")
    .trim();
};

module.exports.css = function () {
  return rules.sort().join("");
};

module.exports.reset = function () {
  cache = {};
  while (rules.length) {
    rules.pop();
  }
};

if (typeof document !== "undefined") {
  let cxsTag = document.getElementById("cxs");
  if (cxsTag) {
    const sheet = cxsTag.sheet;
    insert = function insert(rule) {
      rules.push(rule);
      try {
        sheet.insertRule(rule, sheet.cssRules.length);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error inserting style: ${rule}`, e);
      }
    };
  }
}
