import React from "react";
import { Div, SmallCheckbox, IconLabel } from "../../index";

const CheckboxIconLabel = ({ selected, toggle, icon, text }) => {
  return (
    <Div display={"row.flex-start.center"}>
      <SmallCheckbox selected={selected} onClick={toggle} />
      <IconLabel icon={icon} text={text} py={1} ml={1} />
    </Div>
  );
};

export { CheckboxIconLabel };
