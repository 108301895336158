import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/event/${eventId}`,
        credentials,
        data: {},
        success: (result) => {
          resolve(result.payload);
        },
        error: (err) => {
          reject(err);
        },
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getEventAccountRecordTypes: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/event/${eventId}/account-types`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `/event`,
        credentials,
        data,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  clone: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `/event/clone`,
        credentials,
        data,
        success: (result) => {
          resolve(result.event);
        },
        error: (err) => {
          reject(err);
        },
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
};
