import { combineReducers } from "redux";
import values from "./values/reducer";
import {
  REQUEST,
  RECEIVE,
  RECEIVE_REFERENCES,
  ADD_REFERENCE,
} from "./constants";

function list(state = [], action) {
  switch (action.type) {
    case RECEIVE_REFERENCES:
      return Object.keys(action.payload).map((key) => action.payload[key]);
    case ADD_REFERENCE:
      return [...state, action.payload];
    default:
      return state;
  }
}

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  fetching,
  values,
});
