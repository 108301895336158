import * as R from "ramda";

export const defaultInstanceId = "default";

export const bindInstance = (actions, instanceKey = ["instanceId"]) => {
  let cache = {};
  return (dispatch, props) => {
    const instanceId =
      (typeof instanceKey === "function"
        ? instanceKey(props)
        : R.path(instanceKey, props)) || defaultInstanceId;

    if (!R.path([instanceId, "actions"], cache)) {
      cache[instanceId] = {
        actions: R.map(
          // eslint-disable-next-line consistent-return
          (action) =>
            (payload, props = {}, error = false) => {
              const ac = action(
                payload,
                {
                  ...props,
                  meta: {
                    ...(props.meta || {}),
                    instanceId: instanceId,
                  },
                },
                error,
              );

              if (typeof ac !== "undefined") {
                return cache[instanceId].dispatch(ac);
              }
            },
          actions,
        ),
        dispatch,
      };
    }

    if (dispatch !== R.path([instanceId, "dispatch"], cache)) {
      cache[instanceId].dispatch = dispatch;
    }
    return R.path([instanceId, "actions"], cache);
  };
};
