import React from "react";
import * as R from "ramda";
import ReactTable from "react-table";
import PaginationManager from "components/Global/ReactTable/Pagination/Manager";
import PaginationBar from "components/Global/ReactTable/Pagination/General";
import {
  LoadingIcon,
  Div,
  Text0,
  Text1,
  Text2,
  makeTable,
} from "components/Base";
import { defaultProps } from "../utils";

const HorzInfoList = ({ info, ...props }) => {
  const width = 1 / R.length(info);
  return (
    <Div display="row" {...props}>
      {R.map(
        ({ key, value }) => (
          <Div key={key} width={width}>
            <Text1 uppercase color="neutral6">
              {key}
            </Text1>
            <Text1 mt={1} color="neutral8">
              {value}
            </Text1>
          </Div>
        ),
        info,
      )}
    </Div>
  );
};

const SimpleTable = ({
  columnProps = [],
  headerCellComps = [],
  rowCellComps = [],
  ...props
}) =>
  makeTable({
    TableStyler: defaultProps({
      my: 3,
    })(Div),
    HeaderStyler: defaultProps({
      display: "row.flex-start.center",
      pb: 2,
    })(Div),
    RowStyler: defaultProps({
      display: "row.flex-start.center",
      bg: { default: "white", hover: "neutral0" },
      transition: "fast",
      py: 2,
      bra: 0,
    })(Div),
    HeaderCellStyler: defaultProps({
      px: 2,
      uppercase: true,
      display: "row.flex-end.center",
    })(Div),
    RowCellStyler: defaultProps({
      px: 2,
      display: "row.flex-end.center",
    })(Div),
    columnProps: R.map((x) => {
      if (Array.isArray(x)) {
        const [width, alignment, other] = x;
        let display = "flex-end";
        if (alignment === "left") {
          display = "flex-start";
        } else if (alignment === "center") {
          display = "center";
        }
        return {
          display: `row.${display}.center`,
          width,
          ...other,
        };
      }
      return x;
    }, columnProps),
    headerCellComps: R.map((x) => {
      if (typeof x === "string") {
        return () => (
          <Text0 key={x} bold>
            {x}
          </Text0>
        );
      }
      return x;
    }, headerCellComps),
    rowCellComps: R.map((x) => {
      if (typeof x === "string") {
        return (props) => (
          <Text2 key={x} bold>
            {props[x]}
          </Text2>
        );
      }
      return x;
    }, rowCellComps),
    ...props,
  });

const LoadingState = ({ className, loading, ...props }) => (
  <div
    className={`-loading ${loading ? "-active" : ""} ${className}`}
    {...props}
  >
    <Div width={1}>{loading ? <LoadingIcon size={60} /> : ""}</Div>
  </div>
);
const sharedTableProps = {
  LoadingComponent: LoadingState,
  getNoDataProps: () => ({
    style: {
      backgroundColor: "white",
      width: "100%",
      marginTop: R.pathOr(50, [0, "scrollHeight"])(
        document.getElementsByClassName("rt-thead -header"),
      ),
      height: "100%",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
};
const DataTableBase = ({
  id,
  rows,
  columns,
  EmptyState = "No data",
  resizeable = true,
  onFetchData,
  loading,
  PaginationBarComp = PaginationBar,
}) => {
  const countOfRows = rows.length;
  const hasRows = Boolean(countOfRows);
  return (
    <PaginationManager
      key={`table_${id}_${countOfRows}`}
      pageSize={countOfRows === 0 ? 3 : countOfRows < 10 ? countOfRows : 10}
    >
      <ReactTable
        {...sharedTableProps}
        key={`table_${id}_${countOfRows}`}
        loading={loading}
        className="ReactTable--Lennd ReactTable--Lennd-Simple"
        noDataText={loading ? "" : EmptyState}
        columns={columns}
        data={rows}
        minRows={hasRows ? undefined : 10} // we do this to force space for an empty state
        resizable={resizeable}
        PaginationComponent={hasRows ? PaginationBarComp : undefined}
        showPageSizeOptions={hasRows && countOfRows > 5}
        showPagination={hasRows}
        showPaginationBottom={hasRows}
        manual={Boolean(onFetchData)}
        onFetchData={onFetchData}
      />
    </PaginationManager>
  );
};

const ControlledDataTable = ({
  id,
  rows,
  columns,
  EmptyState = "No data",
  onFetchData = () => {},
  onResizedChange,
  resizable,
  pages,
  onPageChange,
  onSortedChange,
  currentPage,
  loading,
  PaginationBarComp = PaginationBar,
  fixHeaders,
  hasColumnActions,
  pivotBy = undefined,
  expandedRows = {},
  minRows = 10,
  ...moreConfig
}) => {
  const countOfRows = rows.length;
  const hasRows = Boolean(countOfRows);
  return (
    <ReactTable
      {...sharedTableProps}
      key={`table_${id}_${countOfRows}`}
      loading={loading}
      className={`ReactTable--Lennd ReactTable--Lennd-Simple ${
        fixHeaders ? "ReactTable--Lennd-Fixed-Headers" : ""
      } ${hasColumnActions ? "ReactTable--Lennd-Has-Column-Actions" : ""}`}
      noDataText={loading ? "" : EmptyState}
      columns={columns}
      data={rows}
      minRows={hasRows ? countOfRows : minRows} // we do this to force space for an empty state
      resizable={resizable}
      PaginationComponent={hasRows ? PaginationBarComp : undefined}
      showPagination={hasRows}
      showPaginationBottom={hasRows}
      showPageSizeOptions={false}
      manual
      onFetchData={onFetchData}
      onSortedChange={onSortedChange}
      pages={pages}
      onPageChange={onPageChange}
      onResizedChange={onResizedChange}
      page={currentPage}
      pivotBy={pivotBy}
      defaultPageSize={10}
      expanded={expandedRows}
      {...moreConfig}
    />
  );
};
const DataTable = ({
  id,
  rows,
  columns,
  EmptyState,
  resizeable = true,
  PaginationBarComp,
}) => (
  <DataTableBase
    id={id}
    rows={rows}
    columns={columns}
    EmptyState={EmptyState}
    resizeable={resizeable}
    PaginationBarComp={PaginationBarComp}
  />
);

export { ControlledDataTable, DataTable, SimpleTable, HorzInfoList };
