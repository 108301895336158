import * as R from "ramda";

const getParams = R.propOr({}, "params");
const createGetId = (key) => R.compose(R.propOr("", key), getParams);

export const getFieldId = createGetId("fieldId");
export const getSubmissionId = createGetId("submissionId");

export const getHasSkippedPaymentMethodPreviously = (eventUUID) => {
  return localStorage.getItem(`skipPaymentMethod-${eventUUID}`) === "true";
};

export const setSkipPaymentMethod = (eventUUID) => {
  if (eventUUID) {
    localStorage.setItem(`skipPaymentMethod-${eventUUID}`, "true");
  }
};
