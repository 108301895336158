import React, { Component } from "react";
import PageLocation from "../PageLocation";
import PrimaryPagination from "./PrimaryPagination";
import SizeSelector from "../SizeSelector";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import * as R from "ramda";

@CSSModules(css)
class ReactTablePagination extends Component {
  state = { page: this.props.page };

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({ page: this.props.page });
    }
  }

  getSafePage = (page) => {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  };

  changePage = (page) => {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  };

  applyPage = (e) => {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === "" ? this.props.page : page);
  };

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent,
      total,
      data = [],
    } = this.props;

    return (
      <div className={css.container}>
        <PageLocation
          pageSize={pageSize}
          pages={pages}
          page={page}
          total={R.isNil(total) ? data.length : total}
          rowsText={this.props.rowsText}
        />
        <PrimaryPagination
          {...{
            // Computed
            pages,
            // Props
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            showPageJump,
            canPrevious,
            canNext,
            onPageSizeChange,
            className,
            PreviousComponent,
            NextComponent,
            // Helpers
            getSafePage: this.getSafePage,
            changePage: this.changePage,
            applyPage: this.applyPage,
          }}
        />
        {showPageSizeOptions ? (
          <SizeSelector
            pageSize={pageSize}
            rowsText={this.props.rowsText}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={pageSizeOptions}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ReactTablePagination;
