import { combineReducers } from "redux";
import statsReducer from "./stats/reducer";
import searchReducer from "./search/reducer";
import recordsReducer from "./records/reducer";

export default combineReducers({
  records: recordsReducer,
  search: searchReducer,
  stats: statsReducer,
});
