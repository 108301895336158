import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId, userId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/permissions/event/${eventId}/user/${userId}`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getOrganization: (credentials, orgId, userId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `/permissions/organization/${orgId}/user/${userId}`,
        credentials,
        success: resolve,
        error: reject,
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
};
