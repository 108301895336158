import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR } from "./constants";

const form = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.form.form;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  form,
  error,
  fetching,
});
