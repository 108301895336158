import Immutable from "immutable";
import { combineReducers } from "redux";
import { merge, findIndex } from "lodash";
import {
  RECEIVE,
  REQUEST,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE,
  DELETE_SUBMISSION,
} from "./constants";
import { UPDATE_COMPLETE as UPDATE_FORM_COMPLETE } from "../form/constants";
import { UPDATE as UPDATE_SUBMISSION } from "../submission/constants";
import { CREATE as REVIEW_CREATE } from "../submission/reviews/constants";
import { CREATE_COMPLETE as CONFIRMATION_CREATE_COMPLETE } from "../submission/confirmation/constants";
import { CREATE_COMPLETE as DONE_CREATE_COMPLETE } from "../submission/done/constants";
import { CREATE as CREATE_APPROVAL } from "../submission/approvals/constants";

const overview = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.overview.overview;
    case INVALIDATE:
      return [];
    case UPDATE_FORM_COMPLETE:
      return merge({}, state, action.payload);
    case UPDATE_SUBMISSION: {
      const index = findIndex(
        state.submissions,
        (s) => s.id === action.payload.id,
      );
      if (index === -1) {
        return state;
      }
      const submissions = [...state.submissions];
      submissions.splice(index, 1, action.payload);
      return {
        ...state,
        submissions,
      };
    }
    case CONFIRMATION_CREATE_COMPLETE: {
      const index = findIndex(
        state.submissions,
        (s) => s.id === action.payload.submissionId,
      );
      if (index === -1) {
        return state;
      }
      const submissions = [...state.submissions];
      submissions.splice(index, 1, {
        ...submissions[index],
        is_confirmation_sent: action.payload.confirmationId,
      });
      return {
        ...state,
        submissions,
      };
    }
    case DONE_CREATE_COMPLETE: {
      const index = findIndex(
        state.submissions,
        (s) => s.id === action.payload.submissionId,
      );
      if (index === -1) {
        return state;
      }
      const submissions = [...state.submissions];
      submissions.splice(index, 1, {
        ...submissions[index],
        is_done: action.payload.confirmationId,
        is_locked: true,
      });
      return {
        ...state,
        submissions,
      };
    }
    case REVIEW_CREATE: {
      const index = findIndex(
        state.submissions,
        (s) => s.id === action.payload.submissionId,
      );
      if (index === -1) {
        return state;
      }
      const submissions = [...state.submissions];
      submissions.splice(index, 1, {
        ...submissions[index],
        review: {
          status: action.payload.status,
        },
      });
      return {
        ...state,
        submissions,
      };
    }
    case DELETE_SUBMISSION:
      return {
        ...state,
        submissions: state.submissions.filter(
          (s) => s.id !== action.payload.submissionId,
        ),
      };
    case CREATE_APPROVAL: {
      const indexOfSubmission = findIndex(
        state.submissions,
        (s) => s.id === action.payload.submissionId,
      );

      if (indexOfSubmission === -1) {
        return state;
      }

      return Immutable.fromJS(state)
        .setIn(
          [
            "submissions",
            indexOfSubmission,
            "values",
            action.payload.subformId,
            "value",
            "submissions",
            action.payload.recordId,
            "values",
            "approval",
            "value",
          ],
          [action.payload.entry],
        )
        .toJS();
    }
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  overview,
  error,
  fetching,
});
