import Helpers from "utils/Global/Helpers";

module.exports = {
  getNavigation: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `/event/${eventId}/navigation`,
        credentials,
        success,
        error,
      });
    }),
  put: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `/virtual/event/${eventId}/details`,
        credentials,
        data,
        success,
        error,
      });
    }),
};
