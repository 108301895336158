import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import { actions } from "../model";
import { registerError } from "redux/modules/errors/actions";
import { openFilePicker } from "utils/General/sagas";
import Api from "../api";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { fetchOrganization } from "redux/modules/organization/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { getSupport } from "App/Data/sagas";

const init = function* () {
  try {
    const credentials = yield select(getCredentials);
    const orgId = yield select(getOrgId);

    const [{ payload: navigation }] = yield all([
      call(Api.getNavigation, {
        credentials,
        orgId,
      }),
      yield call(getSupport),
    ]);

    yield put(actions.setNavigation(navigation));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting navigation",
          },
        ]),
      ),
    ]);
  }
};

const showFilePicker = function* () {
  const files = yield call(openFilePicker, {
    multiple: false,
    fromSources: ["local_file_system"],
    accept: ["image/jpeg", "image/svg+xml", "image/png"],
    storeTo: {
      path: "org-logo/",
    },
  });

  try {
    const credentials = yield select(getCredentials);
    const orgId = yield select(getOrgId);
    yield call(Api.put, {
      credentials,
      orgId,
      data: {
        logoImageUrl: R.path([0, "url"])(files),
      },
    });

    yield put(fetchOrganization(orgId));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred uploading photo",
        },
      ]),
    );
  }
};

const handleAddEvent = function* () {
  const orgId = yield select(getOrgId);
  const credentials = yield select(getCredentials);

  yield put(showSnackbar({ message: "Creating event..." }));

  const { payload: event } = yield call(Api.addEvent, credentials, {
    orgId,
  });
  window.location = `/event-light/${event.id}/dashboard`;
};

const watchShowAddEventModal = function* () {
  yield takeEvery(actions.showAddEventModal.type, handleAddEvent);
};

const watchInit = function* () {
  yield takeEvery(actions.init.type, init);
};

const watchShowFilepicker = function* () {
  yield takeEvery(actions.showLogoFilepicker.type, showFilePicker);
};

const rootSaga = function* () {
  yield all([
    fork(watchShowAddEventModal),
    fork(watchShowFilepicker),
    fork(watchInit),
  ]);
};

export default rootSaga;
