import React from "react";
import * as R from "ramda";
import { StarIcon, StarOutlineIcon } from "../index";

const TogglableStar = ({ active, onClick, ...props }) => {
  return (
    <StarIcon
      onClick={onClick}
      color={
        active
          ? { default: "yellow7", hover: "yellow7" }
          : { default: "neutral7", hover: "yellow7" }
      }
      {...props}
    />
  );

  // if (active) {
  //   return (
  //     <StarIcon
  //       onClick={onClick}
  //       color={{ default: "yellow7", hover: "neutral7" }}
  //       {...props}
  //     />
  //   );
  // } else {
  //   return (
  //     <StarOutlineIcon
  //       onClick={onClick}
  //       color={{ default: "neutral7", hover: "yellow7" }}
  //       {...props}
  //     />
  //   );
  // }
};

export { TogglableStar };
