/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import Immutable from "immutable";
import { StyleRoot } from "radium"; // @NOTE: This can be removed when we remove radium'
import { globalErrorHandler } from "utils/errors";
import { GlobalError } from "components/Global/ErrorViews";
import { ErrorBoundary } from "react-error-boundary";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (window.__BUGSNAG_ENABLED__) {
  Bugsnag.start({
    apiKey: window.__BUGSNAG_KEY__,
    plugins: [new BugsnagPluginReact()],
    appType: "client",
    enabledReleaseStages: ["production", "preprod", "staging", "dev"],
    releaseStage: window.__ENV__,
  });
}

const ErrorBoundaryToUse = window.__BUGSNAG_ENABLED__
  ? Bugsnag.getPlugin("react").createErrorBoundary(React)
  : ErrorBoundary;

class Root extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  };

  componentDidMount() {
    window.Immutable = Immutable;
  }

  render() {
    return (
      <StyleRoot style={{ height: "100%" }}>
        <Provider store={this.props.store}>
          <ErrorBoundaryToUse
            FallbackComponent={GlobalError}
            onError={globalErrorHandler}
          >
            <Router
              history={this.props.history}
              onUpdate={() => {
                if (window.Appcues) {
                  window.Appcues.page();
                }
              }}
            >
              {this.props.routes}
            </Router>
          </ErrorBoundaryToUse>
        </Provider>
      </StyleRoot>
    );
  }
}
export default Root;
