const ns = "FORMS_V2_SUBMISSION_VALUE";
export const CREATE = `${ns}_CREATE`;
export const CREATE_COMPLETE = `${ns}_CREATE_COMPLETE`;
export const BULK_CREATE = `${ns}_BULK_CREATE`;
export const BULK_CREATE_COMPLETE = `${ns}_BULK_CREATE_COMPLETE`;
export const CREATE_DEFAULT = `${ns}_CREATE_DEFAULT`;
export const CREATE_DEFAULT_COMPLETE = `${ns}_CREATE_DEFAULT_COMPLETE`;
export const CREATE_SUBFORM = `${ns}_CREATE_SUBFORM`;
export const CREATE_SUBFORM_COMPLETE = `${ns}_CREATE_SUBFORM_COMPLETE`;
export const BULK_CREATE_SUBFORM = `${ns}_BULK_CREATE_SUBFORM`;
export const BULK_CREATE_SUBFORM_COMPLETE = `${ns}_BULK_CREATE_SUBFORM_COMPLETE`;
export const BULK_CREATE_DEFAULT = `${ns}_BULK_CREATE_DEFAULT`;
export const BULK_CREATE_DEFAULT_COMPLETE = `${ns}_BULK_CREATE_DEFAULT_COMPLETE`;
export const ERROR = `${ns}_ERROR`;
export const CLEAR_ERROR = `${ns}_CLEAR_ERROR`;
export const INVALIDATE = `${ns}_INVALIDATE`;
