import { call, put, select } from "redux-saga/effects";

import { actions } from "../model";

import { apiCall } from "./utils";

import { userId as getUserId } from "redux/modules/user/selectors";

export const getSubmissionById = function* ({ submissionId, transactionId }) {
  try {
    const userId = yield select(getUserId);
    yield put(actions.fetching(transactionId));
    const result = yield call(apiCall, {
      method: "get",
      url: `/form/submissions/${submissionId}`,
      qs: {
        // NOTE: remove when endpoint is validated
        userId,
      },
    });

    yield put(
      actions.addSubmission(result.submission, { meta: { transactionId } }),
    );
    return result.submission;
  } catch (error) {
    yield put(actions.error(error, { meta: { transactionId } }));
    throw error;
  } finally {
    yield put(actions.fetching(transactionId));
  }
};
