import { registerError } from "redux/modules/errors/actions";
import { REQUEST, RECEIVE } from "./constants";
import api from "./api";

export function fetchOrganization(orgId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const data = await api.get(getState().user.user.credentials, orgId);
      dispatch({ type: RECEIVE, payload: data.payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting organization",
          },
        ]),
      );
    }
  };
}

export function updateOrganization(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating organization",
          },
        ]),
      );
    }
  };
}
