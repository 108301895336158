import { combineReducers, concatReducers } from "redux-mvc";
import optimist from "redux-optimist";
import { routerReducer } from "react-router-redux";

import { history } from "./history";
import errors from "./errors/reducer";

import activities from "./activities/reducer";

import billing from "./billing/reducer";

import organization from "./organization/reducer";
import organizationUsers from "redux/modules/organization/users/reducer";
import organizations from "./organizations/reducer";

import event from "./event/reducer";
import eventEmailMergeTags from "./event/email-merge-tags/reducer";
import eventSetupProgress from "./event/progress/reducer";
import events from "./events/reducer";

import eventHome from "./eventHome/reducer";
import eventGroups from "./eventGroups";
import eventMembers from "./eventMembers";

import contactProfile from "./contacts/profile/reducer";
import contactAssignedForms from "./contacts/assignedForms/reducer";
import contactAssignedDocumentRequests from "./contacts/assignedDocumentRequests/reducer";

import eventPermissionRoles from "./eventPermissionRoles/reducer";

import eventEmailTemplates from "./eventEmailTemplates/reducer";

import portal from "./portal/reducer";

import eventUsers from "./eventUsers/reducer";

import forms from "./forms/reducer";
import formPreferences from "./formPreferences/reducer";
import formOverview from "./formOverview/reducer";
import formRequests from "./formRequests/reducer";

import dashboards from "./dashboards/reducer";
import formsV2_forms from "./formsV2/forms/reducer";
import formsV2_form from "./formsV2/form/reducer";
import formsV2_messages from "./formsV2/messages/reducer";
import formsV2_fields from "./formsV2/form/fields/reducer";
import formsV2_formOverview from "./formsV2/overview/reducer";
import formsV2_submission from "./formsV2/submission/reducer";
import formsV2_submissionCart from "./formsV2/submission/cart/reducer";
import formsV2_submissionActivity from "./formsV2/submission/activity/reducer";
import formsV2_submissionComments from "./formsV2/submission/comments/reducer";
import formsV2_submissions from "./formsV2/submissions/reducer";
import formsV2_references from "./formsV2/references/reducer";
import formsV2_assignments from "./formsV2/form/assignments/reducer";
import peopleBlocks from "./formsV2/people-blocks/reducer";

import credentialsList from "./credentials/credentials/list/reducer";
import credentialsCredential from "./credentials/credentials/credential/reducer";
import credentialsCredentialGroups from "./credentials/credential-groups/list/reducer";
import credentialsIntegrations from "./credentials/integrations/reducer";
import credentialsModules from "./credentials/modules/reducer";

import credentialsLineItems from "./credentials/lineItems/reducer";
import credentialsCounts from "./credentials/counts/reducer";
import credentialsOrderSchema from "./credentials/order/schema/reducer";
import credentialsOrders from "./credentials/orders/reducer";
import credentialsOrder from "./credentials/order/reducer";
import credentialsCheckin from "./credentials/checkin/reducer";
import passesCheckin from "./passesCheckin/reducer";
import passes from "./passes/reducer";

import accountProfile from "./accounts/profile/reducer";
import accountList from "./accounts/list/reducer";
import accountReports from "./accounts/reports/reducer";
import accountPermissions from "./accounts/permissionSets/reducer";
import accountCredentials from "./accounts/credentials/reducer";
import accountAssignedForms from "./accounts/assignedForms/reducer";
import accountAssignedDocumentRequests from "./accounts/assignedDocumentRequests/reducer";

import stripeConnectAuth from "./integrations/stripe-connect/auth/reducer";

import features from "./features/reducer";

import moduleModule from "./modules/module/reducer";
import moduleManagers from "./modules/managers/reducer";
import moduleModules from "./modules/modules/reducer";
import moduleMessages from "./modules/messages/reducer";
import moduleEmail from "./modules/email/reducer";
import moduleNotes from "./modules/notes/reducer";
import moduleActivity from "./modules/activity/reducer";
import moduleLayouts from "./modules/layouts/reducer";
import moduleLayout from "./modules/layout/reducer";
import moduleLayoutVisibleFields from "./modules/layouts/visibleFields/reducer";
import moduleRecordTypes from "./modules/recordTypes/reducer";
import moduleFields from "./modules/fields/reducer";
import moduleSidebar from "./modules/sidebar/reducer";
import moduleRecords from "./modules/records/reducer";
import moduleRecordOwners from "./modules/owners/reducer";
import moduleRecordSubscription from "./modules/subscription/reducer";
import moduleRelatedRecords from "./modules/relatedRecords/reducer";
import moduleRelatedModuleVisibleFields from "./modules/relatedModules/visibleFields/reducer";
import moduleTemplates from "./modules/templates/reducer";
import moduleViews from "./modules/views/reducer";
import moduleForm from "./modules/form/reducer";
import recordView from "./modules/record/reducer";

import permissionGroups from "./permissions/permission-groups/reducer";
import permissionProfiles from "./permissions/permission-profiles/reducer";
import userPermissionProfile from "./permissions/user-permission-profile/reducer";

import user from "./user/reducer";
import userAccessLevels from "./user/access-levels/reducer";
import modal from "./modal/reducer";
import snackbar from "./snackbar/reducer";
import entityReferences from "./entityReferences/reducer";

import orders from "./orders/orders/reducer";
import preparedLineItems from "./orders/bulk-edit-line-items/reducer";
import orderInvoices from "./orders/invoices/reducer";

import items from "./items/items/reducer";
import itemTypes from "./items/types/reducer";
import itemGroups from "./items/item-groups/reducer";

import globalModule from "./global";

const reducer = optimist(
  combineReducers({
    activities,
    event: combineReducers({
      event,
      emailMergeTags: eventEmailMergeTags,
      setup: eventSetupProgress,
    }),
    events,
    errors,
    eventHome,
    eventEmailTemplates,
    eventGroups,
    eventMembers,

    eventUsers,
    modules: combineReducers({
      activity: moduleActivity,
      email: moduleEmail,
      fields: moduleFields,
      form: moduleForm,
      layout: moduleLayout,
      layoutVisibleFields: moduleLayoutVisibleFields,
      layouts: moduleLayouts,
      managers: moduleManagers,
      messages: moduleMessages,
      module: moduleModule,
      modules: moduleModules,
      notes: moduleNotes,
      owners: moduleRecordOwners,
      records: moduleRecords,
      recordTypes: moduleRecordTypes,
      recordView,
      relatedRecords: moduleRelatedRecords,
      relatedModuleVisibleFields: moduleRelatedModuleVisibleFields,
      showSidebar: moduleSidebar,
      subscription: moduleRecordSubscription,
      templates: moduleTemplates,
      views: moduleViews,
    }),
    billing,
    contacts: combineReducers({
      profile: contactProfile,
      assignedForms: contactAssignedForms,
      assignedDocumentRequests: contactAssignedDocumentRequests,
    }),
    accounts: combineReducers({
      assignedDocumentRequests: accountAssignedDocumentRequests,
      assignedForms: accountAssignedForms,
      credentials: accountCredentials,
      list: accountList,
      permissionSets: accountPermissions,
      profile: accountProfile,
      reports: accountReports,
    }),
    formsV2: combineReducers({
      list: formsV2_forms,
      form: formsV2_form,
      fields: formsV2_fields,
      messages: formsV2_messages,
      overview: formsV2_formOverview,
      submissionCart: formsV2_submissionCart,
      submissionActivity: formsV2_submissionActivity,
      submissionComments: formsV2_submissionComments,
      submission: formsV2_submission,
      submissions: formsV2_submissions,
      references: formsV2_references,
      assignments: formsV2_assignments,
      peopleBlocks,
    }),
    form: combineReducers({
      overview: formOverview,
      list: forms,
      preferences: formPreferences,
      requests: formRequests,
    }),
    dashboards,
    integrations: combineReducers({
      stripeConnect: combineReducers({
        auth: stripeConnectAuth,
      }),
    }),
    portal,
    permissions: combineReducers({
      roles: eventPermissionRoles, // @NOTE: Deprecated
      profiles: permissionProfiles,
      groups: permissionGroups,
      userProfile: userPermissionProfile,
    }),
    passesCheckin,
    passes,
    credentials: combineReducers({
      checkin: credentialsCheckin,
      credentials: credentialsList,
      credential: credentialsCredential,
      credentialGroups: credentialsCredentialGroups,
      integrations: credentialsIntegrations,
      modules: credentialsModules,
      lineItems: credentialsLineItems,
      counts: credentialsCounts,
      orders: credentialsOrders,
      order: credentialsOrder,
      orderSchema: credentialsOrderSchema,
    }),

    items: combineReducers({
      items,
      types: itemTypes,
      groups: itemGroups,
    }),
    orders: combineReducers({
      orders,
      preparedLineItems,
      invoices: orderInvoices,
    }),

    user,
    userAccessLevels,
    modal,
    snackbar,
    features,
    entityReferences,
    organization: combineReducers({
      organization,
      users: organizationUsers,
    }),
    organizations,
    history,
    routing: routerReducer,
  }),
);

export default concatReducers([globalModule.reducer, reducer]);
