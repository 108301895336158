import React from "react";
import { Div, CheckCircleIcon, CircleIcon } from "../index";

const Radio = ({ onClick, selected, disabled, size = 24, ...props }) => (
  <Div width={size} height={size} {...props}>
    {selected ? (
      <CheckCircleIcon
        color={disabled ? "gray5" : "indigo8"}
        size={size}
        onClick={disabled ? undefined : onClick}
      />
    ) : (
      <CircleIcon
        color="gray4"
        size={size}
        onClick={disabled ? undefined : onClick}
      />
    )}
  </Div>
);

export { Radio };
