import { RECEIVE, RECEIVE_BLOCK } from "./constants";
import { combineReducers } from "redux";

const blocks = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_BLOCK:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default combineReducers({
  blocks,
  byId,
});
