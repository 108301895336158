import * as R from "ramda";
import React, { Component } from "react";

const escapeRegex = (str) => str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");

const searchHandler = (inputKey, outputKey) => (Comp) => {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        [outputKey]: this.props[inputKey],
        searchTerm: "",
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        [outputKey]: this.filterValues(
          this.state.searchTerm,
          nextProps[inputKey],
        ),
      });
    }

    filterValues(searchTerm, inputValues) {
      if (searchTerm === "") {
        return inputValues;
      }
      const myRegex = new RegExp(escapeRegex(R.toLower(searchTerm)), "g");
      const newValues = R.filter(
        (el) => R.test(myRegex, R.toLower(R.join(" ", R.values(el)))),
        inputValues,
      );
      return newValues;
    }

    onSearch = (searchTerm) => {
      this.setState((state, props) => {
        return {
          searchTerm,
          [outputKey]: this.filterValues(searchTerm, props[inputKey]), // bug here, when calling this.clearSearch, state[outputKey] doesn't update correctly
        };
      });
    };

    clearSearch = () => {
      return this.onSearch("");
    };

    render() {
      return (
        <Comp
          {...{
            ...this.props,
            [outputKey]: this.state[outputKey],
            onSearch: this.onSearch,
            searchTerm: this.state.searchTerm,
            clearSearch: this.clearSearch,
          }}
        />
      );
    }
  }
  return Wrapper;
};

export { searchHandler };
